// @flow

export default {
  azul: '#365aef',
  background: '#f9fafb',
  battleshipGrey: '#6a6d7b',
  blueBlue: '#3050d5',
  bluegreen: '#008b69',
  brownishOrange: '#cd9625',
  charcoalGrey: '#42444c',
  cherry: '#de002e',
  cobalt: '#192a6f',
  coolGrey: '#a8acbb',
  greenBlue: '#00bd83',
  jungleGreen: '#008551',
  lightAquamarine: '#65fbd2',
  lightBlueGrey: '#d7dbe9',
  macaroniAndCheese: '#efc036',
  paleGrey: '#eaecf6',
  paleGreyThree: '#f9fafb',
  paleGreyTwo: '#f6f7fb',
  paleRed: '#d46147',
  rawSienna: '#9c6c00',
  salmon: '#ff8669',
  sapphire: '#2842af',
  silver: '#d7dbe9',
  turquoise: '#06c19c',
  veryLightBlue: '#fafbff',
  white: '#ffffff',
};
