// @flow

import React from 'react';
import { QueryRenderer, graphql } from 'react-relay';
import { connect } from 'react-redux';
import styled from 'styled-components';
import relayEnvironment from 'shared/gql/relayEnvironment';
import { i18n } from 'shared/utils';
import { colors, fonts } from 'shared/styleguide';

type Props = {
  operatorMachineId: ?string,
  onClick: () => void,
};

const Wrapper = styled.div`
  padding: 8px 16px;
  border-radius: 16px;
  background-color: ${colors.battleshipGrey};
  ${fonts.bodyBold};

  &:hover {
    cursor: pointer;
  }
`;

const SelectedMachine = ({ operatorMachineId, onClick }: Props) => {
  if (!operatorMachineId) {
    return null;
  }

  return (
    <QueryRenderer
      environment={relayEnvironment}
      query={graphql`
        query SelectedMachineQuery($id: ID!) {
          machine(id: $id) {
            id
            name
            type {
              id
              name
            }
          }
        }
      `}
      variables={{
        id: operatorMachineId,
      }}
      render={({ props, ...query }: *) => {
        if (!props) {
          return i18n.t('Loading...');
        }

        if (props && !props.machine) {
          window.location = '/choose-machine';
          return;
        }

        return (
          <Wrapper onClick={onClick}>
            {props.machine.name} / {props.machine.type.name}
          </Wrapper>
        );
      }}
    />
  );
};

export default connect(state => ({
  operatorMachineId: state.operatorMachineId,
}))(SelectedMachine);
