// @flow

import React from 'react';
import styled, { css } from 'styled-components';
import { fonts, colors } from 'shared/styleguide';

type KeyValueField = {
  label: string,
  value: string | React$Node,
  onClick?: () => void,
  onDoubleClick?: () => void,
};

type Props = {
  fields: Array<KeyValueField>,
  style?: Object,
  singleColumn?: boolean,
  verticalMode?: boolean,
};

const Wrapper = styled.ul`
  list-style: none;
  margin: 0;
  padding: 0;
  ${p =>
    p.verticalMode
      ? css`
          display: block;
        `
      : css`
          display: grid;
          grid-template-columns: 1fr 2fr;
        `}

  li {
    ${fonts.bodyRegular};
    color: ${colors.charcoalGrey};
    padding-bottom: 16px;
    word-break: break-word;

    /* Title labels are bold */
    &:nth-child(odd) {
      ${fonts.bodyBold};
      ${p =>
        p.verticalMode
          ? css`
              padding-bottom: 4px;
            `
          : ''}
    }

    ${p =>
      p.verticalMode
        ? css`
            &:last-of-type {
              padding-bottom: 0;
            }
          `
        : css`
            &:nth-last-child(-n + 2) {
              padding-bottom: 0;
            }
          `}
  }

  ${p => {
    if (p.verticalMode || p.singleColumn) {
      return '';
    }

    return css`
      @media (min-width: 1000px) {
        grid-template-columns: 1fr 2fr 1fr 2fr;

        li {
          padding-right: 8px;
        }
      }
    `;
  }}
`;

/**
 * A multi-row two or four column grid (two columns on mobile) that shows a series of key/value pairs of data
 * Useful for things like quickly outputting all of the information within a custom form
 * @param {Object} props
 * @return {React$Node}
 */
const KeyValueGrid = ({ fields, style, singleColumn, verticalMode }: Props) => (
  <Wrapper
    style={style}
    singleColumn={singleColumn}
    verticalMode={verticalMode}
  >
    {fields.map(field => {
      const { onClick, onDoubleClick } = field;

      return [
        <li key={`${field.label}-label`}>{field.label}</li>,
        <li
          key={`${field.label}-value`}
          onClick={onClick}
          onDoubleClick={onDoubleClick}
        >
          {field.value}
        </li>,
      ];
    })}
  </Wrapper>
);

KeyValueGrid.defaultProps = {
  style: undefined,
  singleColumn: false,
  verticalMode: false,
};

export default KeyValueGrid;
