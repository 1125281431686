// @flow

import React, { PureComponent } from 'react';
import { createFragmentContainer, graphql } from 'react-relay';
import * as Yup from 'yup';
import styled from 'styled-components';
import { Trans } from 'react-i18next';
import { colors, fonts } from 'shared/styleguide';
import { i18n, Analytics } from 'shared/utils';
import UpdateRunEndMutation from 'shared/mutations/UpdateRunEnd';
import AddLotMutation from 'shared/mutations/AddLot';
import {
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
} from 'shared/components/modal';
import {
  Formik,
  Form,
  FieldGroup,
  FieldGroupRow,
  FieldLabel,
  FieldDescription,
  SelectLotField,
} from 'shared/components/form';
import FatButton from 'shared/components/common/FatButton';
import Divider from 'shared/components/common/Divider';
import type { RunEndModal_run as RunFragment } from './__generated__/RunEndModal_run';

type Props = {
  onClose: () => void,
  run: RunFragment,
};

type State = {
  updateAllYieldsAtOnce: boolean,
};

const Intro = styled.div`
  ${fonts.bodyRegular};
  color: ${colors.charcoalGrey};
  padding-bottom: 24px;

  strong {
    ${fonts.bodyBold};
  }
`;

class RunEndModal extends PureComponent<Props, State> {
  state = {
    updateAllYieldsAtOnce: true,
  };

  render() {
    const { updateAllYieldsAtOnce } = this.state;
    const { onClose, run } = this.props;
    const trackFinalQuantity = Boolean(
      run.jobState.workflowState.isPromptingFinalQuantity,
    );
    const trackRunQuantityType =
      run.jobState.workflowState.trackRunQuantityType;

    const initialValues: Object = {
      userNumber: '',
      notes: '',
    };

    const validationObj: Object = {
      userNumber: Yup.string().required('Required'),
      notes: Yup.string(),
    };

    const yieldEdges = (run.yields.edges || []).filter(Boolean);

    if (trackRunQuantityType !== 'NONE') {
      if (updateAllYieldsAtOnce) {
        initialValues.runQuantity = '';
        initialValues.defectQuantity = '';
        validationObj.runQuantity = Yup.number().required('Required');
        validationObj.defectQuantity = Yup.number().required('Required');
      } else {
        for (const yieldEdge of yieldEdges) {
          initialValues[yieldEdge.node.id + 'runQuantity'] = '';
          initialValues[yieldEdge.node.id + 'defectQuantity'] = '';

          validationObj[
            yieldEdge.node.id + 'runQuantity'
          ] = Yup.number().required('Required');
          validationObj[
            yieldEdge.node.id + 'defectQuantity'
          ] = Yup.number().required('Required');
        }
      }
    }

    if (trackFinalQuantity) {
      if (updateAllYieldsAtOnce) {
        const isLotTrackingEnabled = yieldEdges.some(
          yieldEdge => yieldEdge.node.item.lotTracking,
        );

        initialValues.toLot = run.jobState.workflowState.defaultLot
          ? { value: run.jobState.workflowState.defaultLot.id }
          : null;
        validationObj.toLot = isLotTrackingEnabled
          ? Yup.object()
              .nullable()
              .required('Required')
          : Yup.object().nullable();
      } else {
        for (const yieldEdge of yieldEdges) {
          initialValues[yieldEdge.node.id + 'toLot'] = run.jobState
            .workflowState.defaultLot
            ? { value: run.jobState.workflowState.defaultLot.id }
            : null;
          validationObj[yieldEdge.node.id + 'toLot'] = yieldEdge.node?.item
            .lotTracking
            ? Yup.object()
                .nullable()
                .required('Required')
            : Yup.object().nullable();
        }
      }
    }

    return (
      <Modal maxWidth={700}>
        <Formik
          enableReinitialize
          initialValues={initialValues}
          validationSchema={Yup.object().shape(validationObj)}
          onSubmit={async (values: *, { setSubmitting }: *) => {
            setSubmitting(true);

            const input: Object = {
              runId: run.id,
              userNumber: values.userNumber,
              notes: values.notes,
            };

            if (updateAllYieldsAtOnce) {
              input.runQuantity = parseFloat(values.runQuantity || 0);
              input.defectQuantity = parseFloat(values.defectQuantity || 0);

              if (values.toLot && values.toLot.__isNew__) {
                const { addLot } = await AddLotMutation.commit({
                  variables: {
                    input: {
                      lotNumber: values.toLot?.value,
                    },
                  },
                });

                input.lotId = addLot.lotEdge.node.id;
              } else {
                input.lotId = values.toLot?.value;
              }
            } else {
              input.jobYields = [];

              for (const yieldEdge of yieldEdges) {
                let lotId = null;

                if (
                  values[yieldEdge.node.id + 'toLot'] &&
                  values[yieldEdge.node.id + 'toLot'].__isNew__
                ) {
                  const { addLot } = await AddLotMutation.commit({
                    variables: {
                      input: {
                        lotNumber: values[yieldEdge.node.id + 'toLot']?.value,
                      },
                    },
                  });

                  lotId = addLot.lotEdge.node.id;
                } else {
                  lotId = values[yieldEdge.node.id + 'toLot']?.value;
                }

                input.jobYields.push({
                  jobYieldId: yieldEdge.node.id,
                  runQuantity: parseFloat(
                    values[yieldEdge.node.id + 'runQuantity'] || 0,
                  ),
                  defectQuantity: parseFloat(
                    values[yieldEdge.node.id + 'defectQuantity'] || 0,
                  ),
                  lotId,
                });
              }
            }

            try {
              await UpdateRunEndMutation.commit({
                variables: {
                  input,
                },
              });

              onClose();
              Analytics.trackEvent('Run End', {
                operatorID: values.userNumber,
                notes: values.notes,
                machineType: run.machine?.type?.name,
                totalRunUnits: parseInt(values.runQuantity),
                defectUnits: parseInt(values.defectQuantity),
              });
            } catch (e) {
              setSubmitting(false);
              window.alert(e.message);
            }
          }}
          render={({
            values,
            errors,
            isValid,
            isSubmitting,
            handleSubmit,
            validate,
          }) => (
            <Form>
              <ModalHeader header={i18n.t('Complete Run?')} onClose={onClose} />
              <ModalBody withPadding>
                <Intro>
                  {trackFinalQuantity || trackRunQuantityType !== 'NONE' ? (
                    <Trans>
                      Enter your <strong>Operator ID</strong> and{' '}
                      <strong>Total Run Quantity</strong>, and{' '}
                      <strong>Defects Produced</strong> to complete run.
                    </Trans>
                  ) : (
                    <Trans>
                      Enter your <strong>Operator ID</strong> to complete run.
                    </Trans>
                  )}
                </Intro>
                <Divider style={{ marginBottom: 24 }} />
                <FieldGroup
                  name="userNumber"
                  type="number"
                  label={i18n.t('Operator ID')}
                  placeholder={i18n.t('Enter ID')}
                  error={errors.userNumber}
                />
                {(trackFinalQuantity || trackRunQuantityType !== 'NONE') && (
                  <>
                    <FieldGroup
                      value={updateAllYieldsAtOnce}
                      type="toggle"
                      label={i18n.t('Apply Quantites to All Items')}
                      onChange={() => {
                        this.setState({
                          updateAllYieldsAtOnce: !updateAllYieldsAtOnce,
                        });
                      }}
                      rawField
                    />
                    {updateAllYieldsAtOnce ? (
                      <>
                        <FieldGroupRow
                          left={
                            <FieldGroup
                              name="runQuantity"
                              label={i18n.t('Total Run Quantity')}
                              placeholder={i18n.t('Enter a number')}
                              type="number"
                              error={errors.runQuantity}
                            />
                          }
                          center={
                            <FieldGroup
                              name="defectQuantity"
                              label={i18n.t('Defects Produced')}
                              placeholder={i18n.t('Enter a number')}
                              type="number"
                              error={errors.defectQuantity}
                            />
                          }
                          right={
                            trackFinalQuantity ? (
                              <SelectLotField
                                name="toLot"
                                label={i18n.t('Lot #')}
                                creatable
                                clearable
                                error={errors.toLot}
                                creatingNewRecord={
                                  values.toLot && values.toLot.__isNew__
                                }
                              />
                            ) : (
                              undefined
                            )
                          }
                        />
                      </>
                    ) : (
                      yieldEdges.map(yieldEdge => (
                        <React.Fragment key={yieldEdge.node.id}>
                          <Divider style={{ marginBottom: 16 }} />
                          <FieldLabel>
                            {yieldEdge.node.item.name}&nbsp;
                          </FieldLabel>
                          <FieldDescription>
                            {i18n.t(
                              'Quantity to Produce: {{quantity, number}} {{quantitySymbol}}',
                              {
                                quantity: yieldEdge.node.quantity,
                                quantitySymbol:
                                  yieldEdge.node.item.quantityUOM.symbol,
                              },
                            )}
                            &nbsp;&nbsp;&nbsp;&nbsp;
                            {i18n.t('Part #: {{partNumber}}', {
                              partNumber: yieldEdge.node.item.partNumber,
                            })}
                          </FieldDescription>
                          <FieldGroupRow
                            left={
                              <FieldGroup
                                name={yieldEdge.node.id + 'runQuantity'}
                                label={i18n.t('Total Quantity Produced')}
                                extendedLabel={
                                  yieldEdge.node.item.quantityUOM.symbol
                                }
                                type="number"
                                placeholder={i18n.t('#')}
                                error={
                                  errors[yieldEdge.node.id + 'runQuantity']
                                }
                              />
                            }
                            center={
                              <FieldGroup
                                name={yieldEdge.node.id + 'defectQuantity'}
                                label={i18n.t('Defective Quantity')}
                                extendedLabel={
                                  yieldEdge.node.item.quantityUOM.symbol
                                }
                                type="number"
                                placeholder={i18n.t('#')}
                                error={
                                  errors[yieldEdge.node.id + 'defectQuantity']
                                }
                              />
                            }
                            right={
                              trackFinalQuantity &&
                              yieldEdge.node.item.lotTracking ? (
                                <SelectLotField
                                  name={yieldEdge.node.id + 'toLot'}
                                  label={i18n.t('Lot #')}
                                  creatable
                                  clearable
                                  error={errors[yieldEdge.node.id + 'toLot']}
                                  creatingNewRecord={
                                    values[yieldEdge.node.id + 'toLot'] &&
                                    values[yieldEdge.node.id + 'toLot']
                                      .__isNew__
                                  }
                                />
                              ) : (
                                undefined
                              )
                            }
                          />
                        </React.Fragment>
                      ))
                    )}
                  </>
                )}
                <Divider style={{ marginBottom: 24 }} />
                <FieldGroup
                  name="notes"
                  label={i18n.t('Notes')}
                  placeholder={i18n.t('Type here...')}
                  type="textarea"
                  error={errors.notes}
                  height={80}
                />
              </ModalBody>
              <ModalFooter style={{ display: 'block', textAlign: 'center' }}>
                <FatButton
                  type="submit"
                  theme="blue"
                  disabled={!isValid}
                  loading={isSubmitting}
                  onClick={handleSubmit}
                >
                  {i18n.t('Complete Run')}
                </FatButton>
              </ModalFooter>
            </Form>
          )}
        />
      </Modal>
    );
  }
}

export default createFragmentContainer(RunEndModal, {
  run: graphql`
    fragment RunEndModal_run on Run {
      id
      jobState {
        id
        workflowState {
          id
          trackRunQuantityType
          isPromptingFinalQuantity
          defaultLot {
            id
          }
        }
      }
      yields(first: null) {
        edges {
          node {
            id
            quantity
            item {
              id
              name
              partNumber
              lotTracking
              quantityUOM {
                id
                symbol
              }
            }
          }
        }
      }
      machine {
        id
        type {
          id
          name
        }
      }
    }
  `,
});
