// @flow

import React, { PureComponent } from 'react';
import styled from 'styled-components';
import SingleDatePicker from 'react-datepicker';
import { formStyles } from 'shared/styleguide';
import type { FormikProps, FieldProps } from 'formik';
import moment from 'moment';
import Icon from 'shared/components/common/Icon';

type Props = {
  form?: FormikProps<moment$Moment>,
  field?: $PropertyType<FieldProps, 'field'>,
  error?: string,
  placeholder?: string,
  allowPastDates?: boolean,
  value?: ?moment$Moment,
  onChange?: moment$Moment => void,
  onFocus?: Event => void,
  selectsStart?: boolean,
  selectsEnd?: boolean,
  minDate?: ?moment$Moment,
  maxDate?: ?moment$Moment,
  disableKeyboardInput?: boolean,
  disabled?: boolean,
};

type State = {
  focused: boolean,
};

type KeyboardDisabledCustomInputProps = any;

const Wrapper = styled.div`
  position: relative;
  ${formStyles.fieldWidth};

  .react-datepicker__input-container {
    width: 100%;
  }

  .react-datepicker-wrapper {
    width: 100%;

    input {
      ${formStyles.field};
      padding: 2px 36px 2px 8px;
    }
  }
`;

// eslint-disable-next-line react/prefer-stateless-function
class KeyboardDisabledCustomInput extends PureComponent<KeyboardDisabledCustomInputProps> {
  static defaultProps = {
    placeholder: undefined,
    value: undefined,
  };

  render() {
    const { onClick, value, placeholder } = this.props;

    return (
      <input
        className="test"
        onClick={onClick}
        placeholder={placeholder}
        value={value}
        type="text"
        readOnly
      />
    );
  }
}

// eslint-disable-next-line react/no-multi-comp
class DatePicker extends PureComponent<Props, State> {
  calendar: any = null;

  static defaultProps = {
    form: undefined,
    field: undefined,
    value: undefined,
    error: undefined,
    placeholder: undefined,
    allowPastDates: false,
    onChange: () => {},
    onFocus: () => {},
    selectsStart: false,
    selectsEnd: false,
    minDate: undefined,
    maxDate: undefined,
    disableKeyboardInput: true,
    disabled: false,
  };

  getValue = () => {
    const { value, field } = this.props;

    const val = value !== undefined ? value : field?.value;

    if (!val || !moment(val).isValid) {
      return null;
    }

    return moment(val).toDate();
  };

  handleChange = (newValue: Date) => {
    const { form, field, onChange } = this.props;

    if (form && field) {
      form.setFieldValue(field.name, moment(newValue).format());
    }

    if (onChange) {
      onChange(moment(newValue));
    }
  };

  handleCalendarClick = () => {
    if (this.calendar) {
      this.calendar.setOpen(true);
      this.calendar.setFocus(true);
    }
  };

  render() {
    const {
      error,
      placeholder,
      allowPastDates,
      selectsStart,
      selectsEnd,
      minDate,
      maxDate,
      disableKeyboardInput,
      onFocus,
      disabled,
    } = this.props;
    const datePickerProps = {};
    const selected = this.getValue();

    if (selected) {
      datePickerProps.selected = selected;
    }

    if (!allowPastDates) {
      datePickerProps.minDate = moment().toDate();
    }

    if (minDate) {
      datePickerProps.minDate = moment(minDate).toDate();
    }

    if (maxDate) {
      datePickerProps.maxDate = moment(maxDate).toDate();
    }

    if (disableKeyboardInput) {
      datePickerProps.customInput = <KeyboardDisabledCustomInput />;
    }

    if (disabled) {
      datePickerProps.disabled = disabled;
    }

    return (
      <Wrapper error={error}>
        <SingleDatePicker
          ref={r => (this.calendar = r)}
          onChange={this.handleChange}
          onFocus={onFocus}
          placeholderText={placeholder}
          selectsStart={selectsStart}
          selectsEnd={selectsEnd}
          disabled={disabled}
          disableKeyboardInput={disableKeyboardInput}
          disabledKeyboardNavigation
          {...datePickerProps}
        />
        <Icon
          type="calendar"
          size={20}
          onClick={this.handleCalendarClick}
          style={{
            position: 'absolute',
            top: 0,
            bottom: 0,
            right: 0,
            margin: 'auto 8',
          }}
        />
      </Wrapper>
    );
  }
}

export default DatePicker;
