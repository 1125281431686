/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ReaderFragment } from 'relay-runtime';
type ProgressPanel_user$ref = any;
export type RunLogType = "PROGRESS_ENTRY" | "RUN_END" | "RUN_PAUSE" | "RUN_START" | "SETUP_END" | "SETUP_PAUSE" | "SETUP_START";
export type RunStatus = "COMPLETE" | "IN_PROGRESS" | "PAUSED" | "SCHEDULED";
import type { FragmentReference } from "relay-runtime";
declare export opaque type RunControls_run$ref: FragmentReference;
declare export opaque type RunControls_run$fragmentType: RunControls_run$ref;
export type RunControls_run = {|
  +id: string,
  +setupTotalSeconds: number,
  +runTotalSeconds: number,
  +status: RunStatus,
  +logs: $ReadOnlyArray<{|
    +id: string,
    +type: RunLogType,
    +notes: ?string,
    +createdAt: any,
    +user: {|
      +id: string,
      +$fragmentRefs: ProgressPanel_user$ref,
    |},
  |}>,
  +$refType: RunControls_run$ref,
|};
export type RunControls_run$data = RunControls_run;
export type RunControls_run$key = {
  +$data?: RunControls_run$data,
  +$fragmentRefs: RunControls_run$ref,
};
*/


const node/*: ReaderFragment*/ = (function(){
var v0 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "id",
  "args": null,
  "storageKey": null
};
return {
  "kind": "Fragment",
  "name": "RunControls_run",
  "type": "Run",
  "metadata": null,
  "argumentDefinitions": [],
  "selections": [
    (v0/*: any*/),
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "setupTotalSeconds",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "runTotalSeconds",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "status",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "LinkedField",
      "alias": null,
      "name": "logs",
      "storageKey": null,
      "args": null,
      "concreteType": "RunLog",
      "plural": true,
      "selections": [
        (v0/*: any*/),
        {
          "kind": "ScalarField",
          "alias": null,
          "name": "type",
          "args": null,
          "storageKey": null
        },
        {
          "kind": "ScalarField",
          "alias": null,
          "name": "notes",
          "args": null,
          "storageKey": null
        },
        {
          "kind": "ScalarField",
          "alias": null,
          "name": "createdAt",
          "args": null,
          "storageKey": null
        },
        {
          "kind": "LinkedField",
          "alias": null,
          "name": "user",
          "storageKey": null,
          "args": null,
          "concreteType": "User",
          "plural": false,
          "selections": [
            (v0/*: any*/),
            {
              "kind": "FragmentSpread",
              "name": "ProgressPanel_user",
              "args": null
            }
          ]
        }
      ]
    }
  ]
};
})();
// prettier-ignore
(node/*: any*/).hash = '79c6d9d7dc91a7de05c78f7c4ce8ce33';
module.exports = node;
