// @flow

import { graphql } from 'react-relay';
import { Map } from 'immutable';
import moment from 'moment';
import * as Yup from 'yup';
import AddFormFieldOptionMutation from 'shared/mutations/AddFormFieldOption';
import FieldTypeConfigs from './FieldTypeConfigs';
import type { FormBuilder_formField as FormField } from './__generated__/FormBuilder_form';

type SystemFieldProps = {
  label: string,
  placeholder?: ?string,
  description?: ?string,
};

export { default as FieldTypeConfigs } from './FieldTypeConfigs';
export {
  default as renderAdditionalFieldInfo,
} from './renderAdditionalFieldInfo';
export {
  default as renderCosmeticFieldValue,
} from './renderCosmeticFieldValue';
export { default as DynamicFormField } from './DynamicFormField';

export type SystemFields = {
  [key: string]: SystemFieldProps,
};

/**
 * Takes an array of form fields, and a formFieldsValue object, and
 * returns a map of form field ids to values. Useful for defining Formik.initialValues prop
 * @param {Array<Object>} formFields
 * @param {?Object} formFieldValues
 * @param {?boolean} formatSelectValues
 * @return {Object}
 */
export const computeFieldValues = (
  formFields: Array<FormField> | $ReadOnlyArray<FormField>,
  formFieldValues: ?Object,
  formatSelectValues?: boolean = false,
): Object => {
  const currentValues = formFieldValues || {};
  const computedValues = {};

  for (const formField of formFields) {
    const currentVal =
      currentValues[formField.id]?.value !== undefined
        ? currentValues[formField.id].value
        : currentValues[formField.id];

    // If this is a "select" type field
    if (formatSelectValues && formField.type === 'MULTISELECT') {
      computedValues[formField.id] = currentVal || [];
    } else if (formatSelectValues && formField.type.includes('SELECT')) {
      computedValues[formField.id] = { value: currentVal || '' };
      // If tihs is a "date" type field
    } else if (formatSelectValues && formField.type === 'DATE') {
      computedValues[formField.id] = currentVal ? moment(currentVal) : null;
    } else {
      computedValues[formField.id] = currentVal || '';
    }
  }

  return computedValues;
};

/**
 * Iterates over form field values, and checks to see if any of the fields have
 * a newly created field option that needs to be saved to the database
 * @param {Array<Object>} formFields
 * @param {?Object} formFieldValues
 * @return {Promise<void>}
 */
export const createNewFieldOptions = async (
  formFields: Array<FormField> | $ReadOnlyArray<FormField>,
  formFieldValues: ?Object,
) => {
  if (formFieldValues) {
    for (const k in formFieldValues) {
      const formField = formFields.find(formField => formField.id === k);

      if (
        formFieldValues[k] &&
        formFieldValues[k].__isNew__ &&
        formFieldValues[k].value &&
        formField
      ) {
        await AddFormFieldOptionMutation.commit({
          variables: {
            input: {
              formFieldId: formField.id,
              value: formFieldValues[k].value,
              name: formFieldValues[k].value,
            },
          },
        });
      }
    }
  }
};

/**
 * Generate a Yup-based validation schema from an array of FormFields
 * @param {Array<Object>} formFields
 * @param {Object} systemValidationSchema
 * @return {Object}
 */
export const computeValidationSchema = (
  formFields?: *,
  systemValidationSchema?: Object = {},
): Object => {
  const schema: any = Map(systemValidationSchema || {}).toJS();

  if (formFields) {
    for (const formField of formFields) {
      schema[formField.id] = FieldTypeConfigs[formField.type].validation(
        `Invalid ${formField.name}`,
      );

      if (formField.isRequired) {
        schema[formField.id] = schema[formField.id].required('Required');
      }
    }
  }

  return Yup.object().shape(schema);
};

graphql`
  fragment FormBuilder_form on Form {
    id
    name
    type
    systemKey
    productionType {
      id
    }
    fields {
      id
      systemKey
      type
      name
      description
      placeholder
      isRequired
      isCreatable
      applicationType
      displayOrder
      properties
      options {
        name
        value
      }
    }
  }
`;

graphql`
  fragment FormBuilder_formField on FormField {
    id
    systemKey
    type
    name
    description
    placeholder
    isRequired
    isCreatable
    applicationType
    displayOrder
    properties
    options {
      name
      value
    }
  }
`;
