// @flow

import React from 'react';
import { hot } from 'react-hot-loader/root';
import { BrowserRouter, Route, Redirect, Switch } from 'react-router-dom';
import { Provider } from 'react-redux';
import GlobalStyles from 'shared/GlobalStyles';
import store from './store';
import OperatorPortalBootstrapper from './OperatorPortalBootstrapper';
import SignIn from './scenes/SignIn';
import ChooseMachine from './scenes/ChooseMachine';
import Runs from './scenes/Runs';
import Run from './scenes/Run';

const App = () => (
  <Provider store={store}>
    <BrowserRouter>
      <>
        <OperatorPortalBootstrapper>
          <Switch>
            <Route exact path="/" render={() => <Redirect to="/runs" />} />
            <Route exact path="/signin" component={SignIn} />
            <Route exact path="/choose-machine" component={ChooseMachine} />
            <Route exact path="/operator-signin" component={SignIn} />
            <Route exact path="/runs" component={Runs} />
            <Route exact path="/run/:id/:section?" component={Run} />
            <Route exact render={() => <Redirect to="/runs" />} />
          </Switch>
        </OperatorPortalBootstrapper>
        <GlobalStyles />
      </>
    </BrowserRouter>
  </Provider>
);

export default hot(App);
