// @flow

import React from 'react';
import ReactTable from 'react-table';
import styled, { css } from 'styled-components';
import { colors, fonts } from 'shared/styleguide';

type ColumnProps = {
  Header: string | React$Node | React$ComponentType<any>,
  accessor: string,
  Cell?: string | React$Node | React$ComponentType<any>,
  width?: number,
  minWidth?: number,
  maxWidth?: number,
  style?: Object,
  headerStyle?: Object,
  show?: boolean,
};

type Props = {
  data: $ReadOnlyArray<Object>,
  columns: Array<ColumnProps>,
  nested?: boolean,
  onRowClick?: (Object, Event) => void,
  SubComponent?: Function,
};

const Wrapper = styled.div`
  .ReactTable {
    /* Eliminate stock borders and shadows */
    border: none;

    padding: ${p => (p.nested ? `0 16px 16px 24px` : 0)};

    .rt-tbody {
      overflow: visible;
    }

    .rt-thead {
      box-shadow: none;
    }

    .rt-th,
    .rt-td,
    .rt-tr-group {
      outline: none;
      border: none;
    }

    /* Vertically align all column cells on each header AND row */
    .rt-tr {
      align-items: center;
    }

    /* Set the min-height on only rows. DOESN'T AFFECT HEADERS */
    .rt-tr-group .rt-tr {
      min-height: 48px;
    }

    /* Style the header */
    .rt-th {
      padding: 8px;
      ${fonts.smallRegular};
      color: ${colors.battleshipGrey};
      text-transform: uppercase;
      text-align: left;

      &:first-child {
        padding-left: 16px;
      }

      &:last-child {
        padding-right: 16px;
      }
    }

    /* Style the row column */
    .rt-td {
      padding: 0 8px;
      ${fonts.bodyRegular};
      color: ${colors.charcoalGrey};

      &:first-child {
        padding-left: 16px;
      }

      &:last-child {
        padding-right: 16px;
      }
    }

    /* Setup the borders and border radiuses for the table and rows */
    .rt-tr-group {
      background-color: ${colors.white};
      border: 1px solid ${colors.lightBlueGrey};
      border-bottom: none;

      &:first-child {
        border-top-left-radius: ${p => (p.nested ? 0 : 10)}px;
        border-top-right-radius: ${p => (p.nested ? 0 : 10)}px;
      }

      &:last-child {
        border-bottom: 1px solid ${colors.lightBlueGrey};
        border-bottom-left-radius: ${p => (p.nested ? 0 : 10)}px;
        border-bottom-right-radius: ${p => (p.nested ? 0 : 10)}px;
      }

      /* Show a hover effect on desktop mode for clickable rows */
      @media (min-width: 1000px) {
        ${p => {
          if (p.clickableRow) {
            return css`
              &:hover {
                cursor: pointer;
                background-color: ${colors.veryLightBlue};
              }
            `;
          }

          return '';
        }};
      }
    }
  }
`;

const Table = ({ data, columns, nested, onRowClick, SubComponent }: Props) => (
  <Wrapper clickableRow={Boolean(onRowClick)} nested={nested}>
    <ReactTable
      data={data}
      columns={columns}
      sortable={false}
      showPagination={false}
      resizable={false}
      minRows={0}
      defaultPageSize={10000}
      getTrProps={(state, rowInfo, column) => ({
        onClick: e => {
          if (onRowClick) {
            onRowClick(rowInfo.original, e);
          }
        },
      })}
      SubComponent={SubComponent}
    />
  </Wrapper>
);

Table.defaultProps = {
  nested: undefined,
  onRowClick: undefined,
  SubComponent: undefined,
};

export default Table;
