// @flow

import React from 'react';
import { QueryRenderer, graphql } from 'react-relay';
import relayEnvironment from 'shared/gql/relayEnvironment';

type Props = {
  userId: string,
};

export default ({ userId }: Props) => (
  <QueryRenderer
    environment={relayEnvironment}
    query={graphql`
      query UserLabelQuery($userId: ID!) {
        user(id: $userId) {
          id
          firstName
          lastName
        }
      }
    `}
    variables={{ userId }}
    render={query => {
      if (!query.props) {
        return <span>. . .</span>;
      }

      if (query.props.user) {
        return (
          <span>
            {query.props.user.firstName} {query.props.user.lastName}
          </span>
        );
      }

      return <span>Unknown User</span>;
    }}
  />
);
