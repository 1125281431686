/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ReaderFragment } from 'relay-runtime';
type AdditionalFieldInfo_formField$ref = any;
type CosmeticFieldValue_formField$ref = any;
type UserWidget_user$ref = any;
export type FormFieldType = "CHECKBOX" | "DATE" | "MULTILINE_TEXT" | "MULTISELECT" | "NUMBER" | "SELECT" | "SELECT_CUSTOMER" | "SELECT_MACHINE" | "SELECT_ORDER" | "SELECT_USER" | "TEXT";
export type JobStateStatus = "COMPLETE" | "INCOMPLETE" | "IN_PROGRESS" | "NEEDS_ATTENTION" | "PAUSED";
export type WorkflowStateType = "JOB_COMPLETED" | "JOB_CREATED" | "JOB_ITEM_COUNT" | "RUN" | "TASK";
import type { FragmentReference } from "relay-runtime";
declare export opaque type JobStateGrid_jobState$ref: FragmentReference;
declare export opaque type JobStateGrid_jobState$fragmentType: JobStateGrid_jobState$ref;
export type JobStateGrid_jobState = {|
  +id: string,
  +formFieldValues: any,
  +isEnabled: boolean,
  +status: JobStateStatus,
  +user: ?{|
    +id: string,
    +$fragmentRefs: UserWidget_user$ref,
  |},
  +workflowState: {|
    +id: string,
    +name: string,
    +isAssignable: ?boolean,
    +type: ?WorkflowStateType,
    +form: ?{|
      +id: string,
      +fields: $ReadOnlyArray<{|
        +id: string,
        +name: string,
        +type: FormFieldType,
        +$fragmentRefs: CosmeticFieldValue_formField$ref & AdditionalFieldInfo_formField$ref,
      |}>,
    |},
  |},
  +$refType: JobStateGrid_jobState$ref,
|};
export type JobStateGrid_jobState$data = JobStateGrid_jobState;
export type JobStateGrid_jobState$key = {
  +$data?: JobStateGrid_jobState$data,
  +$fragmentRefs: JobStateGrid_jobState$ref,
};
*/


const node/*: ReaderFragment*/ = (function(){
var v0 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "id",
  "args": null,
  "storageKey": null
},
v1 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "name",
  "args": null,
  "storageKey": null
},
v2 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "type",
  "args": null,
  "storageKey": null
};
return {
  "kind": "Fragment",
  "name": "JobStateGrid_jobState",
  "type": "JobState",
  "metadata": null,
  "argumentDefinitions": [],
  "selections": [
    (v0/*: any*/),
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "formFieldValues",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "isEnabled",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "status",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "LinkedField",
      "alias": null,
      "name": "user",
      "storageKey": null,
      "args": null,
      "concreteType": "User",
      "plural": false,
      "selections": [
        (v0/*: any*/),
        {
          "kind": "FragmentSpread",
          "name": "UserWidget_user",
          "args": null
        }
      ]
    },
    {
      "kind": "LinkedField",
      "alias": null,
      "name": "workflowState",
      "storageKey": null,
      "args": null,
      "concreteType": "WorkflowState",
      "plural": false,
      "selections": [
        (v0/*: any*/),
        (v1/*: any*/),
        {
          "kind": "ScalarField",
          "alias": null,
          "name": "isAssignable",
          "args": null,
          "storageKey": null
        },
        (v2/*: any*/),
        {
          "kind": "LinkedField",
          "alias": null,
          "name": "form",
          "storageKey": null,
          "args": null,
          "concreteType": "Form",
          "plural": false,
          "selections": [
            (v0/*: any*/),
            {
              "kind": "LinkedField",
              "alias": null,
              "name": "fields",
              "storageKey": null,
              "args": null,
              "concreteType": "FormField",
              "plural": true,
              "selections": [
                (v0/*: any*/),
                (v1/*: any*/),
                (v2/*: any*/),
                {
                  "kind": "FragmentSpread",
                  "name": "CosmeticFieldValue_formField",
                  "args": null
                },
                {
                  "kind": "FragmentSpread",
                  "name": "AdditionalFieldInfo_formField",
                  "args": null
                }
              ]
            }
          ]
        }
      ]
    }
  ]
};
})();
// prettier-ignore
(node/*: any*/).hash = 'e78db32e28b60e903d2c403e1bc4720e';
module.exports = node;
