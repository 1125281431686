// @flow

import styled from 'styled-components';
import { colors, fonts } from 'shared/styleguide';

export default styled.div`
  padding: ${p => (p.withPadding ? '16px 24px 24px 24px' : 0)};
  color: ${colors.charcoalGrey};
  ${fonts.bodyRegular};
`;
