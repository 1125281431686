// @flow

import React from 'react';
import styled from 'styled-components';
import { colors, fonts } from 'shared/styleguide';

const Wrapper = styled.div`
  padding: 5px 0 0 2px;
  ${fonts.bodyRegular}
  color: ${p => (p.type === 'error' ? colors.cherry : colors.greenBlue)};
`;

type Props = {
  type?: 'error' | 'basic',
  children: React$Node,
  style?: Object,
};

/**
 * Basic form field message that shows below most
 * input fields. Used to show form errors or arbitrary messages
 * @example <FieldMessage>Email is invalid</FieldMessage>
 * @param {Object} props
 * @return {React$Node}
 */
const FieldMessage = ({ type, children, style }: Props) => (
  <Wrapper type={type} style={style}>
    {children}
  </Wrapper>
);

FieldMessage.defaultProps = {
  type: 'basic',
  style: undefined,
};

export default FieldMessage;
