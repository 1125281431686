// @flow

import React, { PureComponent } from 'react';
import styled from 'styled-components';
import { i18n } from 'shared/utils';
import { colors, fonts } from 'shared/styleguide';
import { fetchQuery, graphql } from 'relay-runtime';
import relayEnvironment from 'shared/gql/relayEnvironment';
import { FieldGroup } from 'shared/components/form';

type Props = {
  name?: string,
  rawField?: boolean,
  label?: ?string,
  error?: ?string,
  queryVariables?: ?Object,
  excludeIds?: Array<string>,
  creatingNewRecord?: boolean,
};

type State = {
  fetchedOptions: Array<Object>,
};

const ProductList = styled.div`
  ${fonts.smallBold};
  ${colors.charcoalGrey};
  padding-bottom: 3px;
  padding-left: 4px;
`;

const QUERY = graphql`
  query SelectOrderFieldQuery(
    $sortBy: OrderSort
    $customerIds: [ID!]
    $search: String
    $orderIds: [ID!]
  ) {
    orders(
      first: 50
      sortBy: $sortBy
      customerIds: $customerIds
      search: $search
      orderIds: $orderIds
    ) {
      edges {
        node {
          id
          orderNumber
          customer {
            id
            name
          }
          yields {
            edges {
              node {
                id
                orderedQuantity
                item {
                  id
                  name
                  quantityUOM {
                    id
                    symbol
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`;

class SelectOrderField extends PureComponent<Props, State> {
  static defaultProps = {
    name: undefined,
    rawField: false,
    label: undefined,
    error: undefined,
    excludeIds: [],
    queryVariables: {},
    creatingNewRecord: false,
  };

  mounted = false;

  state = {
    fetchedOptions: [],
  };

  componentDidMount() {
    this.mounted = true;
  }

  componentWillUnmount() {
    this.mounted = false;
  }

  getData = async (inputValue: string) => {
    const { queryVariables, excludeIds } = this.props;

    try {
      const results = await fetchQuery(relayEnvironment, QUERY, {
        search: inputValue,
        ...queryVariables,
      });

      const options = this.massageData(results.orders.edges || [], excludeIds);

      if (this.mounted) {
        this.setState({
          fetchedOptions: options,
        });
      }

      return options;
    } catch (e) {
      return [];
    }
  };

  /**
   * Massage records to be ReactSelect-friendly
   * @param {Array<Object>} records
   * @param {Array<string | number>} excludeIds
   * @return {Array<Object>}
   */
  massageData = (
    records: Array<Object>,
    excludeIds?: Array<string> = [],
  ): Array<Object> => {
    return (
      records
        // filter out specified ids
        .filter(a => !a.node || excludeIds.indexOf(a.node.id.toString()) < 0)
        .map(a => ({
          data: a.node,
          value: a.node.id,
          label: (
            <div style={{ display: 'inline-block' }}>
              {i18n.t(`{{customerName}} - Order {{orderNumber}}`, {
                orderNumber: a.node.orderNumber,
                customerName: a.node.customer.name,
              })}
              {a.node.yields.edges.map(({ node }) => (
                <ProductList id={node.id} key={node.id}>
                  &middot;
                  {i18n.t(' {{itemName}} - {{quantity}} {{qtyLabel}}', {
                    itemName: node.item.name,
                    quantity: node.orderedQuantity,
                    qtyLabel: node.item.quantityUOM.symbol,
                  })}
                </ProductList>
              ))}
            </div>
          ),
        }))
    );
  };

  render() {
    const {
      name,
      rawField,
      label,
      error,
      creatingNewRecord,
      ...fieldProps
    } = this.props;
    const { fetchedOptions } = this.state;

    return (
      <FieldGroup
        name={name}
        rawField={rawField || false}
        label={label !== undefined ? label : i18n.t('Order')}
        error={error}
        message={
          creatingNewRecord ? i18n.t(`A new order will be created`) : null
        }
        clearable
        placeholder={i18n.t(`Search for Order`)}
        {...fieldProps}
        options={fetchedOptions}
        type="select"
        loadOptions={this.getData}
        defaultOptions
        selectOrderField
      />
    );
  }
}

export default SelectOrderField;
