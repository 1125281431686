// @flow

import { LocalStorage } from 'shared/utils';

export const ActionTypes = {
  TOGGLE_MAIN_MENU: 'TOGGLE_MAIN_MENU',
  QUEUE_ALERT_NOTIFICATION: 'QUEUE_ALERT_NOTIFICATION',
  REMOVE_ALERT_NOTIFICATION: 'REMOVE_ALERT_NOTIFICATION',

  SET_OPERATOR_PRODUCTION_TYPE_ID: 'SET_OPERATOR_PRODUCTION_TYPE_ID',
  SET_OPERATOR_MACHINE_ID: 'SET_OPERATOR_MACHINE_ID',
};

export type ActionPayload = $Shape<{
  type: $Keys<typeof ActionTypes>,
  data: any,
}>;

type GlobalState = {
  mainMenuOpen: boolean,
  alertNotifications: Array<Object>,

  // Operator portal state
  operatorMachineId: ?string,
};

const initialState = {
  mainMenuOpen: false,
  alertNotifications: [],

  // Operator portal state
  operatorMachineId: LocalStorage.get('operatorMachineId', null),
};

export default function(
  state: GlobalState = initialState,
  action: ActionPayload,
): GlobalState {
  switch (action.type) {
    /**
     * Toggle the main menu in/out of view
     */
    case ActionTypes.TOGGLE_MAIN_MENU:
      return {
        ...state,
        mainMenuOpen: action.data,
      };

    /**
     * Add a notification into the alertNotifications stack
     */

    case ActionTypes.QUEUE_ALERT_NOTIFICATION:
      return {
        ...state,
        alertNotifications: [action.data].concat(state.alertNotifications),
      };

    /**
     * Remove a notification from the alertNotifications stack
     */
    case ActionTypes.REMOVE_ALERT_NOTIFICATION:
      return {
        ...state,
        alertNotifications: state.alertNotifications.filter(
          a => a.id !== action.data,
        ),
      };

    /**
     * Update the Operator Portal machine
     */
    case ActionTypes.SET_OPERATOR_MACHINE_ID:
      return {
        ...state,
        operatorMachineId: action.data,
      };
  }

  return state;
}
