// @flow

import React, { PureComponent } from 'react';
import { i18n } from 'shared/utils';
import { graphql, fetchQuery } from 'react-relay';
import relayEnvironment from 'shared/gql/relayEnvironment';
import { FieldGroup } from 'shared/components/form';

type Props = {
  name?: string,
  rawField?: boolean,
  label?: ?string,
  error?: ?string,
  queryVariables?: ?Object,
  excludeIds?: Array<string>,
  creatingNewRecord?: boolean,
  isSearchable?: boolean,
};

type State = {
  fetchedOptions: Array<Object>,
};

const QUERY = graphql`
  query SelectMachineFieldQuery($workflowIds: [ID!], $machineTypeId: ID) {
    machines(workflowIds: $workflowIds, machineTypeId: $machineTypeId) {
      edges {
        node {
          id
          name
          type {
            id
            name
          }
        }
      }
    }
  }
`;

class SelectMachineField extends PureComponent<Props, State> {
  static defaultProps = {
    name: undefined,
    rawField: false,
    label: undefined,
    error: undefined,
    excludeIds: [],
    queryVariables: {},
    creatingNewRecord: false,
    isSearchable: undefined,
  };

  mounted = false;

  state = {
    fetchedOptions: [],
  };

  componentDidMount() {
    this.mounted = true;

    this.getData();
  }

  componentWillUnmount() {
    this.mounted = false;
  }

  getData = async (inputValue?: string) => {
    const { queryVariables, excludeIds } = this.props;

    try {
      const results = await fetchQuery(relayEnvironment, QUERY, {
        search: inputValue,
        ...queryVariables,
      });

      const options = this.massageData(
        results.machines.edges || [],
        excludeIds,
      );

      if (this.mounted) {
        this.setState({
          fetchedOptions: options,
        });
      }

      return options;
    } catch (e) {
      return [];
    }
  };

  /**
   * Massage records to be ReactSelect-friendly
   * @param {Array<Object>} records
   * @param {Array<string | number>} excludeIds
   * @return {Array<Object>}
   */
  massageData = (
    records: Array<Object>,
    excludeIds?: Array<string> = [],
  ): Array<Object> => {
    return (
      records
        // filter out specified ids
        .filter(a => !a.node || excludeIds.indexOf(a.node.id.toString()) < 0)
        .map(({ node }) => ({
          data: node,
          value: node.id,
          label: node.name,
        }))
    );
  };

  render() {
    const {
      name,
      rawField,
      label,
      error,
      creatingNewRecord,
      ...fieldProps
    } = this.props;
    const { fetchedOptions } = this.state;

    return (
      <FieldGroup
        name={name}
        rawField={rawField || false}
        label={label !== undefined ? label : i18n.t('Work Center')}
        error={error}
        message={
          creatingNewRecord ? i18n.t(`A new work center will be created`) : null
        }
        clearable
        placeholder={i18n.t('Work Center')}
        {...fieldProps}
        options={fetchedOptions}
        type="select"
        // loadOptions={this.getData}
        defaultOptions
      />
    );
  }
}

export default SelectMachineField;
