/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ReaderFragment } from 'relay-runtime';
type RunLogs_run$ref = any;
import type { FragmentReference } from "relay-runtime";
declare export opaque type RunLog_run$ref: FragmentReference;
declare export opaque type RunLog_run$fragmentType: RunLog_run$ref;
export type RunLog_run = {|
  +id: string,
  +machine: {|
    +id: string
  |},
  +yields: {|
    +edges: ?$ReadOnlyArray<?{|
      +setupQuantity: ?number,
      +runQuantity: ?number,
      +defectQuantity: ?number,
      +node: {|
        +id: string,
        +item: {|
          +id: string,
          +name: string,
          +quantityUOM: {|
            +id: string,
            +symbol: string,
          |},
        |},
      |},
    |}>
  |},
  +$fragmentRefs: RunLogs_run$ref,
  +$refType: RunLog_run$ref,
|};
export type RunLog_run$data = RunLog_run;
export type RunLog_run$key = {
  +$data?: RunLog_run$data,
  +$fragmentRefs: RunLog_run$ref,
};
*/


const node/*: ReaderFragment*/ = (function(){
var v0 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "id",
  "args": null,
  "storageKey": null
};
return {
  "kind": "Fragment",
  "name": "RunLog_run",
  "type": "Run",
  "metadata": null,
  "argumentDefinitions": [],
  "selections": [
    (v0/*: any*/),
    {
      "kind": "LinkedField",
      "alias": null,
      "name": "machine",
      "storageKey": null,
      "args": null,
      "concreteType": "Machine",
      "plural": false,
      "selections": [
        (v0/*: any*/)
      ]
    },
    {
      "kind": "LinkedField",
      "alias": null,
      "name": "yields",
      "storageKey": null,
      "args": null,
      "concreteType": "RunYieldConnection",
      "plural": false,
      "selections": [
        {
          "kind": "LinkedField",
          "alias": null,
          "name": "edges",
          "storageKey": null,
          "args": null,
          "concreteType": "RunYieldEdge",
          "plural": true,
          "selections": [
            {
              "kind": "ScalarField",
              "alias": null,
              "name": "setupQuantity",
              "args": null,
              "storageKey": null
            },
            {
              "kind": "ScalarField",
              "alias": null,
              "name": "runQuantity",
              "args": null,
              "storageKey": null
            },
            {
              "kind": "ScalarField",
              "alias": null,
              "name": "defectQuantity",
              "args": null,
              "storageKey": null
            },
            {
              "kind": "LinkedField",
              "alias": null,
              "name": "node",
              "storageKey": null,
              "args": null,
              "concreteType": "JobYield",
              "plural": false,
              "selections": [
                (v0/*: any*/),
                {
                  "kind": "LinkedField",
                  "alias": null,
                  "name": "item",
                  "storageKey": null,
                  "args": null,
                  "concreteType": "Item",
                  "plural": false,
                  "selections": [
                    (v0/*: any*/),
                    {
                      "kind": "ScalarField",
                      "alias": null,
                      "name": "name",
                      "args": null,
                      "storageKey": null
                    },
                    {
                      "kind": "LinkedField",
                      "alias": null,
                      "name": "quantityUOM",
                      "storageKey": null,
                      "args": null,
                      "concreteType": "UOM",
                      "plural": false,
                      "selections": [
                        (v0/*: any*/),
                        {
                          "kind": "ScalarField",
                          "alias": null,
                          "name": "symbol",
                          "args": null,
                          "storageKey": null
                        }
                      ]
                    }
                  ]
                }
              ]
            }
          ]
        }
      ]
    },
    {
      "kind": "FragmentSpread",
      "name": "RunLogs_run",
      "args": null
    }
  ]
};
})();
// prettier-ignore
(node/*: any*/).hash = '3e8b489616abc71b3d3eb1bd91f86bb3';
module.exports = node;
