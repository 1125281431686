// @flow

import React, { PureComponent } from 'react';
import styled from 'styled-components';
import { formStyles } from 'shared/styleguide';
import type { FormikProps, FieldProps } from 'formik';
import type { FieldSize } from './types';

const TextareaStyled = styled.textarea`
  position: relative;
  ${formStyles.fieldWidth};

  padding: 0 10px;
  ${formStyles.field};
  padding: 10px;
  line-height: 22px;
  height: ${p => p.height}px;
`;

type Props = {
  form?: FormikProps<string>,
  field?: $PropertyType<FieldProps, 'field'>,
  height?: number,
  error?: string,
  size?: FieldSize,
  placeholder?: string,
  value?: ?string,
  defaultValue?: string,
  disabled?: boolean,
  onChange?: string => void,
  onKeyDown?: KeyboardEvent => void,
  onBlur?: Event => void,
};

class Textarea extends PureComponent<Props> {
  static defaultProps = {
    form: undefined,
    field: undefined,
    height: 150,
    error: undefined,
    size: 'large',
    placeholder: undefined,
    value: undefined,
    defaultValue: undefined,
    disabled: false,
    onChange: () => {},
    onKeyDown: () => {},
    onBlur: () => {},
  };

  handleBlur = (e: Event) => {
    const { form, field, onBlur } = this.props;

    if (form && field) {
      form.setFieldTouched(field.name, true);
    }

    if (onBlur) {
      onBlur(e);
    }
  };

  handleChange = (e: SyntheticEvent<HTMLInputElement>) => {
    const { form, field, onChange } = this.props;

    if (form && field) {
      form.setFieldValue(field.name, e.currentTarget.value);
    }

    if (onChange) {
      onChange(e.currentTarget.value);
    }
  };

  handleKeyDown = (e: KeyboardEvent, ...args: *) => {
    const { onKeyDown } = this.props;

    if (onKeyDown) {
      onKeyDown(e, ...args);
    }
  };

  render() {
    const {
      field,
      value,
      defaultValue,
      error,
      height,
      size,
      placeholder,
      disabled,
    } = this.props;

    return (
      <TextareaStyled
        defaultValue={defaultValue}
        placeholder={placeholder}
        onChange={this.handleChange}
        onKeyDown={this.handleKeyDown}
        onBlur={this.handleBlur}
        disabled={disabled}
        value={value !== undefined ? value : field?.value}
        error={error}
        height={height}
        size={size}
      />
    );
  }
}

export default Textarea;
