// @flow
import styled, { keyframes } from 'styled-components';
import { lighten } from 'polished';
import { colors, fonts, helpers, formStyles } from 'shared/styleguide';

const bounceDelayAnimation = keyframes`
  0%, 80%, 100% { 
    transform: scale(0);
  } 40% { 
    transform: scale(1.0);
  }
`;

export const StyledButton = styled.button`
  position: relative;
  color: ${p => getFontColor(p)};
  ${fonts.bodyBold};
  border-radius: 4px;
  text-align: center;
  border: ${p => getBorderStyle(p)};
  padding: 0 16px;
  background-color: ${p => getBackgroundColor(p)};
  transition: background-color 300ms ease;
  width: ${helpers.widthProp};
  min-height: ${formStyles.INPUT_HEIGHT}px;
  &:hover {
    ${p => {
      if (!p.disabled && !p.loading) {
        return `
          cursor: pointer;
          background-color: ${lighten(0.05, getBackgroundColor(p))};
        `;
      }
      return '';
    }};
  }
`;

export const Loading = styled.div`
  margin: auto;
  text-align: center;
  div {
    width: 14px;
    height: 14px;
    background-color: ${p => getLoaderColor(p.theme)};
    margin: 0 1px;
    padding: 0;
    border-radius: 100%;
    display: inline-block;
    animation: ${bounceDelayAnimation} 1.4s infinite ease-in-out both;
  }
  div:first-child {
    animation-delay: -0.32s;
  }
  div:nth-child(2) {
    animation-delay: -0.16s;
  }
`;

function getBackgroundColor(props) {
  if (props.disabled && !props.loading) {
    return colors.paleGrey;
  }

  switch (props.theme) {
    case 'blue':
      return colors.azul;
    case 'red':
      return colors.cherry;
    case 'green':
      return colors.greenBlue;
    case 'border-white':
      return colors.white;
    case 'transparent':
      return 'rgba(0,0,0,0)';
  }

  return colors.azul;
}

function getFontColor(props) {
  if (props.disabled) {
    return colors.coolGrey;
  }

  switch (props.theme) {
    case 'transparent':
      return colors.coolGrey;
    case 'border-white':
      return colors.charcoalGrey;
  }

  return colors.white;
}

function getLoaderColor(theme) {
  switch (theme) {
    case 'transparent':
      return colors.charcoalGrey;
    case 'border-white':
      return colors.charcoalGrey;
  }

  return colors.white;
}

function getBorderStyle(props) {
  if (props.disabled) {
    return 'none';
  }

  switch (props.theme) {
    case 'border-white':
      return `1px solid ${colors.charcoalGrey}`;
  }

  return 'none';
}
