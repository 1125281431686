/**
 * @flow
 * @relayHash 3b20c1dad564f94dd5d52ac4fdad1c51
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type CompanyUserRole = "ADMIN" | "SUPERUSER" | "USER" | "WORKER";
export type SortDirection = "ASC" | "DESC";
export type UserSortField = "CREATED_AT" | "FIRST_NAME" | "LAST_NAME" | "UPDATED_AT" | "USER_NUMBER";
export type UserSort = {|
  field: UserSortField,
  direction: SortDirection,
|};
export type SelectUserFieldQueryVariables = {|
  sortBy?: ?UserSort,
  companyUserRoles?: ?$ReadOnlyArray<CompanyUserRole>,
  search?: ?string,
|};
export type SelectUserFieldQueryResponse = {|
  +users: {|
    +edges: ?$ReadOnlyArray<?{|
      +node: {|
        +id: string,
        +firstName: string,
        +lastName: string,
        +userNumber: string,
      |}
    |}>
  |}
|};
export type SelectUserFieldQuery = {|
  variables: SelectUserFieldQueryVariables,
  response: SelectUserFieldQueryResponse,
|};
*/


/*
query SelectUserFieldQuery(
  $sortBy: UserSort
  $companyUserRoles: [CompanyUserRole!]
  $search: String
) {
  users(first: 50, sortBy: $sortBy, companyUserRoles: $companyUserRoles, search: $search) {
    edges {
      node {
        id
        firstName
        lastName
        userNumber
      }
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "sortBy",
    "type": "UserSort",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "companyUserRoles",
    "type": "[CompanyUserRole!]",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "search",
    "type": "String",
    "defaultValue": null
  }
],
v1 = [
  {
    "kind": "LinkedField",
    "alias": null,
    "name": "users",
    "storageKey": null,
    "args": [
      {
        "kind": "Variable",
        "name": "companyUserRoles",
        "variableName": "companyUserRoles"
      },
      {
        "kind": "Literal",
        "name": "first",
        "value": 50
      },
      {
        "kind": "Variable",
        "name": "search",
        "variableName": "search"
      },
      {
        "kind": "Variable",
        "name": "sortBy",
        "variableName": "sortBy"
      }
    ],
    "concreteType": "UserConnection",
    "plural": false,
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "edges",
        "storageKey": null,
        "args": null,
        "concreteType": "UserEdge",
        "plural": true,
        "selections": [
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "node",
            "storageKey": null,
            "args": null,
            "concreteType": "User",
            "plural": false,
            "selections": [
              {
                "kind": "ScalarField",
                "alias": null,
                "name": "id",
                "args": null,
                "storageKey": null
              },
              {
                "kind": "ScalarField",
                "alias": null,
                "name": "firstName",
                "args": null,
                "storageKey": null
              },
              {
                "kind": "ScalarField",
                "alias": null,
                "name": "lastName",
                "args": null,
                "storageKey": null
              },
              {
                "kind": "ScalarField",
                "alias": null,
                "name": "userNumber",
                "args": null,
                "storageKey": null
              }
            ]
          }
        ]
      }
    ]
  }
];
return {
  "kind": "Request",
  "fragment": {
    "kind": "Fragment",
    "name": "SelectUserFieldQuery",
    "type": "Query",
    "metadata": null,
    "argumentDefinitions": (v0/*: any*/),
    "selections": (v1/*: any*/)
  },
  "operation": {
    "kind": "Operation",
    "name": "SelectUserFieldQuery",
    "argumentDefinitions": (v0/*: any*/),
    "selections": (v1/*: any*/)
  },
  "params": {
    "operationKind": "query",
    "name": "SelectUserFieldQuery",
    "id": null,
    "text": "query SelectUserFieldQuery(\n  $sortBy: UserSort\n  $companyUserRoles: [CompanyUserRole!]\n  $search: String\n) {\n  users(first: 50, sortBy: $sortBy, companyUserRoles: $companyUserRoles, search: $search) {\n    edges {\n      node {\n        id\n        firstName\n        lastName\n        userNumber\n      }\n    }\n  }\n}\n",
    "metadata": {}
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '4288d36ec30cc6d553d34089b7e09950';
module.exports = node;
