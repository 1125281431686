// @flow

import React from 'react';
import { TransitionGroup, CSSTransition } from 'react-transition-group';
import styled from 'styled-components';
import { colors, fonts } from 'shared/styleguide';
import Icon from 'shared/components/common/Icon';

type AlertNotificationProps = {
  id: number,
  type: 'success' | 'error' | 'info',
  message: string | { title: string, body: ?string },
};

type Props = {
  alertNotifications: Array<AlertNotificationProps>,
};

const Wrapper = styled.div`
  position: fixed;
  max-width: 400px;
  padding: 10px;
  top: 0px;
  left: 50%;
  transform: translateX(-50%);
  z-index: 3; /* FIXME: We normally don't use z-index in this codebase. Revisit when we figure out how to address z-indexing in react-calendar-timeline package */

  @media (min-width: 1000px) {
    padding: 0;
    top: 20px;
    right: 20px;
  }

  /* The following are react-transition-group classes */
  .notification-transition-enter {
    opacity: 0.01;
    transform: translateY(-500px);

    &.notification-transition-enter-active {
      opacity: 1;
      transform: translateY(0);
      transition: transform 300ms, opacity 300ms ease-in;
    }
  }

  .notification-transition-exit {
    opacity: 1;

    &.notification-transition-exit-active {
      opacity: 0.01;
      transition: opacity 300ms ease-in;
    }
  }
`;

const Notification = styled.div`
  padding: 16px;
  border-radius: 5px;
  border: 1px solid ${p => getAccentFromType(p.type)};
  box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.2)};
  margin-bottom: 10px;
  background-color: ${p => getBackgroundColor(p.type)};
  color: ${p => getAccentFromType(p.type)};  

  th {
    padding: 0;
    margin: 0;
    text-align: left;
    vertical-align: center;

    svg {
      flex-shrink: 0;
      width: 24px;
      height: 24px;
    }

    &:first-child {
      padding-right: 8px;
    }

    ${fonts.bodyBold};
  }

  td {
    ${fonts.bodyRegular};
  }
`;

function getAccentFromType(type: string) {
  if (type === 'error') {
    return colors.cherry;
  }

  if (type === 'success') {
    return colors.greenBlue;
  }

  return colors.charcoalGrey;
}

function getBackgroundColor(type: string) {
  if (type === 'error') {
    return '#F8E6EA';
  }

  if (type === 'success') {
    return '#E8F7F2';
  }

  return colors.paleGreyTwo;
}

export default ({ alertNotifications }: Props) => (
  <Wrapper>
    <TransitionGroup>
      {alertNotifications.map(notification => (
        <CSSTransition
          key={notification.id}
          classNames="notification-transition"
          timeout={300}
        >
          <Notification type={notification.type}>
            <table>
              <thead>
                <tr>
                  <th>
                    {(() => {
                      if (notification.type === 'success') {
                        return <Icon type="circle-check-green" size={24} />;
                      }

                      if (notification.type === 'error') {
                        return <Icon type="circle-alert-red" size={24} />;
                      }

                      return <Icon type="circle-info" size={24} />;
                    })()}
                  </th>
                  <th>
                    {typeof notification.message === 'object'
                      ? notification.message.title
                      : notification.message}
                  </th>
                </tr>
              </thead>
              {typeof notification.message === 'object' && (
                <tbody>
                  <tr>
                    <td />
                    <td>{notification.message.body}</td>
                  </tr>
                </tbody>
              )}
            </table>
          </Notification>
        </CSSTransition>
      ))}
    </TransitionGroup>
  </Wrapper>
);
