// @flow

import React from 'react';
import { createFragmentContainer, graphql } from 'react-relay';
import KeyValueGrid from 'shared/components/common/KeyValueGrid';
import UserWidget from 'shared/components/common/QueryWidget/UserWidget';
import { i18n, localizeEnum } from 'shared/utils';
import {
  renderCosmeticFieldValue,
  renderAdditionalFieldInfo,
} from 'shared/components/form/FormBuilder';
import Pill from 'shared/components/common/Pill';
import type { JobStateGrid_jobState as JobStateFragment } from './__generated__/JobStateGrid_jobState';

type Props = {
  jobState: JobStateFragment,
  verticalMode?: boolean,
};

const JobStateGrid = ({ jobState, verticalMode }: Props) => {
  const fields = [];

  if (jobState.workflowState.type === 'TASK') {
    fields.push({
      label: verticalMode ? '' : 'Status',
      value: (
        <Pill
          theme={jobState.status === 'COMPLETE' ? 'green' : 'default'}
          width="auto"
        >
          {localizeEnum(jobState.status)}
        </Pill>
      ),
    });
  }

  if (jobState.workflowState.isAssignable) {
    fields.push({
      label: i18n.t('Assigned To'),
      value: jobState.user ? <UserWidget user={jobState.user} /> : '',
    });
  }

  if (
    jobState.workflowState.form &&
    jobState.workflowState.form.fields.length &&
    jobState.workflowState.form
  ) {
    fields.push(
      ...jobState.workflowState.form.fields
        .map(field => {
          const cosmeticFieldValue = renderCosmeticFieldValue({
            formFieldType: field.type,
            value: jobState.formFieldValues[field.id] || '',
          });

          const additionalInfoPanel = renderAdditionalFieldInfo({
            formFieldType: field.type,
            value: jobState.formFieldValues[field.id] || '',
          });

          const renderFields = [
            {
              label: field.name,
              value: cosmeticFieldValue,
            },
          ];

          if (additionalInfoPanel) {
            renderFields.push({
              label: i18n.t('{{fieldName}} Details', {
                fieldName: field.name,
              }),
              value: additionalInfoPanel,
            });
          }

          return renderFields;
        })
        .reduce((a, b) => a.concat(b)),
    );
  }

  return (
    <KeyValueGrid fields={fields} singleColumn verticalMode={verticalMode} />
  );
};

JobStateGrid.defaultProps = {
  verticalMode: false,
};

export default createFragmentContainer(JobStateGrid, {
  jobState: graphql`
    fragment JobStateGrid_jobState on JobState {
      id
      formFieldValues
      isEnabled
      status
      user {
        id
        ...UserWidget_user
      }
      workflowState {
        id
        name
        isAssignable
        type
        form {
          id
          fields {
            id
            name
            type
            ...CosmeticFieldValue_formField
            ...AdditionalFieldInfo_formField
          }
        }
      }
    }
  `,
});
