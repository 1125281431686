// @flow

import React from 'react';
import { Link, withRouter, type RouterHistory } from 'react-router-dom';
import styled from 'styled-components';
import { fonts, colors } from 'shared/styleguide';
import itemIcon from './img/itemIcon.svg';
import jobIcon from './img/jobIcon.svg';
import logIcon from './img/logIcon.svg';
import toolIcon from './img/toolIcon.svg';

type Tab = {
  label: string,
  path: string,
  icon: string,
};

type Props = {
  history: RouterHistory,
  tabs: Array<Tab>,
  activePath: ?string,
};

const Wrapper = styled.div`
  padding: 6px 0;
  margin: 0;
  border-radius: 10px;
  display: flex;
  flex-flow: row wrap;
  align-items: center;
  justify-content: flex-start;
`;

const Pill = styled.div`
  border-radius: 100px;
  border: solid 1px ${colors.battleshipGrey};
  background-color: ${colors.white};
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  padding: 10px 10px;
  margin-bottom: 16px;
  margin-right: 16px;
  background-color: ${p => (p.selected ? colors.charcoalGrey : 'transparent')};
  &:visited {
    color: ${p => (p.selected ? colors.white : colors.battleshipGrey)};
  }

  a {
    ${fonts.bodyBold};
    text-align: center;
    font-size: 12px;
    color: ${p => (p.selected ? colors.white : colors.battleshipGrey)};
    padding-left: 6px;
  }
`;

const ItemIcon = styled(itemIcon)`
  width: 15px;
  height: 15px;
  g {
    fill: ${p => (p.selected ? colors.white : colors.battleshipGrey)};
  }
`;

const JobIcon = styled(jobIcon)`
  width: 15px;
  height: 15px;
  g {
    fill: ${p => (p.selected ? colors.white : colors.battleshipGrey)};
  }
`;

const ToolIcon = styled(toolIcon)`
  width: 15px;
  height: 15px;
  g {
    stroke: ${p => (p.selected ? colors.white : colors.battleshipGrey)};
  }
`;

const LogIcon = styled(logIcon)`
  width: 15px;
  height: 15px;
  g {
    stroke: ${p => (p.selected ? colors.white : colors.battleshipGrey)};
  }
`;

// TODO: come up with a better way to do this
const determineIcon = (tab: Object, activePath: ?string) => {
  switch (tab.icon) {
    case 'item':
      return <ItemIcon selected={tab.path === activePath} />;
    case 'log':
      return <LogIcon selected={tab.path === activePath} />;
    case 'tool':
      return <ToolIcon selected={tab.path === activePath} />;
    case 'job':
      return <JobIcon selected={tab.path === activePath} />;
    default:
      return <JobIcon selected={tab.path === activePath} />;
  }
};

const IconNav = ({ history, tabs, activePath }: Props) => (
  <Wrapper>
    {tabs.map(tab => (
      <Pill
        key={tab.label}
        onClick={e => history.push(tab.path)}
        selected={tab.path === activePath}
      >
        {determineIcon(tab, activePath)}
        <Link to={tab.path}>{tab.label}</Link>
      </Pill>
    ))}
  </Wrapper>
);

export default withRouter(IconNav);
