// @flow

import React from 'react';
import styled, { css } from 'styled-components';
import FieldLabel from './FieldLabel';

type Props = {
  label?: string,
  left?: ?React$Node,
  center?: ?React$Node,
  right?: ?React$Node,
};

const Wrapper = styled.div`
  position: relative;
  width: 100%;
  border: none;
  padding: 0;
  margin: 0;
`;

const Column = styled.div``;

const Row = styled.div`
  @media (min-width: 1000px) {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    align-items: flex-start;
  }

  ${Column} {
    ${p =>
      p.totalCols &&
      css`
        width: ${100 / p.totalCols}%;
      `}

    ${p =>
      p.totalCols > 1 &&
      css`
        @media (min-width: 1000px) {
          &:first-child {
            padding-right: 8px;
          }

          &:last-child {
            padding-left: 8px;
          }
        }
      `}
  }
`;

/**
 * Helper component that will render a form field label
 * and up to three form fields in a single row
 * @param {Object} props
 * @return {React$Node}
 */
const FieldGroupRow = ({ label, left, center, right }: Props) => {
  const totalCols =
    Number(Boolean(left)) + Number(Boolean(center)) + Number(Boolean(right));

  return (
    <Wrapper>
      {label ? <FieldLabel>{label}</FieldLabel> : null}
      <Row totalCols={totalCols}>
        {left ? <Column leftCol>{left}</Column> : null}
        {center ? <Column centerCol>{center}</Column> : null}
        {right ? <Column rightCol>{right}</Column> : null}
      </Row>
    </Wrapper>
  );
};

FieldGroupRow.defaultProps = {
  label: undefined,
  left: null,
  center: null,
  right: null,
};

export default FieldGroupRow;
