/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ReaderFragment } from 'relay-runtime';
import type { FragmentReference } from "relay-runtime";
declare export opaque type DowntimeStartModal_machine$ref: FragmentReference;
declare export opaque type DowntimeStartModal_machine$fragmentType: DowntimeStartModal_machine$ref;
export type DowntimeStartModal_machine = {|
  +id: string,
  +name: string,
  +type: {|
    +id: string,
    +name: string,
  |},
  +currentDowntime: ?{|
    +id: string,
    +category: {|
      +id: string,
      +name: string,
    |},
  |},
  +$refType: DowntimeStartModal_machine$ref,
|};
export type DowntimeStartModal_machine$data = DowntimeStartModal_machine;
export type DowntimeStartModal_machine$key = {
  +$data?: DowntimeStartModal_machine$data,
  +$fragmentRefs: DowntimeStartModal_machine$ref,
};
*/


const node/*: ReaderFragment*/ = (function(){
var v0 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "id",
  "args": null,
  "storageKey": null
},
v1 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "name",
  "args": null,
  "storageKey": null
},
v2 = [
  (v0/*: any*/),
  (v1/*: any*/)
];
return {
  "kind": "Fragment",
  "name": "DowntimeStartModal_machine",
  "type": "Machine",
  "metadata": null,
  "argumentDefinitions": [],
  "selections": [
    (v0/*: any*/),
    (v1/*: any*/),
    {
      "kind": "LinkedField",
      "alias": null,
      "name": "type",
      "storageKey": null,
      "args": null,
      "concreteType": "MachineType",
      "plural": false,
      "selections": (v2/*: any*/)
    },
    {
      "kind": "LinkedField",
      "alias": null,
      "name": "currentDowntime",
      "storageKey": null,
      "args": null,
      "concreteType": "MachineDowntime",
      "plural": false,
      "selections": [
        (v0/*: any*/),
        {
          "kind": "LinkedField",
          "alias": null,
          "name": "category",
          "storageKey": null,
          "args": null,
          "concreteType": "DowntimeCategory",
          "plural": false,
          "selections": (v2/*: any*/)
        }
      ]
    }
  ]
};
})();
// prettier-ignore
(node/*: any*/).hash = '8eb12fff6edca8a14f0d036a640cc8a4';
module.exports = node;
