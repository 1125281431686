// @flow

import React, { PureComponent } from 'react';
import { i18n } from 'shared/utils';
import { fetchQuery, graphql } from 'relay-runtime';
import relayEnvironment from 'shared/gql/relayEnvironment';
import { FieldGroup } from 'shared/components/form';

type Props = {
  lotNumber?: String,
  rawField?: boolean,
  label?: ?string,
  error?: ?string,
  queryVariables?: ?Object,
  excludeIds?: Array<string>,
  creatingNewRecord?: boolean,
  itemId?: string,
  movement?: string,
};

type State = {
  fetchedOptions: Array<Object>,
};

const QUERY = graphql`
  query SelectLotFieldQuery($sortBy: LotSort, $search: String, $itemId: ID) {
    lots(first: 50, sortBy: $sortBy, search: $search, itemId: $itemId) {
      edges {
        node {
          id
          lotNumber
          items {
            edges {
              node {
                qtyByLot
              }
            }
          }
        }
      }
    }
  }
`;

class SelectLotField extends PureComponent<Props, State> {
  static defaultProps = {
    lotNumber: undefined,
    rawField: false,
    label: undefined,
    error: undefined,
    excludeIds: [],
    queryVariables: {},
    creatingNewRecord: false,
    itemId: undefined,
    movement: undefined,
  };

  mounted = false;

  state = {
    fetchedOptions: [],
  };

  componentDidMount() {
    this.mounted = true;
  }

  componentWillUnmount() {
    this.mounted = false;
  }

  getData = async (inputValue: string) => {
    const { queryVariables, excludeIds, movement } = this.props;

    try {
      const { itemId } = this.props;
      const results = await fetchQuery(relayEnvironment, QUERY, {
        search: inputValue,
        itemId: movement !== 'ADD' ? itemId : null,
        ...queryVariables,
      });

      const options = this.massageData(results.lots.edges || [], excludeIds);

      if (this.mounted) {
        this.setState({
          fetchedOptions: options,
        });
      }

      return options;
    } catch (e) {
      return [];
    }
  };

  /**
   * Massage records to be ReactSelect-friendly
   * @param {Array<Object>} records
   * @param {Array<string | number>} excludeIds
   * @return {Array<Object>}
   */
  massageData = (
    records: Array<Object>,
    excludeIds?: Array<string> = [],
  ): Array<Object> => {
    return (
      records
        // filter out specified ids and NO LOT
        .filter(a => excludeIds.indexOf(a.node.id.toString()) < 0)
        .filter(a => a.node.lotNumber !== 'Default')
        .map(({ node }) => ({
          data: node,
          value: node.id,
          label: node.lotNumber,
        }))
    );
  };

  render() {
    const {
      lotNumber,
      rawField,
      label,
      error,
      creatingNewRecord,
      ...fieldProps
    } = this.props;
    const { fetchedOptions } = this.state;

    return (
      <FieldGroup
        name={lotNumber}
        rawField={rawField || false}
        label={label !== undefined ? label : i18n.t('Lot')}
        error={error}
        message={creatingNewRecord ? i18n.t(`A new lot will be created`) : null}
        clearable
        placeholder={i18n.t(`Search for Lot`)}
        {...fieldProps}
        options={fetchedOptions}
        type="select"
        loadOptions={this.getData}
        defaultOptions
      />
    );
  }
}

export default SelectLotField;
