// @flow

import React, { PureComponent } from 'react';
import moment from 'moment';

type Props = {
  startTime: moment$Moment,
  endTime?: moment$Moment,
  intervalSeconds?: number,
};

type State = {
  time: string,
};

function pad(num, size) {
  let s = num + '';
  while (s.length < size) s = '0' + s;
  return s;
}

class TimeElapsedTicker extends PureComponent<Props, State> {
  static defaultProps = {
    endTime: undefined,
    intervalSeconds: undefined,
  };

  ticker = null;

  constructor(props: Props) {
    super(props);

    this.state = {
      time: this.getTime(),
    };
  }

  componentDidMount() {
    const { endTime } = this.props;

    if (endTime) {
      return;
    }

    this.init();
  }

  componentDidUpdate(prevProps: Props) {
    const { endTime } = this.props;

    if (!prevProps.endTime && endTime) {
      clearInterval(this.ticker);
    } else if (prevProps.endTime && !endTime) {
      this.init();
    }
  }

  componentWillUnmount() {
    if (this.ticker) {
      clearInterval(this.ticker);
    }
  }

  getTime() {
    const { startTime, endTime } = this.props;
    const end = endTime ? endTime : moment();
    const elapsedSeconds = end.diff(startTime, 'seconds');
    const hours = pad(Math.floor(elapsedSeconds / 3600), 2);
    const elapsedMinutes = elapsedSeconds % 3600;
    const minutes = pad(Math.floor(elapsedMinutes / 60), 2);
    const seconds = pad(elapsedMinutes % 60, 2);

    return `${hours}:${minutes}:${seconds}`;
  }

  init() {
    const { intervalSeconds } = this.props;
    const ms = intervalSeconds ? intervalSeconds * 1000 : 1000;

    clearInterval(this.ticker);
    this.ticker = setInterval(() => this.tick(), ms);
  }

  tick() {
    this.setState({
      time: this.getTime(),
    });
  }

  render() {
    const { time } = this.state;

    return <React.Fragment>{time}</React.Fragment>;
  }
}

export default TimeElapsedTicker;
