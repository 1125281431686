// @flow

class LocalStorage {
  /**
   * Retrieve an item from local storage, with an optional fallback value.
   * Retrieved items are JSON parsed and returned synchronously
   * @param {string} name
   * @param {any} fallbackValue
   * @return {any}
   */
  static get(name: string, fallbackValue: any = null) {
    try {
      const localStorageVal = window.localStorage.getItem(name);

      if (!localStorageVal) {
        return fallbackValue;
      }

      return JSON.parse(localStorageVal) || fallbackValue;
    } catch (e) {
      return fallbackValue;
    }
  }

  /**
   * Set an item in local storage
   * @param {string} name
   * @param {any} value
   */
  static set(name: string, value: any) {
    const stringifiedValue = JSON.stringify(value);

    window.localStorage.setItem(name, stringifiedValue);
  }

  /**
   * Clear all local storage
   */
  static clear() {
    window.localStorage.clear();
  }
}

export default LocalStorage;
