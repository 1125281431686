// @flow

import React, { PureComponent } from 'react';
import { i18n } from 'shared/utils';
import { fetchQuery, graphql } from 'relay-runtime';
import relayEnvironment from 'shared/gql/relayEnvironment';
import { FieldGroup } from 'shared/components/form';

type Props = {
  name?: string,
  rawField?: boolean,
  label?: ?string,
  error?: ?string,
  queryVariables?: ?Object,
  excludeIds?: Array<string>,
  creatingNewRecord?: boolean,
};

type State = {
  fetchedOptions: Array<Object>,
};

const QUERY = graphql`
  query SelectItemFieldQuery(
    $sortBy: ItemSort
    $search: String
    $orderIds: [ID!]
  ) {
    items(first: 50, sortBy: $sortBy, search: $search, orderIds: $orderIds) {
      edges {
        node {
          id
          name
          quantityUOM {
            id
            name
            symbol
          }
        }
      }
    }
  }
`;

class SelectItemField extends PureComponent<Props, State> {
  static defaultProps = {
    name: undefined,
    rawField: false,
    label: undefined,
    error: undefined,
    excludeIds: [],
    queryVariables: {},
    creatingNewRecord: false,
  };

  mounted = false;

  state = {
    fetchedOptions: [],
  };

  componentDidMount() {
    this.mounted = true;
  }

  componentWillUnmount() {
    this.mounted = false;
  }

  getData = async (inputValue: string) => {
    const { queryVariables, excludeIds } = this.props;

    try {
      const results = await fetchQuery(relayEnvironment, QUERY, {
        search: inputValue,
        ...queryVariables,
      });

      const options = this.massageData(results.items.edges || [], excludeIds);

      if (this.mounted) {
        this.setState({
          fetchedOptions: options,
        });
      }

      return options;
    } catch (e) {
      return [];
    }
  };

  /**
   * Massage records to be ReactSelect-friendly
   * @param {Array<Object>} records
   * @param {Array<string | number>} excludeIds
   * @return {Array<Object>}
   */
  massageData = (
    records: Array<Object>,
    excludeIds?: Array<string> = [],
  ): Array<Object> => {
    return (
      records
        // filter out specified ids
        .filter(a => excludeIds.indexOf(a.node.id.toString()) < 0)
        .map(({ node }) => ({
          data: node,
          value: node.id,
          label: node.name,
        }))
    );
  };

  render() {
    const {
      name,
      rawField,
      label,
      error,
      creatingNewRecord,
      ...fieldProps
    } = this.props;
    const { fetchedOptions } = this.state;

    return (
      <FieldGroup
        name={name}
        rawField={rawField || false}
        label={label !== undefined ? label : i18n.t('Item')}
        error={error}
        message={
          creatingNewRecord ? i18n.t(`A new item will be created`) : null
        }
        clearable
        placeholder={i18n.t(`Search for Item`)}
        {...fieldProps}
        options={fetchedOptions}
        type="select"
        loadOptions={this.getData}
        defaultOptions
      />
    );
  }
}

export default SelectItemField;
