// @flow

import React, { PureComponent, createRef } from 'react';
import styled from 'styled-components';
import { colors, fonts, formStyles } from 'shared/styleguide';
import type { FormikProps, FieldProps } from 'formik';
import type { FieldSize } from './types';

type Props = {
  form?: FormikProps<string>,
  field?: $PropertyType<FieldProps, 'field'>,
  type?: 'text' | 'email' | 'number' | 'time',
  extendedLabel?: string,
  error?: string,
  size?: FieldSize,
  thin?: boolean,
  placeholder?: string,
  value?: ?string,
  defaultValue?: string,
  disabled?: boolean,
  onChange?: string => void,
  onKeyDown?: KeyboardEvent => void,
  onBlur?: Event => void,
  onFocus?: Event => void,
  style?: Object,
  min?: number,
  max?: number,
};

const Wrapper = styled.div`
  position: relative;
  ${formStyles.fieldWidth};

  input {
    padding: 0 10px;
    ${formStyles.field};
  }

  ${p => {
    if (p.extendedLabel) {
      return `
        display: flex;
        flex-flow: row nowrap;
        align-items: center;

        input {
          flex: 1;
          border-top-right-radius: 0;
          border-bottom-right-radius: 0;
        }
      `;
    }

    return ``;
  }};
`;

const ExtendedLabel = styled.div`
  height: ${p =>
    p.thin ? formStyles.INPUT_HEIGHT_THIN : formStyles.INPUT_HEIGHT}px;
  line-height: ${p =>
    p.thin ? formStyles.INPUT_HEIGHT_THIN : formStyles.INPUT_HEIGHT}px;
  background-color: ${colors.paleGrey};
  text-align: center;
  ${formStyles.fieldBorder}
  ${p => (p.error ? formStyles.fieldErrorBorder : '')};
  border-left: none;
  border-top-right-radius: ${formStyles.INPUT_BORDER_RADIUS}px;
  border-bottom-right-radius: ${formStyles.INPUT_BORDER_RADIUS}px;
  ${fonts.bodyRegular}
  color: ${colors.charcoalGrey};
  min-width: 79px;
  padding: 0 8px;
  opacity: ${p => (p.disabled ? 0.4 : 1)}
`;

class Input extends PureComponent<Props> {
  static defaultProps = {
    form: undefined,
    field: undefined,
    type: 'text',
    extendedLabel: undefined,
    error: undefined,
    size: 'large',
    thin: false,
    placeholder: undefined,
    value: undefined,
    defaultValue: undefined,
    disabled: false,
    style: undefined,
    onChange: () => {},
    onKeyDown: () => {},
    onBlur: () => {},
    onFocus: () => {},
    min: undefined,
    max: undefined,
  };

  input: any = createRef();

  focus = () => {
    if (this.input?.current) {
      this.input.current.focus();
    }
  };

  handleBlur = (e: Event) => {
    const { form, field, onBlur } = this.props;

    if (form && field) {
      form.setFieldTouched(field.name, true);
    }

    if (onBlur) {
      onBlur(e);
    }
  };

  handleChange = (e: SyntheticEvent<HTMLInputElement>) => {
    const { form, field, onChange } = this.props;

    if (form && field) {
      form.setFieldValue(field.name, e.currentTarget.value);
    }

    if (onChange) {
      onChange(e.currentTarget.value);
    }
  };

  handleKeyDown = (e: KeyboardEvent, ...args: *) => {
    const { form, field, onKeyDown } = this.props;

    if (e.key === 'Enter' && form && field && form.handleSubmit) {
      form.handleSubmit(e, ...args);
    }

    if (onKeyDown) {
      onKeyDown(e, ...args);
    }
  };

  render() {
    const {
      field,
      type,
      extendedLabel,
      error,
      size,
      thin,
      placeholder,
      value,
      defaultValue,
      disabled,
      style,
      min,
      max,
      onFocus,
    } = this.props;

    return (
      <Wrapper
        error={error}
        size={size}
        thin={thin}
        extendedLabel={extendedLabel}
        style={style}
      >
        <input
          ref={this.input}
          defaultValue={defaultValue}
          disabled={disabled}
          onChange={this.handleChange}
          onKeyDown={this.handleKeyDown}
          onBlur={this.handleBlur}
          onFocus={onFocus}
          type={type}
          placeholder={placeholder}
          value={value !== undefined ? value : field?.value}
          min={min}
          max={max}
        />
        {extendedLabel && (
          <ExtendedLabel thin={thin} error={error} disabled={disabled}>
            {extendedLabel}
          </ExtendedLabel>
        )}
      </Wrapper>
    );
  }
}

export default Input;
