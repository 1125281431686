// @flow

import React, { PureComponent } from 'react';
import moment from 'moment';
import {
  Route,
  Switch,
  Redirect,
  type Match,
  type RouterHistory,
} from 'react-router-dom';
import styled from 'styled-components';
import { QueryRenderer, graphql } from 'react-relay';
import relayEnvironment from 'shared/gql/relayEnvironment';
import { i18n } from 'shared/utils';
import { colors } from 'shared/styleguide';
import Loader from 'shared/components/common/Loader';
import Page from 'operator-portal/components/Page';
import IconNav from 'operator-portal/components/IconNav';
import SetupStartModal from 'shared/components/common/SetupStartModal';
import SetupPauseModal from 'shared/components/common/SetupPauseModal';
import SetupEndModal from 'shared/components/common/SetupEndModal';
import RunStartModal from 'shared/components/common/RunStartModal';
import RunPauseModal from 'shared/components/common/RunPauseModal';
import RunEndModal from 'shared/components/common/RunEndModal';
import RunControls from 'shared/components/common/RunControls';
import RunHeader from './RunHeader';
import RunDetails from './RunDetails';
import RunYieldsInfo from './RunYieldsInfo';
import RunLog from './RunLog';
import RunTooling from './RunTooling';

type Props = {
  history: RouterHistory,
  match: {
    ...Match,
    params: {
      id: string,
    },
  },
};

type State = {
  setupStartModalOpen: boolean,
  setupPauseModalOpen: boolean,
  setupEndModalOpen: boolean,
  runStartModalOpen: boolean,
  runPauseModalOpen: boolean,
  runEndModalOpen: boolean,
};

const Wrapper = styled.div`
  position: relative;
  flex: 1;

  @media (min-width: 800px) {
    display: flex;
    flex-flow: row nowrap;
    align-items: flex-start;
  }
`;

const LeftColumn = styled.div`
  border-bottom: 2px solid ${colors.lightBlueGrey};
  padding-bottom: 24px;

  @media (min-width: 800px) {
    height: 100%;
    width: 360px;
    border-bottom: none;
    border-right: 2px solid ${colors.lightBlueGrey};
    padding-right: 24px;
  }
`;

const RightColumn = styled.div`
  padding-top: 24px;

  @media (min-width: 800px) {
    height: 100%;
    flex: 1;
    padding-top: 0;
    padding-left: 24px;
    display: flex;
    flex-flow: column;
  }
`;

const TabContentWrapper = styled.div`
  overflow-y: auto;
  flex: 1;
`;

class Run extends PureComponent<Props, State> {
  state = {
    setupStartModalOpen: false,
    setupPauseModalOpen: false,
    setupEndModalOpen: false,
    runStartModalOpen: false,
    runPauseModalOpen: false,
    runEndModalOpen: false,
  };

  handleOpenSetupStartModal = () => {
    this.setState({ setupStartModalOpen: true });
  };

  handleOpenSetupPauseModal = () => {
    this.setState({ setupPauseModalOpen: true });
  };

  handleOpenSetupEndModal = () => {
    this.setState({ setupEndModalOpen: true });
  };

  handleOpenRunStartModal = () => {
    this.setState({ runStartModalOpen: true });
  };

  handleOpenRunPauseModal = () => {
    this.setState({ runPauseModalOpen: true });
  };

  handleOpenRunEndModal = () => {
    this.setState({ runEndModalOpen: true });
  };

  handleCloseAllModals = () => {
    this.setState({
      setupStartModalOpen: false,
      setupPauseModalOpen: false,
      setupEndModalOpen: false,
      runStartModalOpen: false,
      runPauseModalOpen: false,
      runEndModalOpen: false,
    });
  };

  handleGoBack = (run: Object) => {
    const { history } = this.props;
    const date = moment(run.scheduledStartAt).format('YYYY-MM-DD');

    history.push(`/runs?date=${date}`);
  };

  render() {
    const { match } = this.props;
    const {
      setupStartModalOpen,
      setupPauseModalOpen,
      setupEndModalOpen,
      runStartModalOpen,
      runPauseModalOpen,
      runEndModalOpen,
    } = this.state;

    return (
      <Page hideToolbar>
        <QueryRenderer
          environment={relayEnvironment}
          variables={{
            id: match.params.id,
          }}
          query={graphql`
            query RunQuery($id: ID!) {
              run(id: $id) {
                id
                status
                scheduledStartAt
                job {
                  id
                  linkedItems {
                    totalCount
                  }
                  yields {
                    totalCount
                  }
                  ...RunTooling_job
                }
                ...RunControls_run
                ...RunDetails_run
                ...RunYieldsInfo_run
                ...RunLog_run
                ...SetupStartModal_run
                ...SetupPauseModal_run
                ...SetupEndModal_run
                ...RunStartModal_run
                ...RunPauseModal_run
                ...RunEndModal_run
              }
            }
          `}
          render={query => {
            if (!query.props) {
              return <Loader />;
            }

            return (
              <>
                <RunHeader
                  onGoBack={() => this.handleGoBack(query.props.run)}
                  status={query.props.run.status}
                />
                <Wrapper>
                  <LeftColumn>
                    <RunControls
                      run={query.props.run}
                      onSetupStart={this.handleOpenSetupStartModal}
                      onSetupPause={this.handleOpenSetupPauseModal}
                      onSetupEnd={this.handleOpenSetupEndModal}
                      onRunStart={this.handleOpenRunStartModal}
                      onRunPause={this.handleOpenRunPauseModal}
                      onRunEnd={this.handleOpenRunEndModal}
                    />
                  </LeftColumn>
                  <RightColumn>
                    <IconNav
                      history={history}
                      activePath={match.url}
                      tabs={[
                        {
                          label: i18n.t('Details'),
                          path: `/run/${match.params.id}`,
                          icon: 'job',
                        },
                        {
                          label: i18n.t('{{ totalCount }} Items', {
                            totalCount:
                              query.props.run.job.yields?.totalCount || '0',
                          }),
                          path: `/run/${match.params.id}/items`,
                          icon: 'item',
                        },
                        {
                          label: i18n.t('Run Log'),
                          path: `/run/${match.params.id}/log`,
                          icon: 'log',
                        },
                        {
                          label: i18n.t('{{ totalCount }} Tooling', {
                            totalCount:
                              query.props.run.job.linkedItems?.totalCount ||
                              '0',
                          }),
                          path: `/run/${match.params.id}/tooling`,
                          icon: 'tool',
                        },
                      ]}
                    />
                    <TabContentWrapper>
                      <Switch>
                        <Route
                          exact
                          path="/run/:id"
                          render={props => (
                            <RunDetails {...props} run={query.props.run} />
                          )}
                        />
                        <Route
                          exact
                          path="/run/:id/items"
                          render={props => (
                            <RunYieldsInfo {...props} run={query.props.run} />
                          )}
                        />
                        <Route
                          exact
                          path="/run/:id/log"
                          render={props => (
                            <RunLog {...props} run={query.props.run} />
                          )}
                        />
                        <Route
                          exact
                          path="/run/:id/tooling"
                          render={props => (
                            <RunTooling {...props} job={query.props.run.job} />
                          )}
                        />
                        <Route
                          exact
                          render={() => (
                            <Redirect to={`/run/${match.params.id}`} />
                          )}
                        />
                      </Switch>
                    </TabContentWrapper>
                  </RightColumn>
                </Wrapper>
                {setupStartModalOpen && (
                  <SetupStartModal
                    onClose={this.handleCloseAllModals}
                    run={query.props.run}
                  />
                )}
                {setupPauseModalOpen && (
                  <SetupPauseModal
                    onClose={this.handleCloseAllModals}
                    run={query.props.run}
                  />
                )}
                {setupEndModalOpen && (
                  <SetupEndModal
                    onClose={this.handleCloseAllModals}
                    run={query.props.run}
                  />
                )}
                {runStartModalOpen && (
                  <RunStartModal
                    onClose={this.handleCloseAllModals}
                    run={query.props.run}
                  />
                )}
                {runPauseModalOpen && (
                  <RunPauseModal
                    onClose={this.handleCloseAllModals}
                    run={query.props.run}
                  />
                )}
                {runEndModalOpen && (
                  <RunEndModal
                    onClose={this.handleCloseAllModals}
                    run={query.props.run}
                  />
                )}
              </>
            );
          }}
        />
      </Page>
    );
  }
}

export default Run;
