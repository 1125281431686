// @flow

import React from 'react';
import styled, { keyframes } from 'styled-components';
// import ring from './img/ring.svg';

const rotateAnimation = keyframes`
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
`;

const Wrapper = styled.div`
  width: 100%;
  height: 100%;
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;
`;

const Ring = styled.div`
  position: absolute;
  border: 4px solid rgba(0, 0, 0, 0.1);
  border-left-color: #5778f3;
  border-radius: 50%;
  width: 120px;
  height: 120px;
  animation: ${rotateAnimation} 1.2s linear infinite;
`;

const Logo = styled.img.attrs({
  src: require('./img/logo.png'),
})`
  margin: 0 auto;
  width: 60px;
  height: 60px;
`;

const Loader = () => (
  <Wrapper>
    <Ring />
    <Logo />
  </Wrapper>
);

export default Loader;
