// @flow

import React from 'react';
import { createFragmentContainer, graphql } from 'react-relay';
import { i18n } from 'shared/utils';
import Panel from 'shared/components/common/Panel';
import KeyValueGrid from 'shared/components/common/KeyValueGrid';
import Divider from 'shared/components/common/Divider';
import RunLogs from 'shared/components/common/RunLogs';
import type { RunLog_run as RunFragment } from './__generated__/RunLog_run';

type Props = {
  run: RunFragment,
};

const RunLog = ({ run }: Props) => (
  <>
    <div style={{ paddingBottom: 16 }}>
      <Panel title={i18n.t('Summary')}>
        {(run.yields.edges || []).filter(Boolean).map(yieldEdge => (
          <React.Fragment key={yieldEdge.node.id}>
            <KeyValueGrid
              singleColumn
              fields={[
                {
                  label: i18n.t('Item'),
                  value: yieldEdge.node.item.name,
                },
                {
                  label: i18n.t('Total Setup Quantity'),
                  value: i18n.t('{{quantity, number}}', {
                    quantity: yieldEdge.setupQuantity || '',
                  }),
                },
                {
                  label: i18n.t('Total Quantity Produced'),
                  value: i18n.t('{{quantity, number}}', {
                    quantity: yieldEdge.runQuantity || '',
                  }),
                },
                {
                  label: i18n.t('Defective Quantity'),
                  value: i18n.t('{{quantity, number}}', {
                    quantity: yieldEdge.defectQuantity || '',
                  }),
                },
              ]}
            />
            <Divider style={{ marginTop: 16, marginBottom: 16 }} />
          </React.Fragment>
        ))}
      </Panel>
    </div>
    <Panel title={i18n.t('Activity Log')}>
      <RunLogs run={run} />
    </Panel>
  </>
);

export default createFragmentContainer(RunLog, {
  run: graphql`
    fragment RunLog_run on Run {
      id
      machine {
        id
      }
      yields(first: null) {
        edges {
          setupQuantity
          runQuantity
          defectQuantity
          node {
            id
            item {
              id
              name
              quantityUOM {
                id
                symbol
              }
            }
          }
        }
      }
      ...RunLogs_run
    }
  `,
});
