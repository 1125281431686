// @flow

import { graphql } from 'react-relay';

// Usage: add this to job query and pass it to WorkflowUtils
// ...WorkflowUtils_job @relay(mask: false)
export default class WorkflowUtils {
  /**
   * Returns a bidirectional graph of Job States as nodes that can be traversed via children/parents
   * Ancestors/Parents are to the left, Descendants/Children are to the right
   * Graphs are read from the left starting node JOB_CREATED to the right ending node JOB_COMPLETED
   * @param {Object} workflow
   * @param {Object[]} jobStates
   * @return {Object}
   */
  static createGraph = (workflow: any, jobStates: any) => {
    const { states, transitions } = workflow;
    const graph = {
      nodes: {},
      startingNodeId: null,
      endingNodeId: null,
    };

    for (const state of states) {
      const jobState = jobStates.find(
        jobState => jobState.workflowState.id === state.id,
      ) || {
        // If no Job State is found, the Job was created before Workflows
        // Substitute the non-existent Job State with Workflow State so that we can still sort it
        id: state.id,
      };

      graph.nodes[jobState.id || state.id] = {
        id: jobState.id || state.id,
        parentIds: new Set(),
        childIds: new Set(),
        status: jobState.status || 'COMPLETE',
        isEnabled:
          state.type === 'JOB_CREATED' || state.type === 'JOB_COMPLETED'
            ? true
            : jobState.isEnabled,
        isRequired:
          state.type === 'JOB_CREATED' || state.type === 'JOB_COMPLETED'
            ? true
            : state.isRequired,
        type: state.type,
        name: state.name || jobState.workflowState?.name,
      };

      if (state.type === 'JOB_CREATED') {
        graph.startingNodeId = jobState.id;
      } else if (state.type === 'JOB_COMPLETED') {
        graph.endingNodeId = jobState.id;
      }
    }

    // If Job existed before Workflows, then use Workflow State's ID instead of non-existent Job State's ID
    transitions.forEach(transition => {
      const toJobState = jobStates.find(
        jobState => jobState.workflowState.id === transition.toWorkflowState.id,
      );
      const toStateId = toJobState?.id || transition.toWorkflowState.id;
      const fromJobState = jobStates.find(
        jobState =>
          jobState.workflowState.id === transition.fromWorkflowState.id,
      );
      const fromStateId = fromJobState?.id || transition.fromWorkflowState.id;

      graph.nodes[toStateId].parentIds.add(fromStateId);
      graph.nodes[fromStateId].childIds.add(toStateId);
    });

    return graph;
  };

  static getSkippedStatesOfJobState = (jobState: any, job: any) => {
    const graph = WorkflowUtils.createGraph(job.workflow, job.states);
    const ancestors = WorkflowUtils.getJobStateAncestors(jobState, job, graph);
    const skippedStates: any = ancestors.filter(
      ancestor =>
        ancestor.isEnabled &&
        ancestor.isRequired &&
        ancestor.status !== 'COMPLETE',
    );

    return skippedStates;
  };

  static getStartedDescendantStates = (jobState: any, job: any) => {
    const graph = WorkflowUtils.createGraph(job.workflow, job.states);
    const descendants = WorkflowUtils.getJobStateDescendants(
      jobState,
      job,
      graph,
    );
    const startedDescendantStates: any = descendants.filter(
      descendant => descendant.status !== 'INCOMPLETE',
    );

    return startedDescendantStates;
  };

  static getJobStateDescendants = (
    jobState: any,
    job: any,
    graph: any,
    visited: Set<string> = new Set(),
  ) => {
    const node = WorkflowUtils.getNode(jobState, graph);
    const childIds = [...node.childIds];
    const unvisitedChildren = childIds
      .filter(childId => !visited.has(childId))
      .map(childId => graph.nodes[childId]);

    if (unvisitedChildren.length !== 0) {
      unvisitedChildren.forEach(child => {
        visited.add(child.id);
      });

      const result = [
        ...unvisitedChildren,
        ...unvisitedChildren
          .map(child =>
            WorkflowUtils.getJobStateDescendants(child, job, graph, visited),
          )
          .reduce((accumulator = [], current) => accumulator.concat(current)),
      ];

      return result;
    }

    return [];
  };

  static getNode = (jobState: any, graph: any) => {
    const nodeId = Object.keys(graph.nodes).find(
      nodeId => nodeId === jobState.id,
    );
    return graph.nodes[nodeId];
  };

  static getJobStateAncestors = (
    jobState: any,
    job: any,
    graph: any,
    visited: Set<string> = new Set(),
  ) => {
    const node = WorkflowUtils.getNode(jobState, graph);
    const parentIds = [...node.parentIds];
    const unvisitedParents = parentIds
      .filter(parentId => !visited.has(parentId))
      .map(parentId => graph.nodes[parentId]);

    if (unvisitedParents.length !== 0) {
      unvisitedParents.forEach(parent => {
        visited.add(parent.id);
      });

      return [
        ...unvisitedParents,
        ...unvisitedParents
          .map(parent =>
            WorkflowUtils.getJobStateAncestors(parent, job, graph, visited),
          )
          .reduce((accumulator = [], current) => accumulator.concat(current)),
      ];
    }

    return [];
  };

  static getStartingNode = (graph: any) => {
    const startingNodeId = graph.startingNodeId;
    const startingNode = graph.nodes[startingNodeId];

    return startingNode;
  };

  static getParents = (node: any, graph: any) => {
    const parentIds = [...node.parentIds];
    const parents = parentIds.map(parentId => graph.nodes[parentId]);

    return parents;
  };

  static getChildren = (node: any, graph: any) => {
    const childIds = [...node.childIds];
    const children = childIds.map(childId => graph.nodes[childId]);

    return children;
  };

  /**
   * Sorts a graph by assigning an order value via BFS and alphabetical order amongst similar-ordered nodes
   * @param {Object} graph
   */
  static sortGraph = (graph: any) => {
    const startingNode = WorkflowUtils.getStartingNode(graph);
    const queue = [startingNode];
    const visited = new Set();
    let order = 0;

    while (queue.length) {
      const currentNode = queue.shift();
      const currentParentIds = [...currentNode.parentIds];

      if (currentParentIds.some(parentId => !visited.has(parentId))) {
        queue.push(currentNode);
      } else if (!visited.has(currentNode.id)) {
        const unvisitedSortedChildren = WorkflowUtils.getChildren(
          currentNode,
          graph,
        ).sort((childA, childB) => childA.name.localeCompare(childB.name));

        visited.add(currentNode.id);
        currentNode.order = order;
        order++;
        queue.push(...unvisitedSortedChildren);
      }
    }
  };

  static getSortedJobStates = (job: any) => {
    const graph = WorkflowUtils.createGraph(job.workflow, job.states);
    WorkflowUtils.sortGraph(graph);
    const sortedGraph = {};
    const nodeIds: string[] = Object.keys(graph.nodes).sort(
      (nodeIdA, nodeIdB) =>
        graph.nodes[nodeIdA].order - graph.nodes[nodeIdB].order,
    );

    for (const nodeId of nodeIds) {
      sortedGraph[nodeId] = {
        order: graph.nodes[nodeId].order,
      };
    }

    return sortedGraph;
  };

  /**
   * Mutates a graph by removing disabled Job States and connecting it's parents to it's children
   * @param {Object} graph
   */
  static removeDisabledJobStates = (graph: any) => {
    const jobStates: any[] = Object.values(graph.nodes);
    const disabledJobStates = jobStates.filter(jobState => !jobState.isEnabled);

    for (const jobState of disabledJobStates) {
      const children = WorkflowUtils.getChildren(jobState, graph);
      const parents = WorkflowUtils.getParents(jobState, graph);

      for (const child of children) {
        child.parentIds.delete(jobState.id);
        child.parentIds = new Set([...child.parentIds, ...jobState.parentIds]);
      }

      for (const parent of parents) {
        parent.childIds.delete(jobState.id);
        parent.childIds = new Set([...parent.childIds, ...jobState.childIds]);
      }
    }
  };

  /**
   * Returns the next Job States of a Job
   * @param {Object} job
   * @return {Array<string>}
   */
  static getNextJobStates = (job: any) => {
    const graph = WorkflowUtils.createGraph(job.workflow, job.states);
    WorkflowUtils.sortGraph(graph);
    WorkflowUtils.removeDisabledJobStates(graph);

    const startingNode = WorkflowUtils.getStartingNode(graph);
    const visited = new Set([graph.endingNodeId]);
    const queue = [startingNode];
    const nextJobStates: any = [];

    while (queue.length) {
      const currentNode = queue.shift();

      if (!visited.has(currentNode.id)) {
        const children = WorkflowUtils.getChildren(currentNode, graph);
        const parents = WorkflowUtils.getParents(currentNode, graph);
        const someParentsComplete = parents.some(
          parent => parent.status === 'COMPLETE',
        );
        const allParentsVisited = parents.every(parent =>
          visited.has(parent.id),
        );

        if (allParentsVisited) {
          if (
            someParentsComplete &&
            currentNode.status !== 'COMPLETE' &&
            currentNode.type !== 'JOB_COMPLETED' &&
            currentNode.isEnabled
          ) {
            nextJobStates.push(currentNode);
          }

          visited.add(currentNode.id);
          queue.push(...children);
        } else {
          queue.push(currentNode);
        }
      }
    }

    return nextJobStates.map((state: any) => ({
      id: state.id,
      name: state.name,
      iconType: state.type === 'TASK' ? 'circle-task' : 'circle-run',
    }));
  };
}

graphql`
  fragment WorkflowUtils_job on Job {
    id
    workflow {
      id
      states {
        id
        name
        isRequired
        type
      }
      transitions {
        id
        toWorkflowState {
          id
        }
        fromWorkflowState {
          id
        }
      }
    }
    states {
      id
      isEnabled
      status
      workflowState {
        id
        name
        type
      }
    }
  }
`;
