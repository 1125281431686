// @flow

import React, { PureComponent } from 'react';
import { i18n } from 'shared/utils';
import { graphql, fetchQuery } from 'react-relay';
import relayEnvironment from 'shared/gql/relayEnvironment';
import { FieldGroup } from 'shared/components/form';

type Props = {
  name?: string,
  rawField?: boolean,
  label?: ?string,
  error?: ?string,
  queryVariables: Object,
  excludeIds?: Array<string>,
  isSearchable?: boolean,
};

type State = {
  fetchedOptions: Array<Object>,
};

const QUERY = graphql`
  query SelectJobStateFieldQuery($jobId: ID!, $machineTypeId: ID) {
    jobStates(jobId: $jobId, machineTypeId: $machineTypeId) {
      id
      workflowState {
        id
        name
      }
    }
  }
`;

class SelectJobStateField extends PureComponent<Props, State> {
  static defaultProps = {
    name: undefined,
    rawField: false,
    label: undefined,
    error: undefined,
    excludeIds: [],
    isSearchable: undefined,
  };

  mounted = false;

  state = {
    fetchedOptions: [],
  };

  componentDidMount() {
    this.mounted = true;

    this.getData();
  }

  componentWillUnmount() {
    this.mounted = false;
  }

  getData = async (inputValue?: string) => {
    const { queryVariables, excludeIds } = this.props;

    try {
      const results = await fetchQuery(relayEnvironment, QUERY, {
        search: inputValue,
        ...queryVariables,
      });

      const options = this.massageData(results.jobStates || [], excludeIds);

      if (this.mounted) {
        this.setState({
          fetchedOptions: options,
        });
      }

      return options;
    } catch (e) {
      return [];
    }
  };

  /**
   * Massage records to be ReactSelect-friendly
   * @param {Array<Object>} records
   * @param {Array<string | number>} excludeIds
   * @return {Array<Object>}
   */
  massageData = (
    records: Array<Object>,
    excludeIds?: Array<string> = [],
  ): Array<Object> => {
    return (
      records
        // filter out specified ids
        .filter(a => !a.node || excludeIds.indexOf(a.id.toString()) < 0)
        .map(a => ({
          data: a,
          value: a.id,
          label: a.workflowState.name,
        }))
    );
  };

  render() {
    const { name, rawField, label, error, ...fieldProps } = this.props;
    const { fetchedOptions } = this.state;

    return (
      <FieldGroup
        name={name}
        rawField={rawField || false}
        label={label !== undefined ? label : i18n.t('Job State')}
        error={error}
        clearable
        placeholder={i18n.t('Job State Name')}
        {...fieldProps}
        options={fetchedOptions}
        type="select"
        // loadOptions={this.getData}
        defaultOptions
      />
    );
  }
}

export default SelectJobStateField;
