/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ReaderFragment } from 'relay-runtime';
type FormBuilder_form$ref = any;
export type FormFieldType = "CHECKBOX" | "DATE" | "MULTILINE_TEXT" | "MULTISELECT" | "NUMBER" | "SELECT" | "SELECT_CUSTOMER" | "SELECT_MACHINE" | "SELECT_ORDER" | "SELECT_USER" | "TEXT";
export type OverUnderRule = "ALLOW_OVERS" | "NO_OVERS" | "NO_UNDERS";
import type { FragmentReference } from "relay-runtime";
declare export opaque type RunYieldsInfo_run$ref: FragmentReference;
declare export opaque type RunYieldsInfo_run$fragmentType: RunYieldsInfo_run$ref;
export type RunYieldsInfo_run = {|
  +id: string,
  +yields: {|
    +edges: ?$ReadOnlyArray<?{|
      +node: {|
        +id: string,
        +quantity: number,
        +overUnderRule: ?OverUnderRule,
        +overQuantity: ?number,
        +brokerCustomerOrderNumber: ?string,
        +itemNumberUp: number,
        +formFieldValues: any,
        +form: {|
          +id: string,
          +fields: $ReadOnlyArray<{|
            +id: string,
            +systemKey: ?string,
            +name: string,
            +type: FormFieldType,
          |}>,
          +$fragmentRefs: FormBuilder_form$ref,
        |},
        +item: {|
          +id: string,
          +itemNumber: string,
          +name: string,
          +partNumber: ?string,
          +description: ?string,
          +formFieldValues: any,
          +form: {|
            +id: string,
            +fields: $ReadOnlyArray<{|
              +id: string,
              +systemKey: ?string,
              +name: string,
              +type: FormFieldType,
            |}>,
            +$fragmentRefs: FormBuilder_form$ref,
          |},
        |},
      |}
    |}>
  |},
  +$refType: RunYieldsInfo_run$ref,
|};
export type RunYieldsInfo_run$data = RunYieldsInfo_run;
export type RunYieldsInfo_run$key = {
  +$data?: RunYieldsInfo_run$data,
  +$fragmentRefs: RunYieldsInfo_run$ref,
};
*/


const node/*: ReaderFragment*/ = (function(){
var v0 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "id",
  "args": null,
  "storageKey": null
},
v1 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "formFieldValues",
  "args": null,
  "storageKey": null
},
v2 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "name",
  "args": null,
  "storageKey": null
},
v3 = {
  "kind": "LinkedField",
  "alias": null,
  "name": "form",
  "storageKey": null,
  "args": null,
  "concreteType": "Form",
  "plural": false,
  "selections": [
    (v0/*: any*/),
    {
      "kind": "LinkedField",
      "alias": null,
      "name": "fields",
      "storageKey": null,
      "args": null,
      "concreteType": "FormField",
      "plural": true,
      "selections": [
        (v0/*: any*/),
        {
          "kind": "ScalarField",
          "alias": null,
          "name": "systemKey",
          "args": null,
          "storageKey": null
        },
        (v2/*: any*/),
        {
          "kind": "ScalarField",
          "alias": null,
          "name": "type",
          "args": null,
          "storageKey": null
        }
      ]
    },
    {
      "kind": "FragmentSpread",
      "name": "FormBuilder_form",
      "args": null
    }
  ]
};
return {
  "kind": "Fragment",
  "name": "RunYieldsInfo_run",
  "type": "Run",
  "metadata": null,
  "argumentDefinitions": [],
  "selections": [
    (v0/*: any*/),
    {
      "kind": "LinkedField",
      "alias": null,
      "name": "yields",
      "storageKey": null,
      "args": null,
      "concreteType": "RunYieldConnection",
      "plural": false,
      "selections": [
        {
          "kind": "LinkedField",
          "alias": null,
          "name": "edges",
          "storageKey": null,
          "args": null,
          "concreteType": "RunYieldEdge",
          "plural": true,
          "selections": [
            {
              "kind": "LinkedField",
              "alias": null,
              "name": "node",
              "storageKey": null,
              "args": null,
              "concreteType": "JobYield",
              "plural": false,
              "selections": [
                (v0/*: any*/),
                {
                  "kind": "ScalarField",
                  "alias": null,
                  "name": "quantity",
                  "args": null,
                  "storageKey": null
                },
                {
                  "kind": "ScalarField",
                  "alias": null,
                  "name": "overUnderRule",
                  "args": null,
                  "storageKey": null
                },
                {
                  "kind": "ScalarField",
                  "alias": null,
                  "name": "overQuantity",
                  "args": null,
                  "storageKey": null
                },
                {
                  "kind": "ScalarField",
                  "alias": null,
                  "name": "brokerCustomerOrderNumber",
                  "args": null,
                  "storageKey": null
                },
                {
                  "kind": "ScalarField",
                  "alias": null,
                  "name": "itemNumberUp",
                  "args": null,
                  "storageKey": null
                },
                (v1/*: any*/),
                (v3/*: any*/),
                {
                  "kind": "LinkedField",
                  "alias": null,
                  "name": "item",
                  "storageKey": null,
                  "args": null,
                  "concreteType": "Item",
                  "plural": false,
                  "selections": [
                    (v0/*: any*/),
                    {
                      "kind": "ScalarField",
                      "alias": null,
                      "name": "itemNumber",
                      "args": null,
                      "storageKey": null
                    },
                    (v2/*: any*/),
                    {
                      "kind": "ScalarField",
                      "alias": null,
                      "name": "partNumber",
                      "args": null,
                      "storageKey": null
                    },
                    {
                      "kind": "ScalarField",
                      "alias": null,
                      "name": "description",
                      "args": null,
                      "storageKey": null
                    },
                    (v1/*: any*/),
                    (v3/*: any*/)
                  ]
                }
              ]
            }
          ]
        }
      ]
    }
  ]
};
})();
// prettier-ignore
(node/*: any*/).hash = '1519dcdca16f5624723b8a5e594073fb';
module.exports = node;
