// @flow

import React from 'react';
import moment from 'moment';
import qs from 'qs';
import { connect } from 'react-redux';
import { QueryRenderer, graphql } from 'react-relay';
import relayEnvironment from 'shared/gql/relayEnvironment';
import Page from 'operator-portal/components/Page';
import type { RouterHistory } from 'react-router-dom';
import Loader from 'shared/components/common/Loader';
import RunsHeader from './RunsHeader';
import RefetchableRunsContainer from './RefetchableRunsContainer';

type Props = {
  history: RouterHistory,
  operatorMachineId: ?string,
};

function getDate(history) {
  const { date } = qs.parse(history.location.search || '', {
    ignoreQueryPrefix: true,
  });

  const momentDate = moment(date);

  if (momentDate.format() === 'Invalid date') {
    return moment();
  }

  return momentDate;
}

const Runs = ({ history, operatorMachineId }: Props) => {
  const date = getDate(history);
  const queryVariables = {
    startAt: date.clone().startOf('day'),
    endAt: date.clone().endOf('day'),
    machineIds: [operatorMachineId],
  };

  return (
    <Page hideToolbar>
      <RunsHeader history={history} date={date} />
      <QueryRenderer
        environment={relayEnvironment}
        cacheConfig={{
          force: true,
        }}
        variables={queryVariables}
        query={graphql`
          query RunsQuery(
            $startAt: DateTime!
            $endAt: DateTime!
            $machineIds: [ID!]!
          ) {
            runs(
              startAt: $startAt
              endAt: $endAt
              machineIds: $machineIds
              sortBy: { field: SCHEDULED_START_AT, direction: ASC }
            ) {
              ...RefetchableRunsContainer_runs
                @arguments(
                  startAt: $startAt
                  endAt: $endAt
                  machineIds: $machineIds
                )
            }
          }
        `}
        render={({ props }: *) => {
          if (!props) {
            return <Loader />;
          }

          return (
            <RefetchableRunsContainer
              queryVariables={queryVariables}
              runs={props.runs}
              history={history}
            />
          );
        }}
      />
    </Page>
  );
};

export default connect(state => ({
  operatorMachineId: state.operatorMachineId,
}))(Runs);
