/**
 * @flow
 * @relayHash f4ca8cde82745a8f317e6606325f01ab
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type SelectJobStateFieldQueryVariables = {|
  jobId: string,
  machineTypeId?: ?string,
|};
export type SelectJobStateFieldQueryResponse = {|
  +jobStates: $ReadOnlyArray<{|
    +id: string,
    +workflowState: {|
      +id: string,
      +name: string,
    |},
  |}>
|};
export type SelectJobStateFieldQuery = {|
  variables: SelectJobStateFieldQueryVariables,
  response: SelectJobStateFieldQueryResponse,
|};
*/


/*
query SelectJobStateFieldQuery(
  $jobId: ID!
  $machineTypeId: ID
) {
  jobStates(jobId: $jobId, machineTypeId: $machineTypeId) {
    id
    workflowState {
      id
      name
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "jobId",
    "type": "ID!",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "machineTypeId",
    "type": "ID",
    "defaultValue": null
  }
],
v1 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "id",
  "args": null,
  "storageKey": null
},
v2 = [
  {
    "kind": "LinkedField",
    "alias": null,
    "name": "jobStates",
    "storageKey": null,
    "args": [
      {
        "kind": "Variable",
        "name": "jobId",
        "variableName": "jobId"
      },
      {
        "kind": "Variable",
        "name": "machineTypeId",
        "variableName": "machineTypeId"
      }
    ],
    "concreteType": "JobState",
    "plural": true,
    "selections": [
      (v1/*: any*/),
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "workflowState",
        "storageKey": null,
        "args": null,
        "concreteType": "WorkflowState",
        "plural": false,
        "selections": [
          (v1/*: any*/),
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "name",
            "args": null,
            "storageKey": null
          }
        ]
      }
    ]
  }
];
return {
  "kind": "Request",
  "fragment": {
    "kind": "Fragment",
    "name": "SelectJobStateFieldQuery",
    "type": "Query",
    "metadata": null,
    "argumentDefinitions": (v0/*: any*/),
    "selections": (v2/*: any*/)
  },
  "operation": {
    "kind": "Operation",
    "name": "SelectJobStateFieldQuery",
    "argumentDefinitions": (v0/*: any*/),
    "selections": (v2/*: any*/)
  },
  "params": {
    "operationKind": "query",
    "name": "SelectJobStateFieldQuery",
    "id": null,
    "text": "query SelectJobStateFieldQuery(\n  $jobId: ID!\n  $machineTypeId: ID\n) {\n  jobStates(jobId: $jobId, machineTypeId: $machineTypeId) {\n    id\n    workflowState {\n      id\n      name\n    }\n  }\n}\n",
    "metadata": {}
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '2fa67b2b7d27df62b3281b35a441e030';
module.exports = node;
