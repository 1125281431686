/**
 * @flow
 * @relayHash d5d867599bb89f7c07871dc0769da33e
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type ChooseMachineQueryVariables = {||};
export type ChooseMachineQueryResponse = {|
  +viewer: ?{|
    +id: string,
    +company: {|
      +id: string,
      +name: ?string,
    |},
  |}
|};
export type ChooseMachineQuery = {|
  variables: ChooseMachineQueryVariables,
  response: ChooseMachineQueryResponse,
|};
*/


/*
query ChooseMachineQuery {
  viewer {
    id
    company {
      id
      name
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "id",
  "args": null,
  "storageKey": null
},
v1 = [
  {
    "kind": "LinkedField",
    "alias": null,
    "name": "viewer",
    "storageKey": null,
    "args": null,
    "concreteType": "User",
    "plural": false,
    "selections": [
      (v0/*: any*/),
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "company",
        "storageKey": null,
        "args": null,
        "concreteType": "Company",
        "plural": false,
        "selections": [
          (v0/*: any*/),
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "name",
            "args": null,
            "storageKey": null
          }
        ]
      }
    ]
  }
];
return {
  "kind": "Request",
  "fragment": {
    "kind": "Fragment",
    "name": "ChooseMachineQuery",
    "type": "Query",
    "metadata": null,
    "argumentDefinitions": [],
    "selections": (v1/*: any*/)
  },
  "operation": {
    "kind": "Operation",
    "name": "ChooseMachineQuery",
    "argumentDefinitions": [],
    "selections": (v1/*: any*/)
  },
  "params": {
    "operationKind": "query",
    "name": "ChooseMachineQuery",
    "id": null,
    "text": "query ChooseMachineQuery {\n  viewer {\n    id\n    company {\n      id\n      name\n    }\n  }\n}\n",
    "metadata": {}
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = 'cf008deef98d87ee2eb42e0b9efc6a2e';
module.exports = node;
