/**
 * @flow
 * @relayHash 553aebc07c9574f1a2cf7eeb2e8a4a39
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type ItemSortField = "CREATED_AT" | "ITEM_NUMBER" | "NAME" | "PART_NUMBER" | "UPDATED_AT";
export type SortDirection = "ASC" | "DESC";
export type ItemSort = {|
  field: ItemSortField,
  direction: SortDirection,
|};
export type SelectItemFieldQueryVariables = {|
  sortBy?: ?ItemSort,
  search?: ?string,
  orderIds?: ?$ReadOnlyArray<string>,
|};
export type SelectItemFieldQueryResponse = {|
  +items: {|
    +edges: ?$ReadOnlyArray<?{|
      +node: {|
        +id: string,
        +name: string,
        +quantityUOM: {|
          +id: string,
          +name: string,
          +symbol: string,
        |},
      |}
    |}>
  |}
|};
export type SelectItemFieldQuery = {|
  variables: SelectItemFieldQueryVariables,
  response: SelectItemFieldQueryResponse,
|};
*/


/*
query SelectItemFieldQuery(
  $sortBy: ItemSort
  $search: String
  $orderIds: [ID!]
) {
  items(first: 50, sortBy: $sortBy, search: $search, orderIds: $orderIds) {
    edges {
      node {
        id
        name
        quantityUOM {
          id
          name
          symbol
        }
      }
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "sortBy",
    "type": "ItemSort",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "search",
    "type": "String",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "orderIds",
    "type": "[ID!]",
    "defaultValue": null
  }
],
v1 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "id",
  "args": null,
  "storageKey": null
},
v2 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "name",
  "args": null,
  "storageKey": null
},
v3 = [
  {
    "kind": "LinkedField",
    "alias": null,
    "name": "items",
    "storageKey": null,
    "args": [
      {
        "kind": "Literal",
        "name": "first",
        "value": 50
      },
      {
        "kind": "Variable",
        "name": "orderIds",
        "variableName": "orderIds"
      },
      {
        "kind": "Variable",
        "name": "search",
        "variableName": "search"
      },
      {
        "kind": "Variable",
        "name": "sortBy",
        "variableName": "sortBy"
      }
    ],
    "concreteType": "ItemConnection",
    "plural": false,
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "edges",
        "storageKey": null,
        "args": null,
        "concreteType": "ItemEdge",
        "plural": true,
        "selections": [
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "node",
            "storageKey": null,
            "args": null,
            "concreteType": "Item",
            "plural": false,
            "selections": [
              (v1/*: any*/),
              (v2/*: any*/),
              {
                "kind": "LinkedField",
                "alias": null,
                "name": "quantityUOM",
                "storageKey": null,
                "args": null,
                "concreteType": "UOM",
                "plural": false,
                "selections": [
                  (v1/*: any*/),
                  (v2/*: any*/),
                  {
                    "kind": "ScalarField",
                    "alias": null,
                    "name": "symbol",
                    "args": null,
                    "storageKey": null
                  }
                ]
              }
            ]
          }
        ]
      }
    ]
  }
];
return {
  "kind": "Request",
  "fragment": {
    "kind": "Fragment",
    "name": "SelectItemFieldQuery",
    "type": "Query",
    "metadata": null,
    "argumentDefinitions": (v0/*: any*/),
    "selections": (v3/*: any*/)
  },
  "operation": {
    "kind": "Operation",
    "name": "SelectItemFieldQuery",
    "argumentDefinitions": (v0/*: any*/),
    "selections": (v3/*: any*/)
  },
  "params": {
    "operationKind": "query",
    "name": "SelectItemFieldQuery",
    "id": null,
    "text": "query SelectItemFieldQuery(\n  $sortBy: ItemSort\n  $search: String\n  $orderIds: [ID!]\n) {\n  items(first: 50, sortBy: $sortBy, search: $search, orderIds: $orderIds) {\n    edges {\n      node {\n        id\n        name\n        quantityUOM {\n          id\n          name\n          symbol\n        }\n      }\n    }\n  }\n}\n",
    "metadata": {}
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '4524ed6fdbaadeb04f32c215de2c60ca';
module.exports = node;
