// @flow

import React from 'react';
import styled from 'styled-components';
import Icon from 'shared/components/common/Icon';
import { colors, fonts } from 'shared/styleguide';

const Wrapper = styled.div`
  display: flex;
  flex-flow: row nowrap;
  justify-content: flex-start;
  align-items: center;
  padding: 16px;

  span {
    flex: 1;
    ${fonts.subtitle}
    color: ${colors.charcoalGrey};
  }
`;

type Props = {
  onClose: () => void,
  header: React$Node,
  icon?: 'alert',
};

const ModalHeader = ({ header: headerProp, icon, onClose }: Props) => {
  if (typeof headerProp === 'string') {
    return (
      <Wrapper>
        {icon === 'alert' ? (
          <Icon type="circle-alert-red" style={{ marginRight: 16 }} />
        ) : null}
        <span>{headerProp}</span>
        <Icon type="circle-close" onClick={() => onClose()} />
      </Wrapper>
    );
  }

  return <headerProp onClose={() => onClose()} />;
};

ModalHeader.defaultProps = {
  icon: undefined,
};

export default ModalHeader;
