// @flow

import { graphql } from 'react-relay';
import createRelayMutation from 'shared/gql/createRelayMutation';

const mutation = graphql`
  mutation UpdateJobStateMutation($input: UpdateJobStateInput) {
    updateJobState(input: $input) {
      jobState {
        id
        isEnabled
        formFieldValues
        status
        user {
          id
          ...UserWidget_user
        }
        workflowState {
          id
          isAssignable
          form {
            id
            fields {
              id
              name
              type
              options {
                id
                name
                value
              }
              ...CosmeticFieldValue_formField
              ...AdditionalFieldInfo_formField
            }
          }
        }
      }
      job {
        id
        status
        states {
          id
          status
        }
      }
    }
  }
`;

export default {
  commit: createRelayMutation.bind(null, mutation),
};
