// @flow

import { graphql } from 'react-relay';
import createRelayMutation from 'shared/gql/createRelayMutation';

const mutation = graphql`
  mutation OperatorSignInMutation($input: SignInInput!) {
    signIn(input: $input) {
      user {
        id
      }
    }
  }
`;

export default {
  commit: createRelayMutation.bind(null, mutation),
};
