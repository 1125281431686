// @flow

import React, { PureComponent } from 'react';
import { QueryRenderer, graphql } from 'react-relay';
import { connect } from 'react-redux';
import styled from 'styled-components';
import { colors } from 'shared/styleguide';
import relayEnvironment from 'shared/gql/relayEnvironment';
import { toggleMainMenu } from 'operator-portal/store/Actions';
import Icon from 'shared/components/common/Icon';
import Menu from './Menu';
import DowntimeStartModal from './DowntimeStartModal';
import DowntimeEndModal from './DowntimeEndModal';
import DowntimeOverlay from './DowntimeOverlay';

type Props = {
  hideMenu?: boolean,
  noPadding?: boolean,
  children: React$Node,
  mainMenuOpen: boolean,
  operatorMachineId?: string,
};

type State = {
  startDowntimeModalOpen: boolean,
  endDowntimeModalOpen: boolean,
};

const Wrapper = styled.div`
  position: relative;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
`;

const MobileMenuIcon = styled.div`
  position: fixed;
  width: 40px;
  height: 40px;
  bottom: 16px;
  right: 16px;
  border-radius: 40px;
  background-color: ${colors.charcoalGrey};

  @media (min-width: 800px) {
    display: none;
  }
`;

const ContentWrapper = styled.div`
  position: relative;
  padding: ${p => (p.noPadding ? 0 : 12)}px;
  width: 100%;
  flex: 1;
  display: flex;
  flex-flow: column;
  justify-content: flex-start;
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;

  @media (min-width: 800px) {
    padding: ${p => (p.noPadding ? 0 : 24)}px;
  }
`;

class Page extends PureComponent<Props, State> {
  static defaultProps = {
    hideMenu: undefined,
    noPadding: undefined,
    operatorMachineId: undefined,
  };

  state = {
    startDowntimeModalOpen: false,
    endDowntimeModalOpen: false,
  };

  handleOpenStartDowntimeModal = () => {
    this.setState({
      startDowntimeModalOpen: true,
    });
  };

  handleOpenEndDowntimeModal = () => {
    this.setState({
      endDowntimeModalOpen: true,
    });
  };

  handleCloseAllModals = () => {
    this.setState({
      startDowntimeModalOpen: false,
      endDowntimeModalOpen: false,
    });
  };

  render() {
    const {
      operatorMachineId,
      hideMenu,
      mainMenuOpen,
      noPadding,
      children,
    } = this.props;
    const { startDowntimeModalOpen, endDowntimeModalOpen } = this.state;

    const machineDowntimeLookup = Boolean(operatorMachineId);

    return (
      <QueryRenderer
        environment={relayEnvironment}
        variables={{
          skipMachineLookup: !machineDowntimeLookup,
          machineId: operatorMachineId || '',
        }}
        query={graphql`
          query PageOperatorQuery(
            $machineId: ID!
            $skipMachineLookup: Boolean!
          ) {
            machine(id: $machineId) @skip(if: $skipMachineLookup) {
              id
              currentDowntime {
                id
                ...DowntimeOverlay_downtime
              }
              ...DowntimeStartModal_machine
              ...DowntimeEndModal_machine
            }
          }
        `}
        render={({ props }) => (
          <>
            <Wrapper hideMenu={hideMenu}>
              {/* If a machine has been selected by the user, and there is downtime on this machine, then show the downtime overlay */}
              {machineDowntimeLookup && props?.machine?.currentDowntime ? (
                <>
                  <DowntimeOverlay
                    downtime={props.machine.currentDowntime}
                    onDowntimeEnd={this.handleOpenEndDowntimeModal}
                  />
                </>
              ) : (
                <>
                  <ContentWrapper noPadding={noPadding}>
                    {children}
                  </ContentWrapper>
                </>
              )}
              {hideMenu ? null : (
                <Menu
                  showDowntimeButton={
                    machineDowntimeLookup &&
                    !startDowntimeModalOpen &&
                    !props?.machine?.currentDowntime
                  }
                  onStartDowntime={this.handleOpenStartDowntimeModal}
                />
              )}
            </Wrapper>
            {mainMenuOpen ? null : (
              <MobileMenuIcon>
                <Icon
                  type="circle-menu"
                  size={40}
                  onClick={() => toggleMainMenu()}
                />
              </MobileMenuIcon>
            )}
            {endDowntimeModalOpen && (
              <DowntimeEndModal
                machine={props.machine}
                onClose={this.handleCloseAllModals}
              />
            )}
            {startDowntimeModalOpen && (
              <DowntimeStartModal
                machine={props.machine}
                onClose={this.handleCloseAllModals}
              />
            )}
          </>
        )}
      />
    );
  }
}

export default connect(state => ({
  mainMenuOpen: state.mainMenuOpen,
  operatorMachineId: state.operatorMachineId,
}))(Page);
