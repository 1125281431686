// @flow

import React from 'react';
import styled from 'styled-components';
import { colors } from 'shared/styleguide';
import Logo from './img/logo.svg';

const Wrapper = styled.div`
  padding: 8px 16px;
  border-bottom: 1px solid ${colors.lightBlueGrey};

  svg {
    width: 50px;
    height: 50px;
  }
`;

export default () => (
  <Wrapper>
    <Logo />
  </Wrapper>
);
