/**
 * @flow
 * @relayHash 3d82480111847e4214fcf6f677552fe8
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type CustomerLabelQueryVariables = {|
  customerId: string
|};
export type CustomerLabelQueryResponse = {|
  +customer: {|
    +id: string,
    +name: string,
  |}
|};
export type CustomerLabelQuery = {|
  variables: CustomerLabelQueryVariables,
  response: CustomerLabelQueryResponse,
|};
*/


/*
query CustomerLabelQuery(
  $customerId: ID!
) {
  customer(id: $customerId) {
    id
    name
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "customerId",
    "type": "ID!",
    "defaultValue": null
  }
],
v1 = [
  {
    "kind": "LinkedField",
    "alias": null,
    "name": "customer",
    "storageKey": null,
    "args": [
      {
        "kind": "Variable",
        "name": "id",
        "variableName": "customerId"
      }
    ],
    "concreteType": "Customer",
    "plural": false,
    "selections": [
      {
        "kind": "ScalarField",
        "alias": null,
        "name": "id",
        "args": null,
        "storageKey": null
      },
      {
        "kind": "ScalarField",
        "alias": null,
        "name": "name",
        "args": null,
        "storageKey": null
      }
    ]
  }
];
return {
  "kind": "Request",
  "fragment": {
    "kind": "Fragment",
    "name": "CustomerLabelQuery",
    "type": "Query",
    "metadata": null,
    "argumentDefinitions": (v0/*: any*/),
    "selections": (v1/*: any*/)
  },
  "operation": {
    "kind": "Operation",
    "name": "CustomerLabelQuery",
    "argumentDefinitions": (v0/*: any*/),
    "selections": (v1/*: any*/)
  },
  "params": {
    "operationKind": "query",
    "name": "CustomerLabelQuery",
    "id": null,
    "text": "query CustomerLabelQuery(\n  $customerId: ID!\n) {\n  customer(id: $customerId) {\n    id\n    name\n  }\n}\n",
    "metadata": {}
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '39cbce224985fcfece9f96f539e24244';
module.exports = node;
