// @flow

import React, { PureComponent, createRef } from 'react';
import { createFragmentContainer, graphql } from 'react-relay';
import styled from 'styled-components';
import moment from 'moment';
import { i18n, localizeEnum } from 'shared/utils';
import { colors, fonts } from 'shared/styleguide';
import UpdateRunLogMutation from 'shared/mutations/UpdateRunLog';
import UserWidget from 'shared/components/common/QueryWidget/UserWidget';
import Icon from 'shared/components/common/Icon';
import { DatePicker, Input } from 'shared/components/form';
import type { RunLogs_run as RunFragment } from './__generated__/RunLogs_run';

type Props = {
  run: RunFragment,
  onRunUpdate?: () => void,
  logEditEnabled?: boolean,
};

type State = {
  editLog: ?{
    id: string,
    date: moment$Moment,
    time: string,
    saving: boolean,
  },
};

const LogWrapper = styled.div`
  padding: 16px;
  margin-bottom: 16px;
  display: flex;
  flex-flow: row nowrap;
  align-items: flex-start;
  color: ${colors.charcoalGrey};
  ${fonts.bodyRegular};
  border: 1px solid ${colors.lightBlueGrey};
  border-radius: 5px;
`;

const LogTitle = styled.div`
  ${fonts.bodyBold};
`;

const LogNotes = styled.div`
  flex: 1;
  padding: 0 24px;
`;

const InputWrapper = styled.div`
  display: flex;
  flex-flow: row nowrap;
`;

class RunLogs extends PureComponent<Props, State> {
  static defaultProps = {
    onRunUpdate: () => {},
    logEditEnabled: false,
  };

  state = {
    editLog: null,
  };

  calendarInput: any = createRef();

  handleSaveLogDateTime = () => {};

  handleEditLogDate = value => {
    const { editLog } = this.state;
    this.setState({
      editLog: {
        ...editLog,
        date: moment(value),
      },
    });
  };

  handleEditLogTime = value => {
    const { editLog } = this.state;
    this.setState({
      editLog: {
        ...editLog,
        time: value,
      },
    });
  };

  handleEditLog = log => {
    const { logEditEnabled } = this.props;
    const createdAt = moment(log.createdAt);

    if (!logEditEnabled) {
      return;
    }

    this.setState(
      {
        editLog: {
          id: log.id,
          date: createdAt,
          time: createdAt.format('HH:mm'),
          saving: false,
        },
      },
      () => {
        if (this.calendarInput) {
          this.calendarInput.current.handleCalendarClick();
        }
      },
    );
  };

  handleCloseEditLog = () => {
    const { editLog } = this.state;
    const { onRunUpdate } = this.props;

    if (!editLog) {
      return;
    }

    this.setState(
      {
        editLog: {
          ...editLog,
          saving: true,
        },
      },
      async () => {
        await UpdateRunLogMutation.commit({
          variables: {
            input: {
              id: editLog.id,
              createdAt: moment(
                `${editLog.date.format('YYYY-MM-DD')} ${editLog.time}`,
                'YYYY-MM-DD HH:mm',
              ).format(),
            },
          },
        });

        if (onRunUpdate) {
          onRunUpdate();
        }

        this.setState({
          editLog: null,
        });
      },
    );
  };

  render() {
    const { run } = this.props;
    const { editLog } = this.state;
    const yieldEdges = (run.yields.edges || []).filter(Boolean);

    return (
      <>
        {run.logs.map(log => (
          <LogWrapper key={log.id}>
            <LogTitle>{localizeEnum(log.type)}</LogTitle>
            <LogNotes>
              {log.type === 'SETUP_END' && (
                <>
                  {yieldEdges.map(edge => (
                    <div key={edge.node.id} style={{ paddingBottom: 8 }}>
                      {i18n.t('Item: {{name}}', {
                        name: edge.node.item.name,
                      })}
                      <br />
                      {i18n.t('Total Setup Quantity: {{quantity, number}}', {
                        quantity: edge.setupQuantity,
                      })}
                    </div>
                  ))}
                </>
              )}
              {log.type === 'RUN_END' && (
                <>
                  {yieldEdges.map(edge => (
                    <div key={edge.node.id} style={{ paddingBottom: 8 }}>
                      {i18n.t('Item: {{name}}', {
                        name: edge.node.item.name,
                      })}
                      <br />
                      {i18n.t('Total Quantity Produced: {{quantity, number}}', {
                        quantity: edge.runQuantity,
                      })}
                      <br />
                      {i18n.t('Defective Quantity: {{quantity, number}}', {
                        quantity: edge.defectQuantity,
                      })}
                    </div>
                  ))}
                </>
              )}
              {log.notes}
            </LogNotes>
            <div>
              <div
                style={{ paddingBottom: 8 }}
                onDoubleClick={() => this.handleEditLog(log)}
              >
                {editLog && editLog.id === log.id ? (
                  <InputWrapper>
                    {editLog.saving ? (
                      i18n.t('Saving...')
                    ) : (
                      <>
                        <DatePicker
                          ref={this.calendarInput}
                          value={editLog.date}
                          allowPastDates
                          onChange={this.handleEditLogDate}
                        />
                        <Input
                          onChange={this.handleEditLogTime}
                          type="time"
                          value={editLog.time}
                        />
                        <Icon
                          type="circle-close"
                          onClick={this.handleCloseEditLog}
                          style={{
                            marginLeft: 8,
                          }}
                        />
                      </>
                    )}
                  </InputWrapper>
                ) : (
                  moment(log.createdAt).format('MM/DD/YYYY h:mm a')
                )}
              </div>
              <UserWidget user={log.user} />
            </div>
          </LogWrapper>
        ))}
      </>
    );
  }
}

export default createFragmentContainer(RunLogs, {
  run: graphql`
    fragment RunLogs_run on Run {
      id
      yields(first: null) {
        edges {
          setupQuantity
          runQuantity
          defectQuantity
          node {
            id
            item {
              id
              name
            }
          }
        }
      }
      machine {
        id
      }
      logs {
        id
        type
        notes
        createdAt
        user {
          ...UserWidget_user
        }
      }
    }
  `,
});
