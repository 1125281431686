// @flow

import React from 'react';
import { createFragmentContainer, graphql } from 'react-relay';
import * as Yup from 'yup';
import * as Actions from 'operator-portal/store/Actions';
import { i18n, Analytics } from 'shared/utils';
import UpdateMachineDowntimeStartMutation from 'shared/mutations/UpdateMachineDowntimeStart';
import {
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
} from 'shared/components/modal';
import {
  Formik,
  Form,
  FieldGroup,
  SelectDowntimeCategoryField,
} from 'shared/components/form';
import FatButton from 'shared/components/common/FatButton';
import type { DowntimeStartModal_machine as RunFragment } from './__generated__/DowntimeStartModal_machine';

type Props = {
  onClose: () => void,
  machine: RunFragment,
};

const DowntimeStartModal = ({ onClose, machine }: Props) => (
  <Modal maxWidth={700}>
    <Formik
      initialValues={{
        userNumber: '',
        startNotes: '',
        downtimeCategory: null,
      }}
      validationSchema={Yup.object().shape({
        userNumber: Yup.string().required('Required'),
        startNotes: Yup.string(),
        downtimeCategory: Yup.object()
          .nullable()
          .required('Required'),
      })}
      onSubmit={async (values: *, { setSubmitting }: *) => {
        setSubmitting(true);

        try {
          await UpdateMachineDowntimeStartMutation.commit({
            variables: {
              input: {
                machineId: machine.id,
                userNumber: values.userNumber,
                startNotes: values.startNotes,
                downtimeCategoryId: values.downtimeCategory?.value,
              },
            },
          });

          onClose();
          Analytics.trackEvent('Track Downtime', {
            operatorID: values.userNumber,
            notes: values.startNotes,
            machineType: machine?.type?.name,
            downtimeCategory: values.downtimeCategory?.data?.name,
          });
          Actions.alertNotification(i18n.t('Downtime Active'), 'success');
        } catch (e) {
          setSubmitting(false);
          Actions.alertNotification(e.message, 'error');
        }
      }}
      render={({ values, errors, isValid, isSubmitting, handleSubmit }) => (
        <Form>
          <ModalHeader
            header={i18n.t('Start Tracking Downtime?')}
            onClose={onClose}
          />
          <ModalBody withPadding>
            <FieldGroup
              name="userNumber"
              type="number"
              label={i18n.t('Operator ID')}
              placeholder={i18n.t('Enter ID')}
              error={errors.userNumber}
            />
            <SelectDowntimeCategoryField
              name="downtimeCategory"
              error={errors.downtimeCategory}
            />
            <FieldGroup
              name="startNotes"
              type="textarea"
              label={i18n.t('Notes')}
              placeholder={i18n.t('Type here...')}
              error={errors.startNotes}
              height={80}
            />
          </ModalBody>
          <ModalFooter style={{ display: 'block', textAlign: 'center' }}>
            <FatButton
              type="submit"
              disabled={!isValid}
              loading={isSubmitting}
              onClick={handleSubmit}
            >
              {i18n.t('Track Downtime For {{machineName}}', {
                machineName: machine.name,
              })}
            </FatButton>
          </ModalFooter>
        </Form>
      )}
    />
  </Modal>
);

export default createFragmentContainer(DowntimeStartModal, {
  machine: graphql`
    fragment DowntimeStartModal_machine on Machine {
      id
      name
      type {
        id
        name
      }
      currentDowntime {
        id
        category {
          id
          name
        }
      }
    }
  `,
});
