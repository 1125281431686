/**
 * @flow
 * @relayHash 658d787684db1cf9b650cbd96b0bdbc9
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type SelectMachineTypeFieldMachinesQueryVariables = {|
  machineTypeId?: ?string
|};
export type SelectMachineTypeFieldMachinesQueryResponse = {|
  +machines: {|
    +edges: ?$ReadOnlyArray<?{|
      +node: {|
        +id: string
      |}
    |}>
  |}
|};
export type SelectMachineTypeFieldMachinesQuery = {|
  variables: SelectMachineTypeFieldMachinesQueryVariables,
  response: SelectMachineTypeFieldMachinesQueryResponse,
|};
*/


/*
query SelectMachineTypeFieldMachinesQuery(
  $machineTypeId: ID
) {
  machines(machineTypeId: $machineTypeId) {
    edges {
      node {
        id
      }
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "machineTypeId",
    "type": "ID",
    "defaultValue": null
  }
],
v1 = [
  {
    "kind": "LinkedField",
    "alias": null,
    "name": "machines",
    "storageKey": null,
    "args": [
      {
        "kind": "Variable",
        "name": "machineTypeId",
        "variableName": "machineTypeId"
      }
    ],
    "concreteType": "MachineConnection",
    "plural": false,
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "edges",
        "storageKey": null,
        "args": null,
        "concreteType": "MachineEdge",
        "plural": true,
        "selections": [
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "node",
            "storageKey": null,
            "args": null,
            "concreteType": "Machine",
            "plural": false,
            "selections": [
              {
                "kind": "ScalarField",
                "alias": null,
                "name": "id",
                "args": null,
                "storageKey": null
              }
            ]
          }
        ]
      }
    ]
  }
];
return {
  "kind": "Request",
  "fragment": {
    "kind": "Fragment",
    "name": "SelectMachineTypeFieldMachinesQuery",
    "type": "Query",
    "metadata": null,
    "argumentDefinitions": (v0/*: any*/),
    "selections": (v1/*: any*/)
  },
  "operation": {
    "kind": "Operation",
    "name": "SelectMachineTypeFieldMachinesQuery",
    "argumentDefinitions": (v0/*: any*/),
    "selections": (v1/*: any*/)
  },
  "params": {
    "operationKind": "query",
    "name": "SelectMachineTypeFieldMachinesQuery",
    "id": null,
    "text": "query SelectMachineTypeFieldMachinesQuery(\n  $machineTypeId: ID\n) {\n  machines(machineTypeId: $machineTypeId) {\n    edges {\n      node {\n        id\n      }\n    }\n  }\n}\n",
    "metadata": {}
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = 'b2673cf3e9c27fd764cd8f6edfb61f9f';
module.exports = node;
