// @flow

import React from 'react';
import { createFragmentContainer, graphql } from 'react-relay';
import * as Yup from 'yup';
import { i18n, Analytics } from 'shared/utils';
import UpdateRunPauseMutation from 'shared/mutations/UpdateRunPause';
import {
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
} from 'shared/components/modal';
import { Formik, Form, FieldGroup } from 'shared/components/form';
import FatButton from 'shared/components/common/FatButton';
import type { RunPauseModal_run as RunFragment } from './__generated__/RunPauseModal_run';

type Props = {
  onClose: () => void,
  run: RunFragment,
};

const RunPauseModal = ({ onClose, run }: Props) => (
  <Modal maxWidth={700}>
    <Formik
      initialValues={{
        userNumber: '',
        notes: '',
      }}
      validationSchema={Yup.object().shape({
        userNumber: Yup.string().required('Required'),
        notes: Yup.string(),
      })}
      onSubmit={async (values: *, { setSubmitting }: *) => {
        setSubmitting(true);

        try {
          await UpdateRunPauseMutation.commit({
            variables: {
              input: {
                runId: run.id,
                userNumber: values.userNumber,
                notes: values.notes,
              },
            },
          });

          onClose();
          Analytics.trackEvent('Pause Run', {
            operatorID: values.userNumber,
            notes: values.notes,
            machineType: run.machine?.type?.name,
            pauseType: 'Run',
          });
        } catch (e) {
          setSubmitting(false);
          window.alert(e.message);
        }
      }}
      render={({ values, errors, isValid, isSubmitting, handleSubmit }) => (
        <Form>
          <ModalHeader header={i18n.t('Pause Run?')} onClose={onClose} />
          <ModalBody withPadding>
            <FieldGroup
              name="userNumber"
              type="number"
              label={i18n.t('Operator ID')}
              placeholder={i18n.t('Enter ID')}
              error={errors.userNumber}
            />
            <FieldGroup
              name="notes"
              type="textarea"
              label={i18n.t('Notes')}
              placeholder={i18n.t('Type here...')}
              error={errors.notes}
              height={80}
            />
          </ModalBody>
          <ModalFooter style={{ display: 'block', textAlign: 'center' }}>
            <FatButton
              type="submit"
              theme="blue"
              disabled={!isValid}
              loading={isSubmitting}
              onClick={handleSubmit}
            >
              {i18n.t('Pause Run')}
            </FatButton>
          </ModalFooter>
        </Form>
      )}
    />
  </Modal>
);

export default createFragmentContainer(RunPauseModal, {
  run: graphql`
    fragment RunPauseModal_run on Run {
      id
      machine {
        id
        type {
          id
          name
        }
      }
    }
  `,
});
