// @flow

import React from 'react'; // eslint-disable-line
import FieldTypeConfigs from './FieldTypeConfigs';

/**
 * Takes a form field type and a field value, and computes a user-friendly version of the value.
 * @param {Object} formField
 * @param {any} value
 * @return {string|React$Node}
 */
export default ({ formFieldType, value }: *) => {
  if (!value) {
    return '';
  }

  return FieldTypeConfigs[formFieldType].cosmeticValue(value);
};
