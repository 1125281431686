// @flow

import React from 'react';
import { QueryRenderer, graphql } from 'react-relay';
import relayEnvironment from 'shared/gql/relayEnvironment';

type Props = {
  machineId: string,
};

export default ({ machineId }: Props) => (
  <QueryRenderer
    environment={relayEnvironment}
    query={graphql`
      query MachineLabelQuery($machineId: ID!) {
        machine(id: $machineId) {
          id
          name
        }
      }
    `}
    variables={{ machineId }}
    render={query => (
      <span>
        {query.props
          ? query.props.machine?.name || 'Unknown Work Center'
          : '. . .'}
      </span>
    )}
  />
);
