// @flow

import { css } from 'styled-components';
import { lighten } from 'polished';
import colors from './colors';

export default css`
  color: ${colors.azul};
  text-decoration: none;
  transition: color 300ms ease;

  &:visited {
    color: ${colors.blueBlue};
  }

  &:hover {
    text-decoration: none;
    color: ${lighten(0.1, colors.azul)};
  }
`;
