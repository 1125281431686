/**
 * @flow
 * @relayHash eacb67b02414e6d814a199c3d54d1573
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type DowntimeCategorySortField = "CREATED_AT" | "NAME" | "UPDATED_AT";
export type SortDirection = "ASC" | "DESC";
export type DowntimeCategorySort = {|
  field: DowntimeCategorySortField,
  direction: SortDirection,
|};
export type SelectDowntimeCategoryFieldQueryVariables = {|
  sortBy?: ?DowntimeCategorySort,
  search?: ?string,
  parentsOnly?: ?boolean,
|};
export type SelectDowntimeCategoryFieldQueryResponse = {|
  +downtimeCategories: {|
    +edges: ?$ReadOnlyArray<?{|
      +node: {|
        +id: string,
        +name: string,
      |}
    |}>
  |}
|};
export type SelectDowntimeCategoryFieldQuery = {|
  variables: SelectDowntimeCategoryFieldQueryVariables,
  response: SelectDowntimeCategoryFieldQueryResponse,
|};
*/


/*
query SelectDowntimeCategoryFieldQuery(
  $sortBy: DowntimeCategorySort
  $search: String
  $parentsOnly: Boolean
) {
  downtimeCategories(first: 50, sortBy: $sortBy, search: $search, parentsOnly: $parentsOnly) {
    edges {
      node {
        id
        name
      }
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "sortBy",
    "type": "DowntimeCategorySort",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "search",
    "type": "String",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "parentsOnly",
    "type": "Boolean",
    "defaultValue": null
  }
],
v1 = [
  {
    "kind": "LinkedField",
    "alias": null,
    "name": "downtimeCategories",
    "storageKey": null,
    "args": [
      {
        "kind": "Literal",
        "name": "first",
        "value": 50
      },
      {
        "kind": "Variable",
        "name": "parentsOnly",
        "variableName": "parentsOnly"
      },
      {
        "kind": "Variable",
        "name": "search",
        "variableName": "search"
      },
      {
        "kind": "Variable",
        "name": "sortBy",
        "variableName": "sortBy"
      }
    ],
    "concreteType": "DowntimeCategoryConnection",
    "plural": false,
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "edges",
        "storageKey": null,
        "args": null,
        "concreteType": "DowntimeCategoryEdge",
        "plural": true,
        "selections": [
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "node",
            "storageKey": null,
            "args": null,
            "concreteType": "DowntimeCategory",
            "plural": false,
            "selections": [
              {
                "kind": "ScalarField",
                "alias": null,
                "name": "id",
                "args": null,
                "storageKey": null
              },
              {
                "kind": "ScalarField",
                "alias": null,
                "name": "name",
                "args": null,
                "storageKey": null
              }
            ]
          }
        ]
      }
    ]
  }
];
return {
  "kind": "Request",
  "fragment": {
    "kind": "Fragment",
    "name": "SelectDowntimeCategoryFieldQuery",
    "type": "Query",
    "metadata": null,
    "argumentDefinitions": (v0/*: any*/),
    "selections": (v1/*: any*/)
  },
  "operation": {
    "kind": "Operation",
    "name": "SelectDowntimeCategoryFieldQuery",
    "argumentDefinitions": (v0/*: any*/),
    "selections": (v1/*: any*/)
  },
  "params": {
    "operationKind": "query",
    "name": "SelectDowntimeCategoryFieldQuery",
    "id": null,
    "text": "query SelectDowntimeCategoryFieldQuery(\n  $sortBy: DowntimeCategorySort\n  $search: String\n  $parentsOnly: Boolean\n) {\n  downtimeCategories(first: 50, sortBy: $sortBy, search: $search, parentsOnly: $parentsOnly) {\n    edges {\n      node {\n        id\n        name\n      }\n    }\n  }\n}\n",
    "metadata": {}
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '6e2a97a2fb0a4ac650ccb69424756cb7';
module.exports = node;
