// @flow

import React from 'react'; // eslint-disable-line
import FieldTypeConfigs from './FieldTypeConfigs';

export default ({ formFieldType, value, props }: *) => {
  if (!value || !FieldTypeConfigs[formFieldType].additionalInfo) {
    return null;
  }

  return FieldTypeConfigs[formFieldType].additionalInfo(value, props || {});
};
