// @flow

// import 'shared/rhlConfig';
import React from 'react'; // eslint-disable-line import/first
import ReactDOM from 'react-dom'; // eslint-disable-line import/first
import 'shared/utils/i18n';
import store from './store';
import App from './App';

const appRoot: any = document.getElementById('app');

ReactDOM.render(<App store={store} />, appRoot);
