/**
 * @flow
 * @relayHash d3a53240f42f0cb1af620d59b8c34e72
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type SelectedMachineQueryVariables = {|
  id: string
|};
export type SelectedMachineQueryResponse = {|
  +machine: {|
    +id: string,
    +name: string,
    +type: {|
      +id: string,
      +name: string,
    |},
  |}
|};
export type SelectedMachineQuery = {|
  variables: SelectedMachineQueryVariables,
  response: SelectedMachineQueryResponse,
|};
*/


/*
query SelectedMachineQuery(
  $id: ID!
) {
  machine(id: $id) {
    id
    name
    type {
      id
      name
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "id",
    "type": "ID!",
    "defaultValue": null
  }
],
v1 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "id",
  "args": null,
  "storageKey": null
},
v2 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "name",
  "args": null,
  "storageKey": null
},
v3 = [
  {
    "kind": "LinkedField",
    "alias": null,
    "name": "machine",
    "storageKey": null,
    "args": [
      {
        "kind": "Variable",
        "name": "id",
        "variableName": "id"
      }
    ],
    "concreteType": "Machine",
    "plural": false,
    "selections": [
      (v1/*: any*/),
      (v2/*: any*/),
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "type",
        "storageKey": null,
        "args": null,
        "concreteType": "MachineType",
        "plural": false,
        "selections": [
          (v1/*: any*/),
          (v2/*: any*/)
        ]
      }
    ]
  }
];
return {
  "kind": "Request",
  "fragment": {
    "kind": "Fragment",
    "name": "SelectedMachineQuery",
    "type": "Query",
    "metadata": null,
    "argumentDefinitions": (v0/*: any*/),
    "selections": (v3/*: any*/)
  },
  "operation": {
    "kind": "Operation",
    "name": "SelectedMachineQuery",
    "argumentDefinitions": (v0/*: any*/),
    "selections": (v3/*: any*/)
  },
  "params": {
    "operationKind": "query",
    "name": "SelectedMachineQuery",
    "id": null,
    "text": "query SelectedMachineQuery(\n  $id: ID!\n) {\n  machine(id: $id) {\n    id\n    name\n    type {\n      id\n      name\n    }\n  }\n}\n",
    "metadata": {}
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = 'cfbcc851aa4bd83161f8621e7fb7b4c7';
module.exports = node;
