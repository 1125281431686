// @flow

import React from 'react';
import styled from 'styled-components';
import Icon from 'shared/components/common/Icon';
import RunStatusPill from 'shared/components/common/Pill/RunStatusPill';

type Props = {
  onGoBack: () => void,
  status: *,
};

const Wrapper = styled.div`
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  align-items: center;
  min-height: 54px;
  padding-bottom: 16px;
`;

const IconWrapper = styled.div`
  flex: 1;
`;

export default ({ onGoBack, status }: Props) => (
  <Wrapper>
    <IconWrapper>
      <Icon type="circle-arrow-left" size={32} onClick={onGoBack} />
    </IconWrapper>
    <RunStatusPill status={status} width="auto" />
  </Wrapper>
);
