/**
 * @flow
 * @relayHash 896d13daedac54bf2e42761daee741b2
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type LotSortField = "BIN_NUMBER" | "CREATED_AT" | "EXPIRES_AT" | "LOT_NUMBER" | "UPDATED_AT";
export type SortDirection = "ASC" | "DESC";
export type LotSort = {|
  field: LotSortField,
  direction: SortDirection,
|};
export type SelectLotFieldQueryVariables = {|
  sortBy?: ?LotSort,
  search?: ?string,
  itemId?: ?string,
|};
export type SelectLotFieldQueryResponse = {|
  +lots: {|
    +edges: ?$ReadOnlyArray<?{|
      +node: {|
        +id: string,
        +lotNumber: string,
        +items: {|
          +edges: ?$ReadOnlyArray<?{|
            +node: {|
              +qtyByLot: number
            |}
          |}>
        |},
      |}
    |}>
  |}
|};
export type SelectLotFieldQuery = {|
  variables: SelectLotFieldQueryVariables,
  response: SelectLotFieldQueryResponse,
|};
*/


/*
query SelectLotFieldQuery(
  $sortBy: LotSort
  $search: String
  $itemId: ID
) {
  lots(first: 50, sortBy: $sortBy, search: $search, itemId: $itemId) {
    edges {
      node {
        id
        lotNumber
        items {
          edges {
            node {
              qtyByLot
              id
            }
          }
        }
      }
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "sortBy",
    "type": "LotSort",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "search",
    "type": "String",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "itemId",
    "type": "ID",
    "defaultValue": null
  }
],
v1 = [
  {
    "kind": "Literal",
    "name": "first",
    "value": 50
  },
  {
    "kind": "Variable",
    "name": "itemId",
    "variableName": "itemId"
  },
  {
    "kind": "Variable",
    "name": "search",
    "variableName": "search"
  },
  {
    "kind": "Variable",
    "name": "sortBy",
    "variableName": "sortBy"
  }
],
v2 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "id",
  "args": null,
  "storageKey": null
},
v3 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "lotNumber",
  "args": null,
  "storageKey": null
},
v4 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "qtyByLot",
  "args": null,
  "storageKey": null
};
return {
  "kind": "Request",
  "fragment": {
    "kind": "Fragment",
    "name": "SelectLotFieldQuery",
    "type": "Query",
    "metadata": null,
    "argumentDefinitions": (v0/*: any*/),
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "lots",
        "storageKey": null,
        "args": (v1/*: any*/),
        "concreteType": "LotConnection",
        "plural": false,
        "selections": [
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "edges",
            "storageKey": null,
            "args": null,
            "concreteType": "LotEdge",
            "plural": true,
            "selections": [
              {
                "kind": "LinkedField",
                "alias": null,
                "name": "node",
                "storageKey": null,
                "args": null,
                "concreteType": "Lot",
                "plural": false,
                "selections": [
                  (v2/*: any*/),
                  (v3/*: any*/),
                  {
                    "kind": "LinkedField",
                    "alias": null,
                    "name": "items",
                    "storageKey": null,
                    "args": null,
                    "concreteType": "LotItemConnection",
                    "plural": false,
                    "selections": [
                      {
                        "kind": "LinkedField",
                        "alias": null,
                        "name": "edges",
                        "storageKey": null,
                        "args": null,
                        "concreteType": "LotItemEdge",
                        "plural": true,
                        "selections": [
                          {
                            "kind": "LinkedField",
                            "alias": null,
                            "name": "node",
                            "storageKey": null,
                            "args": null,
                            "concreteType": "Item",
                            "plural": false,
                            "selections": [
                              (v4/*: any*/)
                            ]
                          }
                        ]
                      }
                    ]
                  }
                ]
              }
            ]
          }
        ]
      }
    ]
  },
  "operation": {
    "kind": "Operation",
    "name": "SelectLotFieldQuery",
    "argumentDefinitions": (v0/*: any*/),
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "lots",
        "storageKey": null,
        "args": (v1/*: any*/),
        "concreteType": "LotConnection",
        "plural": false,
        "selections": [
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "edges",
            "storageKey": null,
            "args": null,
            "concreteType": "LotEdge",
            "plural": true,
            "selections": [
              {
                "kind": "LinkedField",
                "alias": null,
                "name": "node",
                "storageKey": null,
                "args": null,
                "concreteType": "Lot",
                "plural": false,
                "selections": [
                  (v2/*: any*/),
                  (v3/*: any*/),
                  {
                    "kind": "LinkedField",
                    "alias": null,
                    "name": "items",
                    "storageKey": null,
                    "args": null,
                    "concreteType": "LotItemConnection",
                    "plural": false,
                    "selections": [
                      {
                        "kind": "LinkedField",
                        "alias": null,
                        "name": "edges",
                        "storageKey": null,
                        "args": null,
                        "concreteType": "LotItemEdge",
                        "plural": true,
                        "selections": [
                          {
                            "kind": "LinkedField",
                            "alias": null,
                            "name": "node",
                            "storageKey": null,
                            "args": null,
                            "concreteType": "Item",
                            "plural": false,
                            "selections": [
                              (v4/*: any*/),
                              (v2/*: any*/)
                            ]
                          }
                        ]
                      }
                    ]
                  }
                ]
              }
            ]
          }
        ]
      }
    ]
  },
  "params": {
    "operationKind": "query",
    "name": "SelectLotFieldQuery",
    "id": null,
    "text": "query SelectLotFieldQuery(\n  $sortBy: LotSort\n  $search: String\n  $itemId: ID\n) {\n  lots(first: 50, sortBy: $sortBy, search: $search, itemId: $itemId) {\n    edges {\n      node {\n        id\n        lotNumber\n        items {\n          edges {\n            node {\n              qtyByLot\n              id\n            }\n          }\n        }\n      }\n    }\n  }\n}\n",
    "metadata": {}
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '92602ab93fa91ea37b23199cf6aaf83f';
module.exports = node;
