// @flow

import { commitMutation, type GraphQLTaggedNode } from 'react-relay';
import relayEnvironment from './relayEnvironment';

/**
 * Create a promise-based mutation
 * @param {GraphQLTaggedNode} mutation
 * @param {Object} options These are options that are automatically bound to the mutation
 * @return {Promise<Object>}
 */
export default function(
  mutation: GraphQLTaggedNode,
  options?: Object = {},
): Promise<*> {
  return new Promise((resolve, reject) => {
    commitMutation(relayEnvironment, {
      mutation,
      ...options,
      onCompleted: function(payload, errors) {
        if (errors) {
          return reject(errors[0]);
        }

        resolve(payload);
      },
      onError: function(error) {
        if (error.message) {
          return reject(new Error(error.message));
        }

        if (Array.isArray(error)) {
          return reject(new Error(error.map(e => e.message || e).join(', ')));
        }

        return reject(new Error(error.toString()));
      },
    });
  });
}
