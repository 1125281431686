/**
 * @flow
 * @relayHash 58b99e38586cabe79f89989dfd5a0ae7
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type SelectWorkflowFieldQueryVariables = {||};
export type SelectWorkflowFieldQueryResponse = {|
  +workflows: {|
    +edges: ?$ReadOnlyArray<?{|
      +node: {|
        +id: string,
        +name: string,
        +states: $ReadOnlyArray<{|
          +id: string,
          +isGangRun: ?boolean,
        |}>,
      |}
    |}>
  |}
|};
export type SelectWorkflowFieldQuery = {|
  variables: SelectWorkflowFieldQueryVariables,
  response: SelectWorkflowFieldQueryResponse,
|};
*/


/*
query SelectWorkflowFieldQuery {
  workflows {
    edges {
      node {
        id
        name
        states {
          id
          isGangRun
        }
      }
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "id",
  "args": null,
  "storageKey": null
},
v1 = [
  {
    "kind": "LinkedField",
    "alias": null,
    "name": "workflows",
    "storageKey": null,
    "args": null,
    "concreteType": "WorkflowConnection",
    "plural": false,
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "edges",
        "storageKey": null,
        "args": null,
        "concreteType": "WorkflowEdge",
        "plural": true,
        "selections": [
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "node",
            "storageKey": null,
            "args": null,
            "concreteType": "Workflow",
            "plural": false,
            "selections": [
              (v0/*: any*/),
              {
                "kind": "ScalarField",
                "alias": null,
                "name": "name",
                "args": null,
                "storageKey": null
              },
              {
                "kind": "LinkedField",
                "alias": null,
                "name": "states",
                "storageKey": null,
                "args": null,
                "concreteType": "WorkflowState",
                "plural": true,
                "selections": [
                  (v0/*: any*/),
                  {
                    "kind": "ScalarField",
                    "alias": null,
                    "name": "isGangRun",
                    "args": null,
                    "storageKey": null
                  }
                ]
              }
            ]
          }
        ]
      }
    ]
  }
];
return {
  "kind": "Request",
  "fragment": {
    "kind": "Fragment",
    "name": "SelectWorkflowFieldQuery",
    "type": "Query",
    "metadata": null,
    "argumentDefinitions": [],
    "selections": (v1/*: any*/)
  },
  "operation": {
    "kind": "Operation",
    "name": "SelectWorkflowFieldQuery",
    "argumentDefinitions": [],
    "selections": (v1/*: any*/)
  },
  "params": {
    "operationKind": "query",
    "name": "SelectWorkflowFieldQuery",
    "id": null,
    "text": "query SelectWorkflowFieldQuery {\n  workflows {\n    edges {\n      node {\n        id\n        name\n        states {\n          id\n          isGangRun\n        }\n      }\n    }\n  }\n}\n",
    "metadata": {}
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = 'f7c05c90ee9326906d375e6c3bc879f9';
module.exports = node;
