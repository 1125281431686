// @flow

import React from 'react';
import styled from 'styled-components';
import { colors, fonts } from 'shared/styleguide';

type Props = {
  children: React$Node,
  style?: Object,
};

const Wrapper = styled.label`
  ${fonts.bodyBold}
  color: ${colors.charcoalGrey};
  display: block;
  padding-bottom: 4px;
  display: flex;
  justify-content: space-between;
`;

/**
 * Basic form field label that shows above most input fields
 * @example <FieldLabel>Email Address</FieldLabel>
 * @param {Object} props
 * @return {React$Node}
 */
const FieldLabel = ({ style, children }: Props) => (
  <Wrapper style={style}>{children}</Wrapper>
);

FieldLabel.defaultProps = {
  style: undefined,
};

export default FieldLabel;
