// @flow

import React, { PureComponent } from 'react';
import { i18n } from 'shared/utils';
import { fetchQuery, graphql } from 'relay-runtime';
import relayEnvironment from 'shared/gql/relayEnvironment';
import { FieldGroup } from 'shared/components/form';

type Props = {
  name?: string,
  rawField?: boolean,
  label?: ?string,
  error?: ?string,
  queryVariables?: ?Object,
  excludeIds?: Array<string>,
  creatingNewRecord?: boolean,
  validateMachineType?: boolean,
};

type State = {
  fetchedOptions: Array<Object>,
  validMachineType: boolean,
};

const QUERY = graphql`
  query SelectMachineTypeFieldQuery {
    machineTypes {
      edges {
        node {
          id
          name
        }
      }
    }
  }
`;

const MACHINES_QUERY = graphql`
  query SelectMachineTypeFieldMachinesQuery($machineTypeId: ID) {
    machines(machineTypeId: $machineTypeId) {
      edges {
        node {
          id
        }
      }
    }
  }
`;

class SelectMachineTypeField extends PureComponent<Props, State> {
  static defaultProps = {
    name: undefined,
    rawField: false,
    label: undefined,
    error: undefined,
    excludeIds: [],
    queryVariables: {},
    creatingNewRecord: false,
    validateMachineType: false,
  };

  mounted = false;

  state = {
    fetchedOptions: [],
    validMachineType: true,
  };

  componentDidMount() {
    this.mounted = true;

    this.getData();
  }

  componentWillUnmount() {
    this.mounted = false;
  }

  getData = async (inputValue?: string) => {
    const { queryVariables, excludeIds } = this.props;

    try {
      const results = await fetchQuery(relayEnvironment, QUERY, {
        search: inputValue,
        ...queryVariables,
      });

      const options = this.massageData(
        results.machineTypes.edges || [],
        excludeIds,
      );

      if (this.mounted) {
        this.setState({
          fetchedOptions: options,
        });
      }

      return options;
    } catch (e) {
      return [];
    }
  };

  getValidation = async (machineTypeId: string) => {
    const queryVariables = {
      machineTypeId,
    };

    try {
      const results = await fetchQuery(
        relayEnvironment,
        MACHINES_QUERY,
        queryVariables,
      );
      const validMachineType = Boolean(results.machines.edges.length);

      if (this.mounted) {
        this.setState({
          validMachineType,
        });
      }
    } catch (e) {
      this.setState({
        validMachineType: true,
      });
    }
  };

  /**
   * Massage records to be ReactSelect-friendly
   * @param {Array<Object>} records
   * @param {Array<string | number>} excludeIds
   * @return {Array<Object>}
   */
  massageData = (
    records: Array<Object>,
    excludeIds?: Array<string> = [],
  ): Array<Object> => {
    return (
      records
        // filter out specified ids
        .filter(a => excludeIds.indexOf(a.node.id.toString()) < 0)
        .map(({ node }) => ({
          data: node,
          value: node.id,
          label: node.name,
        }))
    );
  };

  render() {
    const {
      name,
      rawField,
      label,
      error,
      creatingNewRecord,
      validateMachineType,
      ...fieldProps
    } = this.props;
    const { fetchedOptions, validMachineType } = this.state;
    const validationProps = validateMachineType
      ? {
          onChange: ({ value }) => {
            this.getValidation(value);
          },
          error:
            (validateMachineType &&
              !validMachineType &&
              i18n.t(
                'This Work Center Type does not have any Work Centers associated to it.',
              )) ||
            error,
        }
      : {};

    return (
      <FieldGroup
        name={name}
        rawField={rawField || false}
        label={label !== undefined ? label : i18n.t('Work Center Type')}
        error={error}
        message={
          creatingNewRecord
            ? i18n.t(`A new work center type will be created`)
            : null
        }
        clearable
        placeholder={i18n.t(`Search Work Center Type`)}
        {...validationProps}
        {...fieldProps}
        options={fetchedOptions}
        type="select"
        // loadOptions={this.getData}
        defaultOptions
      />
    );
  }
}

export default SelectMachineTypeField;
