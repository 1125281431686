// @flow

import React, { PureComponent } from 'react';
import ReactDOM from 'react-dom';
import ModalElement from './ModalElement';

type Props = {
  maxWidth?: number,
  onClickAway?: () => void,
  children: React$Node,
};

class Modal extends PureComponent<Props> {
  ref: *;

  static defaultProps = {
    maxWidth: undefined,
    onClickAway: undefined,
  };

  /**
   * Instantiate a react element, add it to the document body, and
   * initiate global click event handlers
   */
  componentDidMount() {
    // Setup some event listeners for the mouse and touch (mobile) events
    document.addEventListener('mousedown', this.handleClickAway);
    document.addEventListener('touchend', this.handleClickAway);
  }

  componentWillUnmount() {
    this.teardownClickAwayListeners();
  }

  teardownClickAwayListeners = () => {
    document.removeEventListener('mousedown', this.handleClickAway);
    document.removeEventListener('touchend', this.handleClickAway);
  };

  handleClickAway = (e: Event) => {
    const { onClickAway } = this.props;

    if (onClickAway && this.ref && !this.ref.contains(e.target)) {
      onClickAway();
    }
  };

  render() {
    const { maxWidth, children } = this.props;
    const documentRoot: any = document.getElementById('app');

    return ReactDOM.createPortal(
      <ModalElement getRef={r => (this.ref = r)} maxWidth={maxWidth}>
        {children}
      </ModalElement>,
      documentRoot,
    );
  }
}

export default Modal;
