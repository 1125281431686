/**
 * @flow
 * @relayHash f04f7e5e3bce5cc483ae27ec3c1441fd
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type OrderLabelQueryVariables = {|
  orderId: string
|};
export type OrderLabelQueryResponse = {|
  +order: {|
    +id: string,
    +orderNumber: string,
    +customer: {|
      +id: string,
      +name: string,
    |},
  |}
|};
export type OrderLabelQuery = {|
  variables: OrderLabelQueryVariables,
  response: OrderLabelQueryResponse,
|};
*/


/*
query OrderLabelQuery(
  $orderId: ID!
) {
  order(id: $orderId) {
    id
    orderNumber
    customer {
      id
      name
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "orderId",
    "type": "ID!",
    "defaultValue": null
  }
],
v1 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "id",
  "args": null,
  "storageKey": null
},
v2 = [
  {
    "kind": "LinkedField",
    "alias": null,
    "name": "order",
    "storageKey": null,
    "args": [
      {
        "kind": "Variable",
        "name": "id",
        "variableName": "orderId"
      }
    ],
    "concreteType": "Order",
    "plural": false,
    "selections": [
      (v1/*: any*/),
      {
        "kind": "ScalarField",
        "alias": null,
        "name": "orderNumber",
        "args": null,
        "storageKey": null
      },
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "customer",
        "storageKey": null,
        "args": null,
        "concreteType": "Customer",
        "plural": false,
        "selections": [
          (v1/*: any*/),
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "name",
            "args": null,
            "storageKey": null
          }
        ]
      }
    ]
  }
];
return {
  "kind": "Request",
  "fragment": {
    "kind": "Fragment",
    "name": "OrderLabelQuery",
    "type": "Query",
    "metadata": null,
    "argumentDefinitions": (v0/*: any*/),
    "selections": (v2/*: any*/)
  },
  "operation": {
    "kind": "Operation",
    "name": "OrderLabelQuery",
    "argumentDefinitions": (v0/*: any*/),
    "selections": (v2/*: any*/)
  },
  "params": {
    "operationKind": "query",
    "name": "OrderLabelQuery",
    "id": null,
    "text": "query OrderLabelQuery(\n  $orderId: ID!\n) {\n  order(id: $orderId) {\n    id\n    orderNumber\n    customer {\n      id\n      name\n    }\n  }\n}\n",
    "metadata": {}
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '620a03c20de9302f22db8276545df48e';
module.exports = node;
