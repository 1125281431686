/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ReaderFragment } from 'relay-runtime';
import type { FragmentReference } from "relay-runtime";
declare export opaque type DowntimeEndModal_machine$ref: FragmentReference;
declare export opaque type DowntimeEndModal_machine$fragmentType: DowntimeEndModal_machine$ref;
export type DowntimeEndModal_machine = {|
  +id: string,
  +name: string,
  +type: {|
    +id: string,
    +name: string,
  |},
  +currentDowntime: ?{|
    +id: string
  |},
  +$refType: DowntimeEndModal_machine$ref,
|};
export type DowntimeEndModal_machine$data = DowntimeEndModal_machine;
export type DowntimeEndModal_machine$key = {
  +$data?: DowntimeEndModal_machine$data,
  +$fragmentRefs: DowntimeEndModal_machine$ref,
};
*/


const node/*: ReaderFragment*/ = (function(){
var v0 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "id",
  "args": null,
  "storageKey": null
},
v1 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "name",
  "args": null,
  "storageKey": null
};
return {
  "kind": "Fragment",
  "name": "DowntimeEndModal_machine",
  "type": "Machine",
  "metadata": null,
  "argumentDefinitions": [],
  "selections": [
    (v0/*: any*/),
    (v1/*: any*/),
    {
      "kind": "LinkedField",
      "alias": null,
      "name": "type",
      "storageKey": null,
      "args": null,
      "concreteType": "MachineType",
      "plural": false,
      "selections": [
        (v0/*: any*/),
        (v1/*: any*/)
      ]
    },
    {
      "kind": "LinkedField",
      "alias": null,
      "name": "currentDowntime",
      "storageKey": null,
      "args": null,
      "concreteType": "MachineDowntime",
      "plural": false,
      "selections": [
        (v0/*: any*/)
      ]
    }
  ]
};
})();
// prettier-ignore
(node/*: any*/).hash = '808b7813dca884e88f99a234f30d2daa';
module.exports = node;
