// @flow

import React from 'react';
import { StyledButton, Loading } from './styled';

type Props = {
  children: React$Node,
  onClick?: Function,
  theme?: 'blue' | 'red' | 'green' | 'transparent' | 'border-white',
  loading?: boolean,
  disabled?: boolean,
  type?: 'button' | 'submit' | 'reset',
  form?: string,
  width?: number | string,
};

const Button = ({
  children,
  onClick,
  theme,
  loading,
  disabled,
  type,
  form,
  width,
}: Props) => (
  <StyledButton
    theme={theme}
    onClick={onClick}
    disabled={loading || disabled}
    loading={loading ? 1 : 0}
    type={type || 'button'}
    form={form || undefined}
    width={width}
  >
    {loading ? (
      <Loading theme={theme}>
        <div />
        <div />
        <div />
      </Loading>
    ) : (
      children
    )}
  </StyledButton>
);

Button.defaultProps = {
  onClick: undefined,
  theme: 'blue',
  loading: false,
  disabled: false,
  type: 'button',
  form: undefined,
  width: undefined,
};

export default Button;
