/**
 * @flow
 * @relayHash 6a1404efaa0f2e037f4a493f163edd63
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type SelectCustomerFieldQueryVariables = {|
  search?: ?string
|};
export type SelectCustomerFieldQueryResponse = {|
  +customers: {|
    +edges: ?$ReadOnlyArray<?{|
      +node: {|
        +id: string,
        +name: string,
        +shippingAddressLine1: ?string,
        +shippingAddressLine2: ?string,
        +shippingAddressLine3: ?string,
        +shippingAddressCity: ?string,
        +shippingAddressState: ?string,
        +shippingAddressZip: ?string,
        +billingAddressLine1: ?string,
        +billingAddressLine2: ?string,
        +billingAddressLine3: ?string,
        +billingAddressCity: ?string,
        +billingAddressState: ?string,
        +billingAddressZip: ?string,
        +salesUser: ?{|
          +id: string,
          +firstName: string,
          +lastName: string,
        |},
      |}
    |}>
  |}
|};
export type SelectCustomerFieldQuery = {|
  variables: SelectCustomerFieldQueryVariables,
  response: SelectCustomerFieldQueryResponse,
|};
*/


/*
query SelectCustomerFieldQuery(
  $search: String
) {
  customers(first: 50, search: $search) {
    edges {
      node {
        id
        name
        shippingAddressLine1
        shippingAddressLine2
        shippingAddressLine3
        shippingAddressCity
        shippingAddressState
        shippingAddressZip
        billingAddressLine1
        billingAddressLine2
        billingAddressLine3
        billingAddressCity
        billingAddressState
        billingAddressZip
        salesUser {
          id
          firstName
          lastName
        }
      }
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "search",
    "type": "String",
    "defaultValue": null
  }
],
v1 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "id",
  "args": null,
  "storageKey": null
},
v2 = [
  {
    "kind": "LinkedField",
    "alias": null,
    "name": "customers",
    "storageKey": null,
    "args": [
      {
        "kind": "Literal",
        "name": "first",
        "value": 50
      },
      {
        "kind": "Variable",
        "name": "search",
        "variableName": "search"
      }
    ],
    "concreteType": "CustomerConnection",
    "plural": false,
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "edges",
        "storageKey": null,
        "args": null,
        "concreteType": "CustomerEdge",
        "plural": true,
        "selections": [
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "node",
            "storageKey": null,
            "args": null,
            "concreteType": "Customer",
            "plural": false,
            "selections": [
              (v1/*: any*/),
              {
                "kind": "ScalarField",
                "alias": null,
                "name": "name",
                "args": null,
                "storageKey": null
              },
              {
                "kind": "ScalarField",
                "alias": null,
                "name": "shippingAddressLine1",
                "args": null,
                "storageKey": null
              },
              {
                "kind": "ScalarField",
                "alias": null,
                "name": "shippingAddressLine2",
                "args": null,
                "storageKey": null
              },
              {
                "kind": "ScalarField",
                "alias": null,
                "name": "shippingAddressLine3",
                "args": null,
                "storageKey": null
              },
              {
                "kind": "ScalarField",
                "alias": null,
                "name": "shippingAddressCity",
                "args": null,
                "storageKey": null
              },
              {
                "kind": "ScalarField",
                "alias": null,
                "name": "shippingAddressState",
                "args": null,
                "storageKey": null
              },
              {
                "kind": "ScalarField",
                "alias": null,
                "name": "shippingAddressZip",
                "args": null,
                "storageKey": null
              },
              {
                "kind": "ScalarField",
                "alias": null,
                "name": "billingAddressLine1",
                "args": null,
                "storageKey": null
              },
              {
                "kind": "ScalarField",
                "alias": null,
                "name": "billingAddressLine2",
                "args": null,
                "storageKey": null
              },
              {
                "kind": "ScalarField",
                "alias": null,
                "name": "billingAddressLine3",
                "args": null,
                "storageKey": null
              },
              {
                "kind": "ScalarField",
                "alias": null,
                "name": "billingAddressCity",
                "args": null,
                "storageKey": null
              },
              {
                "kind": "ScalarField",
                "alias": null,
                "name": "billingAddressState",
                "args": null,
                "storageKey": null
              },
              {
                "kind": "ScalarField",
                "alias": null,
                "name": "billingAddressZip",
                "args": null,
                "storageKey": null
              },
              {
                "kind": "LinkedField",
                "alias": null,
                "name": "salesUser",
                "storageKey": null,
                "args": null,
                "concreteType": "User",
                "plural": false,
                "selections": [
                  (v1/*: any*/),
                  {
                    "kind": "ScalarField",
                    "alias": null,
                    "name": "firstName",
                    "args": null,
                    "storageKey": null
                  },
                  {
                    "kind": "ScalarField",
                    "alias": null,
                    "name": "lastName",
                    "args": null,
                    "storageKey": null
                  }
                ]
              }
            ]
          }
        ]
      }
    ]
  }
];
return {
  "kind": "Request",
  "fragment": {
    "kind": "Fragment",
    "name": "SelectCustomerFieldQuery",
    "type": "Query",
    "metadata": null,
    "argumentDefinitions": (v0/*: any*/),
    "selections": (v2/*: any*/)
  },
  "operation": {
    "kind": "Operation",
    "name": "SelectCustomerFieldQuery",
    "argumentDefinitions": (v0/*: any*/),
    "selections": (v2/*: any*/)
  },
  "params": {
    "operationKind": "query",
    "name": "SelectCustomerFieldQuery",
    "id": null,
    "text": "query SelectCustomerFieldQuery(\n  $search: String\n) {\n  customers(first: 50, search: $search) {\n    edges {\n      node {\n        id\n        name\n        shippingAddressLine1\n        shippingAddressLine2\n        shippingAddressLine3\n        shippingAddressCity\n        shippingAddressState\n        shippingAddressZip\n        billingAddressLine1\n        billingAddressLine2\n        billingAddressLine3\n        billingAddressCity\n        billingAddressState\n        billingAddressZip\n        salesUser {\n          id\n          firstName\n          lastName\n        }\n      }\n    }\n  }\n}\n",
    "metadata": {}
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '6bbae2771b37fc66f42edd75ebd8529e';
module.exports = node;
