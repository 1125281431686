/**
 * @flow
 * @relayHash 517865ba7d850b32bb574761b536c523
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type UpdateMachineDowntimeEndInput = {|
  machineId: string,
  userNumber: string,
  endNotes?: ?string,
|};
export type UpdateMachineDowntimeEndMutationVariables = {|
  input: UpdateMachineDowntimeEndInput
|};
export type UpdateMachineDowntimeEndMutationResponse = {|
  +updateMachineDowntimeEnd: {|
    +machineEdge: {|
      +cursor: string,
      +node: {|
        +id: string,
        +name: string,
        +currentDowntime: ?{|
          +id: string,
          +startNotes: ?string,
          +endNotes: ?string,
          +startAt: ?any,
          +endAt: ?any,
          +startUser: ?{|
            +id: string,
            +firstName: string,
            +lastName: string,
            +avatar: ?string,
          |},
          +endUser: ?{|
            +id: string,
            +firstName: string,
            +lastName: string,
            +avatar: ?string,
          |},
          +category: {|
            +id: string,
            +name: string,
          |},
        |},
      |},
    |}
  |}
|};
export type UpdateMachineDowntimeEndMutation = {|
  variables: UpdateMachineDowntimeEndMutationVariables,
  response: UpdateMachineDowntimeEndMutationResponse,
|};
*/


/*
mutation UpdateMachineDowntimeEndMutation(
  $input: UpdateMachineDowntimeEndInput!
) {
  updateMachineDowntimeEnd(input: $input) {
    machineEdge {
      cursor
      node {
        id
        name
        currentDowntime {
          id
          startNotes
          endNotes
          startAt
          endAt
          startUser {
            id
            firstName
            lastName
            avatar
          }
          endUser {
            id
            firstName
            lastName
            avatar
          }
          category {
            id
            name
          }
        }
      }
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "input",
    "type": "UpdateMachineDowntimeEndInput!",
    "defaultValue": null
  }
],
v1 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "id",
  "args": null,
  "storageKey": null
},
v2 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "name",
  "args": null,
  "storageKey": null
},
v3 = [
  (v1/*: any*/),
  {
    "kind": "ScalarField",
    "alias": null,
    "name": "firstName",
    "args": null,
    "storageKey": null
  },
  {
    "kind": "ScalarField",
    "alias": null,
    "name": "lastName",
    "args": null,
    "storageKey": null
  },
  {
    "kind": "ScalarField",
    "alias": null,
    "name": "avatar",
    "args": null,
    "storageKey": null
  }
],
v4 = [
  {
    "kind": "LinkedField",
    "alias": null,
    "name": "updateMachineDowntimeEnd",
    "storageKey": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "UpdateMachineDowntimeEndPayload",
    "plural": false,
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "machineEdge",
        "storageKey": null,
        "args": null,
        "concreteType": "MachineEdge",
        "plural": false,
        "selections": [
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "cursor",
            "args": null,
            "storageKey": null
          },
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "node",
            "storageKey": null,
            "args": null,
            "concreteType": "Machine",
            "plural": false,
            "selections": [
              (v1/*: any*/),
              (v2/*: any*/),
              {
                "kind": "LinkedField",
                "alias": null,
                "name": "currentDowntime",
                "storageKey": null,
                "args": null,
                "concreteType": "MachineDowntime",
                "plural": false,
                "selections": [
                  (v1/*: any*/),
                  {
                    "kind": "ScalarField",
                    "alias": null,
                    "name": "startNotes",
                    "args": null,
                    "storageKey": null
                  },
                  {
                    "kind": "ScalarField",
                    "alias": null,
                    "name": "endNotes",
                    "args": null,
                    "storageKey": null
                  },
                  {
                    "kind": "ScalarField",
                    "alias": null,
                    "name": "startAt",
                    "args": null,
                    "storageKey": null
                  },
                  {
                    "kind": "ScalarField",
                    "alias": null,
                    "name": "endAt",
                    "args": null,
                    "storageKey": null
                  },
                  {
                    "kind": "LinkedField",
                    "alias": null,
                    "name": "startUser",
                    "storageKey": null,
                    "args": null,
                    "concreteType": "User",
                    "plural": false,
                    "selections": (v3/*: any*/)
                  },
                  {
                    "kind": "LinkedField",
                    "alias": null,
                    "name": "endUser",
                    "storageKey": null,
                    "args": null,
                    "concreteType": "User",
                    "plural": false,
                    "selections": (v3/*: any*/)
                  },
                  {
                    "kind": "LinkedField",
                    "alias": null,
                    "name": "category",
                    "storageKey": null,
                    "args": null,
                    "concreteType": "DowntimeCategory",
                    "plural": false,
                    "selections": [
                      (v1/*: any*/),
                      (v2/*: any*/)
                    ]
                  }
                ]
              }
            ]
          }
        ]
      }
    ]
  }
];
return {
  "kind": "Request",
  "fragment": {
    "kind": "Fragment",
    "name": "UpdateMachineDowntimeEndMutation",
    "type": "Mutation",
    "metadata": null,
    "argumentDefinitions": (v0/*: any*/),
    "selections": (v4/*: any*/)
  },
  "operation": {
    "kind": "Operation",
    "name": "UpdateMachineDowntimeEndMutation",
    "argumentDefinitions": (v0/*: any*/),
    "selections": (v4/*: any*/)
  },
  "params": {
    "operationKind": "mutation",
    "name": "UpdateMachineDowntimeEndMutation",
    "id": null,
    "text": "mutation UpdateMachineDowntimeEndMutation(\n  $input: UpdateMachineDowntimeEndInput!\n) {\n  updateMachineDowntimeEnd(input: $input) {\n    machineEdge {\n      cursor\n      node {\n        id\n        name\n        currentDowntime {\n          id\n          startNotes\n          endNotes\n          startAt\n          endAt\n          startUser {\n            id\n            firstName\n            lastName\n            avatar\n          }\n          endUser {\n            id\n            firstName\n            lastName\n            avatar\n          }\n          category {\n            id\n            name\n          }\n        }\n      }\n    }\n  }\n}\n",
    "metadata": {}
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '859b706a6168f69db70125a76f93cc64';
module.exports = node;
