// @flow

import React from 'react';
import { createFragmentContainer, graphql } from 'react-relay';
import { i18n } from 'shared/utils';
import EmptyListResults from 'shared/components/common/EmptyListResults';
import KeyValueGrid from 'shared/components/common/KeyValueGrid';
import Panel from 'shared/components/common/Panel';
import { renderCosmeticFieldValue } from 'shared/components/form/FormBuilder';
import type { RunTooling_job as JobFragment } from './__generated__/RunTooling_job';

type Props = {
  job: JobFragment,
};

const RunTooling = (props: Props) => {
  const { job } = props;

  const hasTooling = Boolean(job.linkedItems?.edges?.length);

  const content = hasTooling ? (
    (job.linkedItems.edges || []).map((edge: any) => {
      const { node } = edge;

      const fields = [
        {
          label: i18n.t('Inventory #'),
          value: node.itemNumber,
        },
        {
          label: i18n.t('UPC'),
          value: node.upc,
        },
        {
          label: i18n.t('Part #'),
          value: node.partNumber,
        },
        {
          label: i18n.t('Est. Usage'),
          value: `${edge.usageQty || 0} ${node?.quantityUOM?.symbol || ''}`,
        },
        {
          label: i18n.t('Category'),
          value: node.category?.name,
        },
        {
          label: i18n.t('Description'),
          value: node.description,
        },
      ].concat(
        node.form.fields.map(customField => ({
          label: customField.name,
          value: renderCosmeticFieldValue({
            formFieldType: customField.type,
            value: node.formFieldValues[customField.id] || '',
          }),
        })),
      );

      return (
        <Panel
          title={node.name}
          style={{ marginBottom: 16 }}
          key={edge?.node.id}
        >
          <KeyValueGrid singleColumn fields={fields} />
        </Panel>
      );
    })
  ) : (
    <Panel>
      <EmptyListResults
        graphic="items"
        message={i18n.t(
          'Tooling associated with the job ticket will display here. Contact your administrator if something is missing.',
        )}
      />
    </Panel>
  );

  return (
    <>
      <div style={{ paddingBottom: 16 }}>{content}</div>
    </>
  );
};

export default createFragmentContainer(RunTooling, {
  job: graphql`
    fragment RunTooling_job on Job {
      id
      importJobNumber
      jobNumber
      linkedItems {
        edges {
          usageQty
          node {
            id
            description
            itemNumber
            formFieldValues
            name
            partNumber
            upc
            category {
              id
              name
            }
            quantityUOM {
              id
              name
              symbol
            }
            form {
              id
              ...FormBuilder_form @relay(mask: false)
            }
          }
        }
      }
    }
  `,
});
