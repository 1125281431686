// @flow

import React from 'react';
import { createFragmentContainer, graphql } from 'react-relay';
import styled from 'styled-components';
import { colors, fonts } from 'shared/styleguide';
import UserAvatar from 'shared/components/common/UserAvatar';
import type { UserWidget_user as UserFragment } from './__generated__/UserWidget_user';

type Props = {
  title?: string,
  onClick?: string => void,
  style?: Object,
  user: UserFragment,
};

const Row = styled.div`
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  min-height: 30px;
`;

const Info = styled.div`
  padding-left: 8px;
  color: ${colors.charcoalGrey};
  ${fonts.bodyRegular};
`;

const Title = styled.div`
  ${fonts.smallRegular};
  color: ${colors.battleshipGrey};
  text-transform: uppercase;
  letter-spacing: 0.5px;
  padding-bottom: 6px;
  white-space: nowrap;
`;

const UserWidget = ({ title, onClick, style, user }: Props) => (
  <a
    href="#"
    style={style}
    onClick={() => {
      if (onClick) {
        onClick(user.id);
      }
    }}
  >
    {title && <Title>{title}</Title>}
    <Row>
      <UserAvatar user={user} />
      <Info>
        <div>{user.firstName}</div>
      </Info>
    </Row>
  </a>
);

UserWidget.defaultProps = {
  title: undefined,
  onClick: undefined,
  style: undefined,
};

export default createFragmentContainer(UserWidget, {
  user: graphql`
    fragment UserWidget_user on User {
      id
      firstName
      lastName
      ...UserAvatar_user
    }
  `,
});
