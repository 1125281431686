// @flow

import i18n from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import { initReactI18next } from 'react-i18next';
import numeral from 'numeral';

i18n
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    fallbackLng: 'en',

    // have a common namespace used around the full app
    ns: ['translations'],
    defaultNS: 'translations',

    // debug: config.env !== 'production',
    debug: false,

    // returnObjects: true, // probably don't need this anymore now that we're using words as keys

    // saveMissing: true,

    keySeparator: '```',
    nsSeparator: '[][][]',

    interpolation: {
      escapeValue: false, // not needed for react
      format: function(value, format, lng) {
        if (format === 'number') return numeral(value).format('0,0[.]0[00000]');
        return value;
      },
    },

    react: {
      wait: true,
    },

    // resources: {
    //   en: {
    //     translations: USEnglish,
    //   },
    // },
  });

const language = localStorage.getItem('language');

if (language) {
  i18n.changeLanguage(language);
}

export default i18n;
