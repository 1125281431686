// @flow

import styled from 'styled-components';
import { colors } from 'shared/styleguide';

export default styled.div`
  padding: 24px;
  border-top: 1px solid ${colors.lightBlueGrey};

  display: flex;
  flex-direction: column;

  button {
    margin-top: 16px;
    width: 100%;
  }

  @media (min-width: 1000px) {
    padding: 16px 24px;
    flex-direction: row;
    justify-content: flex-end;

    button {
      margin-top: unset;
      width: auto;
    }
  }
`;
