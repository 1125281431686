// @flow

import React from 'react';
import styled from 'styled-components';
import { Field } from 'formik';
import InfoTooltip from 'shared/components/common/InfoTooltip';
import Input from './Input';
import Select from './Select';
import Textarea from './Textarea';
import DatePicker from './DatePicker';
import DateRangePicker from './DateRangePicker';
import Toggle from './Toggle';
import FieldMessage from './FieldMessage';
import FieldLabel from './FieldLabel';
import FieldDescription from './FieldDescription';

type FieldType =
  | 'text'
  | 'email'
  | 'number'
  | 'select'
  | 'textarea'
  | 'date-range'
  | 'date'
  | 'toggle';

export type Props = {
  name: string,
  rawField?: boolean,
  type?: FieldType,
  label?: ?string,
  description?: ?string,
  error?: ?string,
  message?: string,
  style?: Object,
  sideLabel?: ?string,
  tooltip?: string | React$Node,
  selectOrderField?: boolean,
  ...*,
};

const RowWrapper = styled.div`
  display: flex;
  align-items: ${p => (p.sideLabel === 'right' ? 'center' : 'flex-start')};
  justify-content: ${p =>
    p.sideLabel === 'right' ? 'flex-start' : 'space-between'};
  padding: 0;
  padding-top: ${p => (p.sideLabel === 'right' ? '0' : '16px')};
  padding-bottom: ${p => (p.sideLabel === 'right' ? '0' : '8px')};

  label {
    padding-left: ${p => (p.sideLabel === 'right' ? '16px' : '0')};
    padding-bottom: 0;
  }
`;

const Wrapper = styled.div`
  position: relative;
  border: none;
  ${p =>
    p.sideLabel
      ? p.sideLabel === 'right'
        ? 'margin-right: auto;'
        : 'margin-left: auto;'
      : `
        padding: 0 0 16px 0;
        margin: 0;
        width: 100%;
  `}
`;

function getFieldComponent(type?: FieldType) {
  if (!type) {
    return Input;
  }

  if (type === 'select') {
    return Select;
  }

  if (type === 'textarea') {
    return Textarea;
  }

  if (type === 'date') {
    return DatePicker;
  }

  if (type === 'date-range') {
    return DateRangePicker;
  }

  if (type === 'toggle') {
    return Toggle;
  }

  return Input;
}

/**
 * This is a helper component that will render common combinations of
 * form inputs along with labels and errors. This component combines the usage
 * of <FieldLabel>, <FieldMessage>, and an formik-connected input field
 * component (i.e. <Select>, <Textarea>, <Field>, etc)
 *
 * @example
 *  <FieldGroup
 *    label="Update your Email Address"
 *    name="email"
 *    type="email"
 *    placeholder="johndoe@gmail.com"
 *    error={null}
 *  />
 * @example
 *  <FieldGroup
 *    label="Update Language Preference"
 *    name="language"
 *    type="select"
 *    options={[{ value: 'english', label: 'English' }, { value: 'spanish', label: 'Spanish' }]}
 *    error={null}
 *  />
 * @param {Object} props
 * @return {React$Node}
 */
const FieldGroup = ({
  name,
  rawField,
  type,
  label,
  description,
  error,
  message,
  style,
  sideLabel,
  tooltip,
  selectOrderField,
  ...fieldSpecificProps
}: Props) => {
  const FieldComponent = getFieldComponent(type);
  const fieldProps = {
    name,
    error,
    type,
    label,
    selectOrderField,
    ...fieldSpecificProps,
  };
  const FieldLabelComponent = label ? (
    <FieldLabel
      style={
        description
          ? {
              paddingBottom: 0,
            }
          : null
      }
    >
      {label}
      {tooltip && !sideLabel && (
        <InfoTooltip id={type + '-' + name} content={tooltip} />
      )}
    </FieldLabel>
  ) : null;

  const content = sideLabel ? (
    <>
      <RowWrapper sideLabel={sideLabel}>
        {sideLabel === 'left' && FieldLabelComponent}

        {tooltip && (
          <InfoTooltip
            id={type + '-' + name}
            content={tooltip}
            style={{ marginLeft: 'auto', marginRight: 16, alignSelf: 'center' }}
          />
        )}

        {rawField ? (
          <FieldComponent {...fieldProps} />
        ) : (
          <Field {...fieldProps} component={FieldComponent} />
        )}

        {sideLabel === 'right' && FieldLabelComponent}
      </RowWrapper>
      {description ? <FieldDescription>{description}</FieldDescription> : null}

      {error ? <FieldMessage type="error">{error}</FieldMessage> : null}

      {message ? <FieldMessage>{message}</FieldMessage> : null}
    </>
  ) : (
    <>
      {FieldLabelComponent}

      {description ? <FieldDescription>{description}</FieldDescription> : null}

      {rawField ? (
        <FieldComponent {...fieldProps} />
      ) : (
        <Field {...fieldProps} component={FieldComponent} />
      )}

      {error ? <FieldMessage type="error">{error}</FieldMessage> : null}

      {message ? <FieldMessage>{message}</FieldMessage> : null}
    </>
  );

  return (
    <Wrapper sideLabel={sideLabel} style={style}>
      {content}
    </Wrapper>
  );
};

FieldGroup.defaultProps = {
  rawField: false,
  type: 'text',
  label: null,
  description: null,
  error: null,
  message: undefined,
  style: undefined,
  sideLabel: null,
  tooltip: null,
  selectOrderField: false,
};

export default FieldGroup;
