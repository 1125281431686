// @flow

import React from 'react';
import styled from 'styled-components';
import ReactTooltip from 'react-tooltip';
import Icon from 'shared/components/common/Icon';

type Props = {
  id: string,
  content: string | React$Node,
  place?: string,
  style?: Object,
};

const Wrapper = styled.div`
  width: 18px;
`;

const IconWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

const InfoTooltip = ({ id, content, place, style }: Props) => (
  <Wrapper style={style}>
    <IconWrapper data-for={id} data-tip>
      <Icon type="circle-question" />
    </IconWrapper>
    <ReactTooltip place={place} effect="solid" type="info" id={id}>
      {content}
    </ReactTooltip>
  </Wrapper>
);

InfoTooltip.defaultProps = {
  place: 'top',
  style: {},
};

export default InfoTooltip;
