/**
 * @flow
 * @relayHash 0ca79f6c39eee56c8a4d0f19d0eab41a
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type UOMType = "LENGTH" | "QUANTITY" | "WEIGHT";
export type SelectUOMFieldQueryVariables = {|
  type?: ?UOMType
|};
export type SelectUOMFieldQueryResponse = {|
  +uoms: $ReadOnlyArray<{|
    +id: string,
    +name: string,
    +type: UOMType,
    +symbol: string,
  |}>
|};
export type SelectUOMFieldQuery = {|
  variables: SelectUOMFieldQueryVariables,
  response: SelectUOMFieldQueryResponse,
|};
*/


/*
query SelectUOMFieldQuery(
  $type: UOMType
) {
  uoms(type: $type) {
    id
    name
    type
    symbol
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "type",
    "type": "UOMType",
    "defaultValue": null
  }
],
v1 = [
  {
    "kind": "LinkedField",
    "alias": null,
    "name": "uoms",
    "storageKey": null,
    "args": [
      {
        "kind": "Variable",
        "name": "type",
        "variableName": "type"
      }
    ],
    "concreteType": "UOM",
    "plural": true,
    "selections": [
      {
        "kind": "ScalarField",
        "alias": null,
        "name": "id",
        "args": null,
        "storageKey": null
      },
      {
        "kind": "ScalarField",
        "alias": null,
        "name": "name",
        "args": null,
        "storageKey": null
      },
      {
        "kind": "ScalarField",
        "alias": null,
        "name": "type",
        "args": null,
        "storageKey": null
      },
      {
        "kind": "ScalarField",
        "alias": null,
        "name": "symbol",
        "args": null,
        "storageKey": null
      }
    ]
  }
];
return {
  "kind": "Request",
  "fragment": {
    "kind": "Fragment",
    "name": "SelectUOMFieldQuery",
    "type": "Query",
    "metadata": null,
    "argumentDefinitions": (v0/*: any*/),
    "selections": (v1/*: any*/)
  },
  "operation": {
    "kind": "Operation",
    "name": "SelectUOMFieldQuery",
    "argumentDefinitions": (v0/*: any*/),
    "selections": (v1/*: any*/)
  },
  "params": {
    "operationKind": "query",
    "name": "SelectUOMFieldQuery",
    "id": null,
    "text": "query SelectUOMFieldQuery(\n  $type: UOMType\n) {\n  uoms(type: $type) {\n    id\n    name\n    type\n    symbol\n  }\n}\n",
    "metadata": {}
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '65e3a3245ea1e636cacfd612b05e0647';
module.exports = node;
