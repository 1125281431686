// @flow

import React from 'react';
import moment from 'moment';
import qs from 'qs';
import type { RouterHistory } from 'react-router-dom';
import styled from 'styled-components';
import { colors, fonts } from 'shared/styleguide';
import { i18n } from 'shared/utils';
import Icon from 'shared/components/common/Icon';

type Props = {
  history: RouterHistory,
  date: moment$Moment,
};

const Wrapper = styled.div`
  display: flex;
  flex-flow: row nowrap;
  justify-content: center;
  align-items: center;
  min-height: 54px;

  @media (min-width: 800px) {
    justify-content: flex-start;
  }
`;

const Title = styled.div`
  ${fonts.headerBold};
  color: ${colors.charcoalGrey};
  flex: 1;
  display: none;

  @media (min-width: 800px) {
    display: block;
  }
`;

const DateLabel = styled.div`
  user-select: none;
  outline: none;
  margin: 0 16px;
  padding: 6px 16px;
  border: 1px solid ${colors.lightBlueGrey};
  border-radius: 20px;
  background-color: ${colors.paleGreyTwo};
  color: ${colors.charcoalGrey};
  ${fonts.largeBold};
`;

function changeDate(history, date, operation: 'add' | 'subtract') {
  const updatedDate =
    operation === 'add' ? date.add(1, 'day') : date.subtract(1, 'day');

  history.replace(
    '/runs?' +
      qs.stringify({
        date: updatedDate.format('YYYY-MM-DD'),
      }),
  );
}

export default ({ history, date }: Props) => {
  const dateLabel =
    moment().format('YYYY-MM-DD') === date.format('YYYY-MM-DD')
      ? 'Today'
      : date.format('ddd, MMM Do, YYYY');

  return (
    <Wrapper>
      <Title>
        {i18n.t('Runs {{date}}', {
          date: dateLabel,
        })}
      </Title>
      <Icon
        type="circle-arrow-left"
        size={32}
        onClick={() => changeDate(history, date, 'subtract')}
      />
      <DateLabel>{date.format('MMMM Do YYYY')}</DateLabel>
      <Icon
        type="circle-arrow-right"
        size={32}
        onClick={() => changeDate(history, date, 'add')}
      />
    </Wrapper>
  );
};
