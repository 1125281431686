// @flow

import {
  Environment,
  Network,
  RecordSource,
  Store,
  QueryResponseCache,
} from 'relay-runtime';
import { SubscriptionClient } from 'subscriptions-transport-ws';

const CACHE_MINUTES = 40000;

const cache = new QueryResponseCache({
  size: 1024,
  ttl: 60 * 1000 * CACHE_MINUTES,
});

function fetchQuery(operation, variables, cacheConfig, uploadables) {
  const queryID = operation.text;
  const isMutation = operation.operationKind === 'mutation';
  const isQuery = operation.operationKind === 'query';
  const forceFetch = cacheConfig && cacheConfig.force;
  const fromCache = cache.get(queryID, variables);

  if (isQuery && fromCache !== null && !forceFetch) {
    return fromCache;
  }

  const request: any = {
    method: 'POST',
    credentials: 'same-origin',
    headers: {},
  };

  if (uploadables) {
    if (!window.FormData) {
      throw new Error('Uploading files without `FormData` not supported.');
    }

    const formData = new FormData();
    formData.append('query', operation.text);
    formData.append('variables', JSON.stringify(variables));

    Object.keys(uploadables).forEach(key => {
      if (Object.prototype.hasOwnProperty.call(uploadables, key)) {
        formData.append(key, uploadables[key]);
      }
    });

    request.body = formData;
  } else {
    request.headers['Content-Type'] = 'application/json';
    request.body = JSON.stringify({
      query: operation.text,
      variables,
    });
  }

  return fetch('/graphql', request)
    .then(response => response.json())
    .then(json => {
      if (isQuery && json) {
        cache.set(queryID, variables, json);
      }

      // Clear cache on mutations
      if (isMutation) {
        cache.clear();
      }

      if (isMutation && json.errors) {
        return Promise.reject(json.errors);
      }

      return json;
    });
}

function setupSubscription(config, variables, cacheConfig, observer) {
  const query = config.text;

  const subscriptionClient = new SubscriptionClient(
    `ws://${window.location.host}/graphql`,
    {
      reconnect: true,
    },
  );

  const onNext = result => {
    observer.onNext(result);
  };

  const onError = error => {
    observer.onError(error);
  };

  const onComplete = () => {
    observer.onCompleted();
  };

  const client = subscriptionClient
    .request({ query, variables })
    .subscribe(onNext, onError, onComplete);

  return {
    dispose: client.unsubscribe,
  };
}

const environment = new Environment({
  network: Network.create(fetchQuery, setupSubscription),
  store: new Store(new RecordSource()),
});

export default environment;
