// @flow

import React from 'react';
import styled from 'styled-components';
import { colors, fonts } from 'shared/styleguide';

type Props = {
  children: React$Node,
};

const Wrapper = styled.label`
  ${fonts.bodyRegular}
  color: ${colors.battleshipGrey};
  display: block;
  padding-bottom: 8px;
`;

/**
 * Basic form field label that shows above most input fields
 * @example <FieldDescription>Email Address</FieldDescription>
 * @param {Object} props
 * @return {React$Node}
 */
export default ({ children }: Props) => <Wrapper>{children}</Wrapper>;
