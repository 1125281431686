/**
 * @flow
 * @relayHash 61edb8366d923e7f1f4527b05528b0db
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type SelectEventTypeFieldQueryVariables = {||};
export type SelectEventTypeFieldQueryResponse = {|
  +eventTypes: $ReadOnlyArray<{|
    +id: string,
    +name: string,
    +cosmeticName: string,
  |}>
|};
export type SelectEventTypeFieldQuery = {|
  variables: SelectEventTypeFieldQueryVariables,
  response: SelectEventTypeFieldQueryResponse,
|};
*/


/*
query SelectEventTypeFieldQuery {
  eventTypes {
    id
    name
    cosmeticName
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "kind": "LinkedField",
    "alias": null,
    "name": "eventTypes",
    "storageKey": null,
    "args": null,
    "concreteType": "EventType",
    "plural": true,
    "selections": [
      {
        "kind": "ScalarField",
        "alias": null,
        "name": "id",
        "args": null,
        "storageKey": null
      },
      {
        "kind": "ScalarField",
        "alias": null,
        "name": "name",
        "args": null,
        "storageKey": null
      },
      {
        "kind": "ScalarField",
        "alias": null,
        "name": "cosmeticName",
        "args": null,
        "storageKey": null
      }
    ]
  }
];
return {
  "kind": "Request",
  "fragment": {
    "kind": "Fragment",
    "name": "SelectEventTypeFieldQuery",
    "type": "Query",
    "metadata": null,
    "argumentDefinitions": [],
    "selections": (v0/*: any*/)
  },
  "operation": {
    "kind": "Operation",
    "name": "SelectEventTypeFieldQuery",
    "argumentDefinitions": [],
    "selections": (v0/*: any*/)
  },
  "params": {
    "operationKind": "query",
    "name": "SelectEventTypeFieldQuery",
    "id": null,
    "text": "query SelectEventTypeFieldQuery {\n  eventTypes {\n    id\n    name\n    cosmeticName\n  }\n}\n",
    "metadata": {}
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '89596e422db8603332e69110e58bd6fd';
module.exports = node;
