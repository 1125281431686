/**
 * @flow
 * @relayHash 0999aceefe162a133d290553f97ec6a3
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
type RefetchableRunsContainer_runs$ref = any;
export type RunsQueryVariables = {|
  startAt: any,
  endAt: any,
  machineIds: $ReadOnlyArray<string>,
|};
export type RunsQueryResponse = {|
  +runs: {|
    +$fragmentRefs: RefetchableRunsContainer_runs$ref
  |}
|};
export type RunsQuery = {|
  variables: RunsQueryVariables,
  response: RunsQueryResponse,
|};
*/


/*
query RunsQuery(
  $startAt: DateTime!
  $endAt: DateTime!
  $machineIds: [ID!]!
) {
  runs(startAt: $startAt, endAt: $endAt, machineIds: $machineIds, sortBy: {field: SCHEDULED_START_AT, direction: ASC}) {
    ...RefetchableRunsContainer_runs_20i6kk
  }
}

fragment RefetchableRunsContainer_runs_20i6kk on RunConnection {
  edges {
    node {
      id
      status
      scheduledStartAt
      scheduledEndAt
      jobState {
        id
        workflowState {
          id
          name
        }
      }
      job {
        id
        importJobNumber
        jobNumber
        name
        yields {
          totalCount
        }
        order {
          id
          customer {
            id
            name
          }
        }
      }
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "startAt",
    "type": "DateTime!",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "endAt",
    "type": "DateTime!",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "machineIds",
    "type": "[ID!]!",
    "defaultValue": null
  }
],
v1 = {
  "kind": "Variable",
  "name": "endAt",
  "variableName": "endAt"
},
v2 = {
  "kind": "Variable",
  "name": "machineIds",
  "variableName": "machineIds"
},
v3 = {
  "kind": "Variable",
  "name": "startAt",
  "variableName": "startAt"
},
v4 = [
  (v1/*: any*/),
  (v2/*: any*/),
  {
    "kind": "Literal",
    "name": "sortBy",
    "value": {
      "direction": "ASC",
      "field": "SCHEDULED_START_AT"
    }
  },
  (v3/*: any*/)
],
v5 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "id",
  "args": null,
  "storageKey": null
},
v6 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "name",
  "args": null,
  "storageKey": null
},
v7 = [
  (v5/*: any*/),
  (v6/*: any*/)
];
return {
  "kind": "Request",
  "fragment": {
    "kind": "Fragment",
    "name": "RunsQuery",
    "type": "Query",
    "metadata": null,
    "argumentDefinitions": (v0/*: any*/),
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "runs",
        "storageKey": null,
        "args": (v4/*: any*/),
        "concreteType": "RunConnection",
        "plural": false,
        "selections": [
          {
            "kind": "FragmentSpread",
            "name": "RefetchableRunsContainer_runs",
            "args": [
              (v1/*: any*/),
              (v2/*: any*/),
              (v3/*: any*/)
            ]
          }
        ]
      }
    ]
  },
  "operation": {
    "kind": "Operation",
    "name": "RunsQuery",
    "argumentDefinitions": (v0/*: any*/),
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "runs",
        "storageKey": null,
        "args": (v4/*: any*/),
        "concreteType": "RunConnection",
        "plural": false,
        "selections": [
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "edges",
            "storageKey": null,
            "args": null,
            "concreteType": "RunEdge",
            "plural": true,
            "selections": [
              {
                "kind": "LinkedField",
                "alias": null,
                "name": "node",
                "storageKey": null,
                "args": null,
                "concreteType": "Run",
                "plural": false,
                "selections": [
                  (v5/*: any*/),
                  {
                    "kind": "ScalarField",
                    "alias": null,
                    "name": "status",
                    "args": null,
                    "storageKey": null
                  },
                  {
                    "kind": "ScalarField",
                    "alias": null,
                    "name": "scheduledStartAt",
                    "args": null,
                    "storageKey": null
                  },
                  {
                    "kind": "ScalarField",
                    "alias": null,
                    "name": "scheduledEndAt",
                    "args": null,
                    "storageKey": null
                  },
                  {
                    "kind": "LinkedField",
                    "alias": null,
                    "name": "jobState",
                    "storageKey": null,
                    "args": null,
                    "concreteType": "JobState",
                    "plural": false,
                    "selections": [
                      (v5/*: any*/),
                      {
                        "kind": "LinkedField",
                        "alias": null,
                        "name": "workflowState",
                        "storageKey": null,
                        "args": null,
                        "concreteType": "WorkflowState",
                        "plural": false,
                        "selections": (v7/*: any*/)
                      }
                    ]
                  },
                  {
                    "kind": "LinkedField",
                    "alias": null,
                    "name": "job",
                    "storageKey": null,
                    "args": null,
                    "concreteType": "Job",
                    "plural": false,
                    "selections": [
                      (v5/*: any*/),
                      {
                        "kind": "ScalarField",
                        "alias": null,
                        "name": "importJobNumber",
                        "args": null,
                        "storageKey": null
                      },
                      {
                        "kind": "ScalarField",
                        "alias": null,
                        "name": "jobNumber",
                        "args": null,
                        "storageKey": null
                      },
                      (v6/*: any*/),
                      {
                        "kind": "LinkedField",
                        "alias": null,
                        "name": "yields",
                        "storageKey": null,
                        "args": null,
                        "concreteType": "JobYieldConnection",
                        "plural": false,
                        "selections": [
                          {
                            "kind": "ScalarField",
                            "alias": null,
                            "name": "totalCount",
                            "args": null,
                            "storageKey": null
                          }
                        ]
                      },
                      {
                        "kind": "LinkedField",
                        "alias": null,
                        "name": "order",
                        "storageKey": null,
                        "args": null,
                        "concreteType": "Order",
                        "plural": false,
                        "selections": [
                          (v5/*: any*/),
                          {
                            "kind": "LinkedField",
                            "alias": null,
                            "name": "customer",
                            "storageKey": null,
                            "args": null,
                            "concreteType": "Customer",
                            "plural": false,
                            "selections": (v7/*: any*/)
                          }
                        ]
                      }
                    ]
                  }
                ]
              }
            ]
          }
        ]
      }
    ]
  },
  "params": {
    "operationKind": "query",
    "name": "RunsQuery",
    "id": null,
    "text": "query RunsQuery(\n  $startAt: DateTime!\n  $endAt: DateTime!\n  $machineIds: [ID!]!\n) {\n  runs(startAt: $startAt, endAt: $endAt, machineIds: $machineIds, sortBy: {field: SCHEDULED_START_AT, direction: ASC}) {\n    ...RefetchableRunsContainer_runs_20i6kk\n  }\n}\n\nfragment RefetchableRunsContainer_runs_20i6kk on RunConnection {\n  edges {\n    node {\n      id\n      status\n      scheduledStartAt\n      scheduledEndAt\n      jobState {\n        id\n        workflowState {\n          id\n          name\n        }\n      }\n      job {\n        id\n        importJobNumber\n        jobNumber\n        name\n        yields {\n          totalCount\n        }\n        order {\n          id\n          customer {\n            id\n            name\n          }\n        }\n      }\n    }\n  }\n}\n",
    "metadata": {}
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = 'c4a5a4401a6c9d958ba66f03f6b0594c';
module.exports = node;
