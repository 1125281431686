// @flow

import React, { PureComponent } from 'react';
import moment from 'moment';

type Props = {
  format: string,
  intervalSeconds: number,
};

type State = {
  time: string,
};

class DateTimePicker extends PureComponent<Props, State> {
  ticker = null;

  constructor(props: Props) {
    super(props);

    this.state = {
      time: moment().format(props.format),
    };
  }

  componentDidMount() {
    const { intervalSeconds } = this.props;

    this.ticker = setInterval(() => this.tick(), intervalSeconds * 1000);
  }

  componentWillUnmount() {
    if (this.ticker) {
      clearInterval(this.ticker);
    }
  }

  tick() {
    const { format } = this.props;

    this.setState({
      time: moment().format(format),
    });
  }

  render() {
    const { time } = this.state;

    return <React.Fragment>{time}</React.Fragment>;
  }
}

export default DateTimePicker;
