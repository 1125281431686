// @flow

import React from 'react';
import styled, { createGlobalStyle } from 'styled-components';
import { colors } from 'shared/styleguide';

// FIXME: this by itself is not sufficient. Safari will scroll the user back up to the top. Need to implement a fix (i.e. https://stackoverflow.com/a/45230674/1525109 - can be done on componentDidMount and componentWillUnmount)
const DisableBodyScroll = createGlobalStyle`
  body {
    overflow: hidden;
  }
`;

const Background = styled.div`
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: flex;
  align-items: center;
  background-color: rgba(66, 68, 76, 0.6);
  padding: 0;
  z-index: 2; /* FIXME: We don't use z-index in this codebase. But the react-calendar-timeline package has forced us to do this. Need to address this later */

  overflow-y: auto;
  -webkit-overflow-scrolling: touch;

  @media (min-width: 1000px) {
    padding: 32px 0;
  }
`;

const ModalWrapper = styled.div`
  margin: auto;
  width: 100%;
  background-color: ${colors.white};
  border-radius: 5px;
  border: 1px solid ${colors.lightBlueGrey};

  @media (min-width: 1000px) {
    max-width: ${p => (p.maxWidth ? `${p.maxWidth}px` : 'auto')};
  }
`;

type Props = {
  maxWidth?: number,
  getRef: (*) => *,
  children: React$Node,
};

const ModalElement = ({ maxWidth, getRef, children }: Props) => (
  <Background>
    <ModalWrapper ref={getRef} maxWidth={maxWidth}>
      {children}
    </ModalWrapper>
    <DisableBodyScroll />
  </Background>
);

ModalElement.defaultProps = {
  maxWidth: undefined,
};

export default ModalElement;
