// @flow

import React from 'react';
import styled from 'styled-components';
import { colors, fonts, helpers } from 'shared/styleguide';
import { transparentize } from 'polished';

const Wrapper = styled.div`
  display: inline-block;
  padding: 8px 16px;
  height: 32px;
  border-radius: 100px;
  text-align: center;
  ${fonts.smallBold}
  background-color: ${p => transparentize(0.9, getAccentColor(p.theme))};
  color: ${p => getAccentColor(p.theme)};
  width: ${helpers.widthProp};
  
  &:hover {
    cursor: ${p => (p.onClick ? 'pointer' : 'default')};
  }
`;

function getAccentColor(theme) {
  switch (theme) {
    case 'blue':
      return colors.azul;
    case 'green':
      return colors.greenBlue;
    case 'red':
      return colors.cherry;
    case 'yellow':
      return colors.brownishOrange;
  }

  return colors.battleshipGrey;
}

type Props = {
  children: React$Node,
  onClick?: () => void,
  theme?: 'blue' | 'green' | 'red' | 'yellow' | 'default',
  width?: string | number,
};

const Pill = ({ theme, onClick, width, children }: Props) => (
  <Wrapper theme={theme} onClick={onClick} width={width}>
    {children}
  </Wrapper>
);

Pill.defaultProps = {
  onClick: undefined,
  theme: 'default',
  width: undefined,
};

export default Pill;
