// @flow

/* eslint-disable react/no-array-index-key */

import React from 'react';

type Props = {
  children: ?string,
};

export default ({ children }: Props) => (
  <>
    {(children || '').split('\n').map((str, i) => (
      <React.Fragment key={i}>
        {str}
        <br />
      </React.Fragment>
    ))}
  </>
);
