// @flow

import { graphql } from 'react-relay';
import createRelayMutation from 'shared/gql/createRelayMutation';

const mutation = graphql`
  mutation UpdateMachineDowntimeStartMutation(
    $input: UpdateMachineDowntimeStartInput!
  ) {
    updateMachineDowntimeStart(input: $input) {
      machineEdge {
        cursor
        node {
          id
          name
          currentDowntime {
            id
            startNotes
            endNotes
            startAt
            endAt
            startUser {
              id
              firstName
              lastName
              avatar
            }
            endUser {
              id
              firstName
              lastName
              avatar
            }
            category {
              id
              name
            }
          }
        }
      }
    }
  }
`;

export default {
  commit: createRelayMutation.bind(null, mutation),
};
