// @flow

import React from 'react';
import { localizeEnum } from 'shared/utils';
import Pill from '.';

type Props = {
  width?: string | number,
  status: 'SCHEDULED' | 'IN_PROGRESS' | 'PAUSED' | 'COMPLETE',
};

const ThemeStatusMap = {
  SCHEDULED: 'default',
  PAUSED: 'default',
  IN_PROGRESS: 'blue',
  COMPLETE: 'green',
};

const RunStatusPill = ({ status, width }: Props) => (
  <Pill theme={ThemeStatusMap[status]} width={width}>
    {localizeEnum(status)}
  </Pill>
);

RunStatusPill.defaultProps = {
  width: undefined,
};

export default RunStatusPill;
