// @flow

import React, { PureComponent } from 'react';
import { withRouter } from 'react-router-dom';
import type { RouterHistory } from 'react-router';
import { connect } from 'react-redux';
import request from 'superagent';
import styled from 'styled-components';
import { LocalStorage, i18n } from 'shared/utils';
import { colors, fonts } from 'shared/styleguide';
import * as Actions from 'operator-portal/store/Actions';
import Button from 'shared/components/common/Button';
import Icon from 'shared/components/common/Icon';
import TimeTicker from 'shared/components/common/TimeTicker';
import SelectedMachine from './SelectedMachine';

type Props = {
  history: RouterHistory,
  mainMenuOpen: boolean,
  onStartDowntime: () => void,
  showDowntimeButton: boolean,
};

type State = {
  logoutEnabled: boolean,
};

const Wrapper = styled.div`
  position: fixed;
  top: 0;
  right: 0;
  max-width: 250px;
  width: 100%;
  height: 100%;
  -webkit-overflow-scrolling: touch;
  background: ${colors.charcoalGrey};
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 16px;

  transition: transform 300ms ease;
  transform: translateX(${p => (p.show ? 0 : '100%')});
  color: ${colors.white};
  ${fonts.bodyRegular};

  @media (min-width: 800px) {
    position: static;
    height: auto;
    min-height: 65px;
    width: 100%;
    max-width: 100%;
    transition: none;
    transform: none;
    flex-flow: row nowrap;
    align-items: center;
    justify-content: flex-start;
    padding: 16px 24px;
  }
`;

const Label = styled.div`
  ${fonts.bodyBold};
  padding-bottom: 8px;

  @media (min-width: 800px) {
    padding-bottom: 0;
    padding-right: 8px;
  }
`;

const Value = styled.div`
  padding-bottom: 32px;

  @media (min-width: 800px) {
    padding-bottom: 0;
    padding-right: 24px;
  }
`;

const CloseMenuWrapper = styled.div`
  align-self: flex-end;
  padding-bottom: 24px;

  @media (min-width: 800px) {
    display: none;
  }
`;

const LogoutWrapper = styled.div`
  align-self: flex-end;

  @media (min-width: 800px) {
    align-self: auto;
  }
`;

const InvisibleLogoutButton = styled.div`
  width: 32px;
  height: 32px;
  background-color: rgba(255, 255, 255, 0.05);
`;

class OperatorMenu extends PureComponent<Props, State> {
  wrapperRef = null;

  state = {
    logoutEnabled: false,
  };

  componentDidMount() {
    document.addEventListener('mousedown', this.handleMenuClickAway);
    document.addEventListener('touchend', this.handleMenuClickAway);
  }

  componentWillUnmount() {
    document.removeEventListener('mousedown', this.handleMenuClickAway);
    document.removeEventListener('touchend', this.handleMenuClickAway);
  }

  handleEnableLogout = (e: any) => {
    // Only allow triple clicks
    if (e.detail === 3) {
      this.setState({
        logoutEnabled: true,
      });
    }
  };

  handleLogout = async () => {
    const logoutConfirm = window.confirm(
      'Are you sure you want to log out? THIS IS NOT RECOMMENDED!',
    );

    if (!logoutConfirm) {
      return;
    }

    await request.delete('/api/v1/session');

    LocalStorage.clear();

    window.location = '/'; // eslint-disable-line require-atomic-updates
  };

  handleMenuClickAway = (e: Event) => {
    const { mainMenuOpen } = this.props;

    if (
      mainMenuOpen &&
      this.wrapperRef &&
      !this.wrapperRef.contains(e.target)
    ) {
      Actions.toggleMainMenu(false);
    }
  };

  handleCloseMenu = () => {
    Actions.toggleMainMenu(false);
  };

  handleSelectMachine = () => {
    const { history } = this.props;

    const confirmChange = window.confirm(
      i18n.t('Are you sure you want to switch to a different work center?'),
    );

    if (!confirmChange) {
      return;
    }

    Actions.setOperatorMachineId(null);

    history.push(`/choose-machine`);
  };

  render() {
    const { mainMenuOpen, onStartDowntime, showDowntimeButton } = this.props;
    const { logoutEnabled } = this.state;

    return (
      <>
        <Wrapper show={mainMenuOpen} ref={r => (this.wrapperRef = r)}>
          <CloseMenuWrapper>
            <Icon
              onClick={this.handleCloseMenu}
              type="circle-close"
              size={32}
            />
          </CloseMenuWrapper>
          <Label>{i18n.t('Work Center')}</Label>
          <Value>
            <SelectedMachine onClick={this.handleSelectMachine} />
          </Value>
          <Label>{i18n.t('Date')}</Label>
          <Value>
            <TimeTicker intervalSeconds={3600} format="MMM D, YYYY" />
          </Value>
          <Label>{i18n.t('Time')}</Label>
          <Value>
            <TimeTicker intervalSeconds={1} format="hh:mm:ss A" />
          </Value>
          {showDowntimeButton && (
            <Value>
              <Button theme="border-white" onClick={onStartDowntime}>
                {i18n.t('Track Downtime')}
              </Button>
            </Value>
          )}
          <div style={{ flex: 1 }} />
          <LogoutWrapper>
            {logoutEnabled ? (
              <Icon
                type="circle-logout"
                size={32}
                onClick={(): any => this.handleLogout()}
              />
            ) : (
              <InvisibleLogoutButton onClick={this.handleEnableLogout} />
            )}
          </LogoutWrapper>
        </Wrapper>
      </>
    );
  }
}

export default withRouter(
  connect(state => ({
    mainMenuOpen: state.mainMenuOpen,
  }))(OperatorMenu),
);
