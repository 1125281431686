// @flow

import React, { PureComponent } from 'react';
import ReactToggle from 'react-toggle';
import styled from 'styled-components';
import type { FormikProps, FieldProps } from 'formik';
import { colors } from 'shared/styleguide';
import { transparentize } from 'polished';

type Props = {
  form?: FormikProps<boolean>,
  field?: $PropertyType<FieldProps, 'field'>,
  value?: boolean,
  disabled?: boolean,
  onChange?: boolean => any,
};

const ToggleStyled = styled(ReactToggle)`
  &.react-toggle--checked {
    .react-toggle-track {
      background: ${colors.greenBlue};
    }
    .react-toggle-thumb {
      left: 26px;
    }
  }

  &.react-toggle--focus {
    .react-toggle-thumb {
      box-shadow: none;
    }
  }

  .react-toggle-track {
    height: 25px;
    border: solid 1.5px rgba(0, 0, 0, 0.1);
    background: ${colors.paleGrey};
  }

  &:hover {
    &.react-toggle--checked {
      .react-toggle-track {
        background: ${transparentize(0.2, colors.greenBlue)} !important;
      }
    }
    .react-toggle-track {
      background: ${colors.white} !important;
    }
  }

  &:active {
    .react-toggle-thumb {
      box-shadow: none !important;
    }
  }

  .react-toggle-thumb {
    width: 23px;
    height: 23px;
    border: solid 1px rgba(0, 0, 0, 0.2);
  }
`;

class Toggle extends PureComponent<Props> {
  static defaultProps = {
    form: undefined,
    field: undefined,
    value: undefined,
    disabled: undefined,
    onChange: () => {},
  };

  handleChange = (e: any) => {
    const { form, field, onChange } = this.props;
    const checked = e.target.checked;

    if (form && field) {
      form.setFieldValue(field.name, checked);
    }

    if (onChange) {
      onChange(checked);
    }
  };

  render() {
    const { value, field, disabled } = this.props;

    const toggleProps: any = {
      icons: false,
      disabled,
      onChange: this.handleChange,
    };

    const checked = value !== undefined ? value : field?.value;

    if (checked !== undefined) {
      toggleProps.checked = checked;
    }

    return <ToggleStyled {...toggleProps} />;
  }
}

export default Toggle;
