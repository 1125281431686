// @flow

import React from 'react';
import styled from 'styled-components';
import { colors, fonts } from 'shared/styleguide';

type Props = {
  children: React$Node,
  onClick: () => void,
  theme?: 'blue' | 'red' | 'white',
  disabled?: boolean,
  loading?: boolean,
  type?: 'button' | 'submit' | 'reset',
  thin?: boolean,
};

function getBackgroundColor(props) {
  switch (props.theme) {
    case 'blue':
      return colors.sapphire;
    case 'white':
      return colors.white;
  }

  return colors.paleRed;
}

const StyledButton = styled.button`
  background-color: ${p => getBackgroundColor(p)};
  border-radius: 10px;
  color: ${p => (p.theme === 'white' ? colors.charcoalGrey : colors.white)};
  ${fonts.headerBold};
  text-align: center;
  padding: ${p => (p.thin ? '16px 32px' : '32px 60px')};
  text-transform: uppercase;
  width: 100%;
  opacity: ${p => (p.disabled ? 0.4 : 1)};
  border: 1px solid ${p => (p.theme === 'white' ? colors.paleGrey : '')};
  box-shadow: ${p =>
    p.theme === 'white' ? `0 0 6px 0 rgba(66, 68, 76, 0.3)` : 'none'};

  @media (min-width: 800px) {
    &:hover {
      cursor: ${p => (p.disabled ? 'auto' : 'pointer')};
    }
  }
`;

const FatButton = ({
  children,
  onClick,
  theme,
  disabled,
  loading,
  type,
  thin,
}: Props) => (
  <StyledButton
    theme={theme}
    onClick={onClick}
    disabled={loading || disabled}
    type={type}
    thin={thin}
  >
    {children}
  </StyledButton>
);

FatButton.defaultProps = {
  theme: undefined,
  disabled: undefined,
  loading: undefined,
  type: undefined,
  thin: undefined,
};

export default FatButton;
