/**
 * @flow
 * @relayHash d7b95c916ea78e149cca87f027b286cf
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type MachineLabelQueryVariables = {|
  machineId: string
|};
export type MachineLabelQueryResponse = {|
  +machine: {|
    +id: string,
    +name: string,
  |}
|};
export type MachineLabelQuery = {|
  variables: MachineLabelQueryVariables,
  response: MachineLabelQueryResponse,
|};
*/


/*
query MachineLabelQuery(
  $machineId: ID!
) {
  machine(id: $machineId) {
    id
    name
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "machineId",
    "type": "ID!",
    "defaultValue": null
  }
],
v1 = [
  {
    "kind": "LinkedField",
    "alias": null,
    "name": "machine",
    "storageKey": null,
    "args": [
      {
        "kind": "Variable",
        "name": "id",
        "variableName": "machineId"
      }
    ],
    "concreteType": "Machine",
    "plural": false,
    "selections": [
      {
        "kind": "ScalarField",
        "alias": null,
        "name": "id",
        "args": null,
        "storageKey": null
      },
      {
        "kind": "ScalarField",
        "alias": null,
        "name": "name",
        "args": null,
        "storageKey": null
      }
    ]
  }
];
return {
  "kind": "Request",
  "fragment": {
    "kind": "Fragment",
    "name": "MachineLabelQuery",
    "type": "Query",
    "metadata": null,
    "argumentDefinitions": (v0/*: any*/),
    "selections": (v1/*: any*/)
  },
  "operation": {
    "kind": "Operation",
    "name": "MachineLabelQuery",
    "argumentDefinitions": (v0/*: any*/),
    "selections": (v1/*: any*/)
  },
  "params": {
    "operationKind": "query",
    "name": "MachineLabelQuery",
    "id": null,
    "text": "query MachineLabelQuery(\n  $machineId: ID!\n) {\n  machine(id: $machineId) {\n    id\n    name\n  }\n}\n",
    "metadata": {}
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '037112168927948134ac158450d315c4';
module.exports = node;
