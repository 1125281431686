/**
 * @flow
 * @relayHash 9109e4e0d317037aebd7f6d7ffb5e744
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type OrderSortField = "CREATED_AT" | "CUSTOMER" | "CUSTOMER_ORDER_NUMBER" | "ORDERED_AT" | "ORDER_NUMBER" | "PROMISED_AT" | "REQUIRED_AT" | "STATUS" | "UPDATED_AT";
export type SortDirection = "ASC" | "DESC";
export type OrderSort = {|
  field: OrderSortField,
  direction: SortDirection,
|};
export type SelectOrderFieldQueryVariables = {|
  sortBy?: ?OrderSort,
  customerIds?: ?$ReadOnlyArray<string>,
  search?: ?string,
  orderIds?: ?$ReadOnlyArray<string>,
|};
export type SelectOrderFieldQueryResponse = {|
  +orders: {|
    +edges: ?$ReadOnlyArray<?{|
      +node: {|
        +id: string,
        +orderNumber: string,
        +customer: {|
          +id: string,
          +name: string,
        |},
        +yields: {|
          +edges: ?$ReadOnlyArray<?{|
            +node: {|
              +id: string,
              +orderedQuantity: number,
              +item: {|
                +id: string,
                +name: string,
                +quantityUOM: {|
                  +id: string,
                  +symbol: string,
                |},
              |},
            |}
          |}>
        |},
      |}
    |}>
  |}
|};
export type SelectOrderFieldQuery = {|
  variables: SelectOrderFieldQueryVariables,
  response: SelectOrderFieldQueryResponse,
|};
*/


/*
query SelectOrderFieldQuery(
  $sortBy: OrderSort
  $customerIds: [ID!]
  $search: String
  $orderIds: [ID!]
) {
  orders(first: 50, sortBy: $sortBy, customerIds: $customerIds, search: $search, orderIds: $orderIds) {
    edges {
      node {
        id
        orderNumber
        customer {
          id
          name
        }
        yields {
          edges {
            node {
              id
              orderedQuantity
              item {
                id
                name
                quantityUOM {
                  id
                  symbol
                }
              }
            }
          }
        }
      }
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "sortBy",
    "type": "OrderSort",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "customerIds",
    "type": "[ID!]",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "search",
    "type": "String",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "orderIds",
    "type": "[ID!]",
    "defaultValue": null
  }
],
v1 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "id",
  "args": null,
  "storageKey": null
},
v2 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "name",
  "args": null,
  "storageKey": null
},
v3 = [
  {
    "kind": "LinkedField",
    "alias": null,
    "name": "orders",
    "storageKey": null,
    "args": [
      {
        "kind": "Variable",
        "name": "customerIds",
        "variableName": "customerIds"
      },
      {
        "kind": "Literal",
        "name": "first",
        "value": 50
      },
      {
        "kind": "Variable",
        "name": "orderIds",
        "variableName": "orderIds"
      },
      {
        "kind": "Variable",
        "name": "search",
        "variableName": "search"
      },
      {
        "kind": "Variable",
        "name": "sortBy",
        "variableName": "sortBy"
      }
    ],
    "concreteType": "OrderConnection",
    "plural": false,
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "edges",
        "storageKey": null,
        "args": null,
        "concreteType": "OrderEdge",
        "plural": true,
        "selections": [
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "node",
            "storageKey": null,
            "args": null,
            "concreteType": "Order",
            "plural": false,
            "selections": [
              (v1/*: any*/),
              {
                "kind": "ScalarField",
                "alias": null,
                "name": "orderNumber",
                "args": null,
                "storageKey": null
              },
              {
                "kind": "LinkedField",
                "alias": null,
                "name": "customer",
                "storageKey": null,
                "args": null,
                "concreteType": "Customer",
                "plural": false,
                "selections": [
                  (v1/*: any*/),
                  (v2/*: any*/)
                ]
              },
              {
                "kind": "LinkedField",
                "alias": null,
                "name": "yields",
                "storageKey": null,
                "args": null,
                "concreteType": "OrderYieldConnection",
                "plural": false,
                "selections": [
                  {
                    "kind": "LinkedField",
                    "alias": null,
                    "name": "edges",
                    "storageKey": null,
                    "args": null,
                    "concreteType": "OrderYieldEdge",
                    "plural": true,
                    "selections": [
                      {
                        "kind": "LinkedField",
                        "alias": null,
                        "name": "node",
                        "storageKey": null,
                        "args": null,
                        "concreteType": "OrderYield",
                        "plural": false,
                        "selections": [
                          (v1/*: any*/),
                          {
                            "kind": "ScalarField",
                            "alias": null,
                            "name": "orderedQuantity",
                            "args": null,
                            "storageKey": null
                          },
                          {
                            "kind": "LinkedField",
                            "alias": null,
                            "name": "item",
                            "storageKey": null,
                            "args": null,
                            "concreteType": "Item",
                            "plural": false,
                            "selections": [
                              (v1/*: any*/),
                              (v2/*: any*/),
                              {
                                "kind": "LinkedField",
                                "alias": null,
                                "name": "quantityUOM",
                                "storageKey": null,
                                "args": null,
                                "concreteType": "UOM",
                                "plural": false,
                                "selections": [
                                  (v1/*: any*/),
                                  {
                                    "kind": "ScalarField",
                                    "alias": null,
                                    "name": "symbol",
                                    "args": null,
                                    "storageKey": null
                                  }
                                ]
                              }
                            ]
                          }
                        ]
                      }
                    ]
                  }
                ]
              }
            ]
          }
        ]
      }
    ]
  }
];
return {
  "kind": "Request",
  "fragment": {
    "kind": "Fragment",
    "name": "SelectOrderFieldQuery",
    "type": "Query",
    "metadata": null,
    "argumentDefinitions": (v0/*: any*/),
    "selections": (v3/*: any*/)
  },
  "operation": {
    "kind": "Operation",
    "name": "SelectOrderFieldQuery",
    "argumentDefinitions": (v0/*: any*/),
    "selections": (v3/*: any*/)
  },
  "params": {
    "operationKind": "query",
    "name": "SelectOrderFieldQuery",
    "id": null,
    "text": "query SelectOrderFieldQuery(\n  $sortBy: OrderSort\n  $customerIds: [ID!]\n  $search: String\n  $orderIds: [ID!]\n) {\n  orders(first: 50, sortBy: $sortBy, customerIds: $customerIds, search: $search, orderIds: $orderIds) {\n    edges {\n      node {\n        id\n        orderNumber\n        customer {\n          id\n          name\n        }\n        yields {\n          edges {\n            node {\n              id\n              orderedQuantity\n              item {\n                id\n                name\n                quantityUOM {\n                  id\n                  symbol\n                }\n              }\n            }\n          }\n        }\n      }\n    }\n  }\n}\n",
    "metadata": {}
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '8540d2aee15ce05f9d7ad72b9e7f9f98';
module.exports = node;
