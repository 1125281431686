// @flow

import React from 'react';
import { createFragmentContainer, graphql } from 'react-relay';
import styled from 'styled-components';
import { colors, fonts } from 'shared/styleguide';
import UserAvatar from 'shared/components/common/UserAvatar';
import TimeElapsedTicker from 'shared/components/common/TimeElapsedTicker';
import type { ProgressPanel_user as UserFragment } from './__generated__/ProgressPanel_user';

type Props = {
  user: UserFragment,
  startTime: moment$Moment,
  endTime?: moment$Moment,
  label: string,
  onClick?: () => void,
};

const Wrapper = styled.div`
  border-radius: 10px;
  background-color: ${colors.charcoalGrey};
  padding: 16px 16px 64px 16px;
  display: flex;
  flex-direction: column;
  color: ${colors.white};

  @media (min-width: 1000px) {
    &:hover {
      cursor: ${p => (p.onClick ? 'pointer' : 'default')};
    }
  }
`;

const Header = styled.div`
  align-self: flex-start;
  display: flex;
  flex-flow: row nowrap;
  justify-content: flex-start;
  align-items: center;
  ${fonts.bodyBold};
  color: ${colors.white};
  padding-bottom: 45px;
`;

const TickerWrapper = styled.div`
  flex: 1;
  align-self: center;
`;

const TickerLabel = styled.div`
  ${fonts.headerBold};
  padding-bottom: 16px;
  text-transform: uppercase;
  text-align: center;
`;

const Ticker = styled.div`
  font-size: 48px;
  font-weight: ${fonts.tickerBold};
  text-align: center;
`;

const ProgressPanel = ({ user, label, startTime, endTime, onClick }: Props) => (
  <Wrapper onClick={onClick}>
    {user && (
      <Header>
        <UserAvatar user={user} style={{ marginRight: 8 }} />
        {user.firstName} {user.lastName}
      </Header>
    )}
    <TickerWrapper>
      <TickerLabel>{label}</TickerLabel>
      <Ticker>
        <TimeElapsedTicker startTime={startTime} endTime={endTime} />
      </Ticker>
    </TickerWrapper>
  </Wrapper>
);

ProgressPanel.defaultProps = {
  endTime: undefined,
  onClick: undefined,
};

export default createFragmentContainer(ProgressPanel, {
  user: graphql`
    fragment ProgressPanel_user on User {
      id
      firstName
      lastName
      avatar
      ...UserAvatar_user
    }
  `,
});
