// @flow

import React, { PureComponent } from 'react';
import styled from 'styled-components';
import Icon from 'shared/components/common/Icon';
import type { FormikProps, FieldProps } from 'formik';
import DatePicker from './DatePicker';

type Value = {
  startDate: ?moment$Moment,
  endDate: ?moment$Moment,
};

type Props = {
  form?: FormikProps<moment$Moment>,
  field?: $PropertyType<FieldProps, 'field'>,
  error?: string,
  value: Value,
  onChange?: ({ startDate: ?moment$Moment, endDate: ?moment$Moment }) => void,
  disableKeyboardInput?: boolean,
};

const Wrapper = styled.div`
  display: flex;
`;

class DateRangePicker extends PureComponent<Props> {
  static defaultProps = {
    form: undefined,
    field: undefined,
    error: undefined,
    onChange: undefined,
    disableKeyboardInput: true,
  };

  handleChange = (newValue: Value) => {
    const { form, field, onChange } = this.props;

    if (form && field) {
      form.setFieldValue(field.name, newValue);
    }

    if (onChange) {
      onChange(newValue);
    }
  };

  render() {
    const { field, value, error, disableKeyboardInput } = this.props;
    const currentValue = (value !== undefined ? value : field?.value) || {};

    return (
      <Wrapper error={error}>
        <DatePicker
          value={currentValue.startDate}
          onChange={startDate =>
            this.handleChange({
              startDate,
              endDate: currentValue.endDate,
            })
          }
          selectsStart
          allowPastDates
          maxDate={currentValue.endDate}
          disableKeyboardInput={disableKeyboardInput}
        />
        <Icon
          type="right-arrow"
          size={20}
          style={{
            margin: 'auto 8',
          }}
        />
        <DatePicker
          value={currentValue.endDate}
          onChange={endDate =>
            this.handleChange({
              endDate,
              startDate: currentValue.startDate,
            })
          }
          selectsEnd
          minDate={currentValue.startDate}
          disableKeyboardInput={disableKeyboardInput}
        />
      </Wrapper>
    );
  }
}

export default DateRangePicker;
