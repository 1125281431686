// @flow

import 'react-toggle/style.css';
import 'react-table/react-table.css';
import 'react-datasheet/lib/react-datasheet.css';
import 'react-datepicker/dist/react-datepicker.css';
import 'storm-react-diagrams/dist/style.min.css';
import 'react-sortable-tree/style.css';

import { createGlobalStyle } from 'styled-components';
import { colors, fonts, linkStyles } from 'shared/styleguide';

import './react-big-calendar.css';
import './react-calendar-timeline.css';

// FIXME: This is necessary because styled-components are re-fetching the custom fonts on every re-render
// More info here: https://github.com/styled-components/styled-components/issues/1593
import './fonts.css';

// GLOBAL LAYOUT-WIDE STYLES
export default createGlobalStyle`
  * {
    box-sizing: border-box;
  }

  html {
    height: 100%;
  }

  body {
    min-height: 100%;
  }

  html,
  body,
  input,
  textarea,
  select,
  button {
    font-family: 'CerebriSans', sans-serif;
    font-weight: normal;
    font-style: normal;
  }

  html,
  body {
    width: 100%;
    padding: 0;
    margin: 0;
    background-color: ${colors.background};

    #app {
      width: 100%;
      height: 100%;

      /* Do not support layouts below 300px in width */
      min-width: 300px;
    }
  }

  body {
    height: 100%;
    overflow-y: scroll; /* always show the scroll bar to avoid jumpiness when going from page to page */
    overflow-x: hidden;
  }

  a {
    ${linkStyles};
  }

  /* STYLES FOR REACT-CALENDAR-TIMELINE PACKAGE */
  .react-calendar-timeline .rct-header-container.header-sticky, 
  .react-calendar-timeline .rct-item .rct-item-content {
    position: static;
  }

  .react-calendar-timeline .rct-sidebar-header {
    background: none;
    color: ${colors.charcoalGrey};
    ${fonts.bodyBold};
  }

  .react-calendar-timeline .rct-header .rct-label-group {
    background: ${colors.white};
    /* border: 1px solid ${colors.lightBlueGrey}; */
    ${fonts.largeRegular};
    color: ${colors.charcoalGrey};
    text-transform: uppercase;
    border-left: 2px solid ${colors.lightBlueGrey};
    border-top: 1px solid ${colors.lightBlueGrey};
    border-bottom: 1px solid ${colors.lightBlueGrey};
  }

  .react-calendar-timeline .rct-header .rct-label {
    ${fonts.smallRegular};
    color: ${colors.battleshipGrey};
    background: ${colors.white};
    border-bottom: none;
    border-bottom: 1px solid ${colors.lightBlueGrey};
    border-left: 1px solid ${colors.lightBlueGrey};
  }

  .react-calendar-timeline .rct-header .rct-label.rct-label-only {
    background: ${colors.white};
    color: ${colors.battleshipGrey};
  }


  .react-calendar-timeline .rct-sidebar-header {
    border-bottom: none;
    border-right: 1px solid ${colors.lightBlueGrey};
  }

  .react-calendar-timeline .rct-sidebar {
    border-left: none;
    border-top: 1px solid #d7dbe9;
    border-bottom: 1px solid #d7dbe9;
    border-right: 1px solid #d7dbe9;
    border-top-right-radius: 10px;
    border-bottom-right-radius: 10px;
  }

  .react-calendar-timeline .rct-sidebar .rct-sidebar-row {
    border-bottom: 1px solid ${colors.lightBlueGrey};
  }

  .react-calendar-timeline .rct-sidebar .rct-sidebar-row.rct-sidebar-row-odd,
  .react-calendar-timeline .rct-horizontal-lines .rct-hl-odd {
    background: ${colors.paleGreyThree};
  }

  .react-calendar-timeline .rct-horizontal-lines .rct-hl-even,
  .react-calendar-timeline .rct-horizontal-lines .rct-hl-odd {
    border-bottom: 1px solid transparent;
  }

  .react-calendar-timeline .rct-vertical-lines .rct-vl {
    /* z-index: 30; */
    z-index: 0;
  }

  .react-calendar-timeline .rct-horizontal-lines .rct-hl-even, .react-calendar-timeline .rct-horizontal-lines .rct-hl-odd {
    /* z-index: 40; */
    z-index: 0;
  }

  .react-calendar-timeline .rct-cursor-line {
    /* z-index: 51; */
    z-index: 0;
  }

  .react-calendar-timeline .rct-infolabel {
    z-index: 85;
  }

  .react-calendar-timeline .rct-item:hover {
    /* z-index: 88; */
    z-index: 0;
  }

  .react-calendar-timeline .rct-header-container {
    z-index: 90;
  }

  .react-calendar-timeline .rct-header {
    z-index: 90;
  }

  .react-calendar-timeline .rct-item {
    z-index: 0 !important;
  }


  /* STYLES FOR REACT CONTEXTMENU PACKAGE */
  .react-contextmenu {
    background-color: ${colors.white};
    border: 1px solid ${colors.lightBlueGrey};
    box-shadow: 0 2px 20px 0 rgba(66, 68, 76, 0.15);
    border-radius: 5px;
    color: ${colors.charcoalGrey};
    ${fonts.bodyRegular};
    margin: 2px 0 0;
    min-width: 200px;
    outline: none;
    opacity: 0;
    pointer-events: none;
    text-align: left;
    transition: opacity 200ms ease !important;
    z-index: 1;
  }

  .react-contextmenu.react-contextmenu--visible {
    opacity: 1;
    pointer-events: auto;
    z-index: 3;
  }

  .react-contextmenu-item {
    border: 0;
    color: ${colors.charcoalGrey};
    cursor: pointer;
    line-height: 1.5;
    padding: 8px 16px;
    text-align: inherit;
    white-space: nowrap;
    border-bottom: 1px solid ${colors.lightBlueGrey};

    &:first-child {
      border-top-left-radius: 5px;
      border-top-right-radius: 5px;
    }

    &:last-child {
      border-bottom-left-radius: 5px;
      border-bottom-right-radius: 5px;
    }

    &:hover {
      background-color: ${colors.paleGreyTwo};
    }
  }

  .react-contextmenu-item.react-contextmenu-item--active,
  .react-contextmenu-item.react-contextmenu-item--selected {
    color: ${colors.white};
    background-color: #20a0ff;
    border-color: #20a0ff;
    text-decoration: none;
  }

  .react-contextmenu-item.react-contextmenu-item--disabled,
  .react-contextmenu-item.react-contextmenu-item--disabled:hover {
    background-color: transparent;
    border-color: rgba(0, 0, 0, 0.15);
    color: #878a8c;
  }

  .react-contextmenu-item--divider {
    border-bottom: 1px solid ${colors.lightBlueGrey};
    cursor: inherit;
    margin-bottom: 3px;
    padding: 2px 0;
  }
  .react-contextmenu-item--divider:hover {
    background-color: transparent;
    border-color: rgba(0, 0, 0, 0.15);
  }

  .react-contextmenu-item.react-contextmenu-submenu {
    padding: 0;
  }

  .react-contextmenu-item.react-contextmenu-submenu
    > .react-contextmenu-item:after {
    content: '▶';
    display: inline-block;
    position: absolute;
    right: 7px;
  }

  .__react_component_tooltip.type-info {
    max-width: 400px;
    word-break: break-word;
    border-radius: 10px;
    background-color: ${colors.charcoalGrey};
    ${fonts.bodyRegular};

    &.place-top:after {
      border-top-color: ${colors.charcoalGrey};
      border-top-style: solid;
      border-top-width: 6px;
    }

    &.place-right:after {
      border-right-color: ${colors.charcoalGrey};
      border-right-style: solid;
      border-right-width: 6px;
    }

    &.place-bottom:after {
      border-bottom-color: ${colors.charcoalGrey};
      border-bottom-style: solid;
      border-bottom-width: 6px;
    }

    &.place-left:after {
      border-left-color: ${colors.charcoalGrey};
      border-left-style: solid;
      border-left-width: 6px;
    }
  } 

  /* Style overrides for react sortable tree */
  .rst__tree .rst__nodeContent {
    min-width: 60%;
  }
  .rst__tree .rst__rowContentsDragDisabled {
    border-left: unset;
  }

  .rst__tree .rst__rowContents {
    border: solid 1px ${colors.lightBlueGrey};
    border-radius: 10px;
    box-shadow: unset;
  }

  .rst__tree .rst__collapseButton,
  .rst__tree .rst__expandButton,
  .rst__tree .rst__collapseButton:focus, 
  .rst__tree .rst__expandButton:focus {
    box-shadow: 0 0 0 2px ${colors.coolGrey};
  }

  .rst__tree .rst__rowLabel {
    height: 100%;
    width: 100%;
  }

  .rst__tree .rst__lineHalfHorizontalRight::before, 
  .rst__tree .rst__lineFullVertical::after,
  .rst__tree .rst__lineHalfVerticalTop::after,
  .rst__tree .rst__lineHalfVerticalBottom::after,
  .rst__tree .rst__lineChildren::after {
    background: ${colors.coolGrey};
  }

  .rst__lineHalfHorizontalRight::before {
    height: 2px;
  }
  .rst__tree .rst__lineFullVertical::after,
  .rst__tree .rst__lineHalfVerticalTop::after,
  .rst__tree .rst__lineHalfVerticalBottom::after,
  .rst__tree .rst__lineChildren::after {
    width: 2px
  }

/* context menu for Job List Panel in Production Schedule */
  .job-list-panel-content .react-contextmenu.react-contextmenu--visible {
    opacity: 1;
    pointer-events: auto;
    z-index: 3;
    top: 190px !important;
    left: 100px !important;
  }

  @media (min-width: 1000px) {
    .job-list-panel-content .react-contextmenu.react-contextmenu--visible {
      top: 135px !important;
    left: 200px !important;
    }
  }
`;
