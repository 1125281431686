// @flow

import { css } from 'styled-components';

export default {
  smallRegular: css`
    font-size: 12px;
    font-weight: 400;
  `,
  smallBold: css`
    font-size: 12px;
    font-weight: 700;
  `,
  bodyRegular: css`
    font-size: 14px;
    font-weight: 400;
  `,
  bodyBold: css`
    font-size: 14px;
    font-weight: 700;
  `,
  largeRegular: css`
    font-size: 16px;
    font-weight: 400;
  `,
  largeBold: css`
    font-size: 16px;
    font-weight: 700;
  `,
  headerRegular: css`
    font-size: 18px;
    font-weight: 400;
  `,
  headerBold: css`
    font-size: 18px;
    font-weight: 700;
  `,
  subtitle: css`
    font-size: 20px;
    font-weight: 900;
  `,
  largeTitle: css`
    font-size: 24px;
    font-weight: 900;

    @media (min-width: 1000px) {
      font-size: 28px;
    }
  `,
  title: css`
    font-size: 20px;
    font-weight: 700;
  `,
  menuTitle: css`
    font-size: 24px;
    font-weight: 900;
  `,
  metricBold: css`
    font-size: 50px;
    font-weight: 900;
  `,
  tickerBold: css`
    font-size: 45px;
    font-weight: 900;
  `,
};
