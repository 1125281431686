// @flow

import React, { PureComponent } from 'react'; // eslint-disable-line
import debounce from 'lodash.debounce';
import { connect } from 'formik';

type Props = {
  formik: Object,
  onSave: Object => Promise<any>,
  render?: Object => React$Node,
  debounce?: number,
};

type State = {
  isSaving: boolean,
};

class FormAutoSaver extends PureComponent<Props, State> {
  static defaultProps = {
    render: undefined,
    debounce: undefined,
  };

  state = {
    isSaving: false,
  };

  constructor(props: Props) {
    super(props);

    this.state = {
      isSaving: false,
    };

    this.save = debounce(this.save, props.debounce || 1000);
  }

  componentDidUpdate(prevProps) {
    const { formik } = this.props;

    if (
      JSON.stringify(prevProps.formik.values) !== JSON.stringify(formik.values)
    ) {
      this.save();
    }
  }

  save = () => {
    const { formik, onSave } = this.props;

    if (!formik.isValid) {
      return;
    }

    this.setState(
      {
        isSaving: true,
      },
      () => {
        Promise.resolve(onSave(formik.values)).then(() => {
          this.setState({ isSaving: false });
        });
      },
    );
  };

  render() {
    const { render } = this.props;

    return render ? render(this.state) : null;
  }
}

export default connect(FormAutoSaver);
