// @flow

import React from 'react';
import { createFragmentContainer, graphql } from 'react-relay';
import styled from 'styled-components';
import { colors } from 'shared/styleguide';
import { i18n } from 'shared/utils';
import { renderCosmeticFieldValue } from 'shared/components/form/FormBuilder';
import EmptyListResults from 'shared/components/common/EmptyListResults';
import Panel from 'shared/components/common/Panel';
import KeyValueGrid from 'shared/components/common/KeyValueGrid';
import type { RunYieldsInfo_run as RunFragment } from './__generated__/RunYieldsInfo_run';

type Props = {
  run: RunFragment,
};

const Divider = styled.div`
  margin-top: 24px;
  margin-bottom: 16px;
  height: 2px;
  background-color: ${colors.paleGrey};
`;
const RunYieldsInfo = ({ run }: Props) => {
  const edges = (run.yields.edges || []).filter(Boolean).map(edge => edge.node);

  if (!edges.length) {
    return (
      <Panel>
        <EmptyListResults
          graphic="items"
          message={i18n.t(
            'Items associated with this run yield will display here. Contact your administrator if something is missing.',
          )}
        />
      </Panel>
    );
  }

  return edges.map(jobYield => {
    const customJobYieldFields = jobYield.form.fields.filter(
      formField => !formField.systemKey,
    );

    const customItemFields = jobYield.item.form.fields.filter(
      formField => !formField.systemKey,
    );

    const overUnderRule =
      jobYield.overUnderRule === 'NO_OVERS'
        ? 'No Overs'
        : jobYield.overUnderRule === 'NO_UNDERS'
        ? 'No Unders'
        : jobYield.overUnderRule === 'ALLOW_OVERS'
        ? 'Allow Overs'
        : '';

    const totalQuantity = jobYield.quantity;
    const overQuantity = jobYield.overQuantity;
    const percentageChange =
      totalQuantity && overQuantity
        ? Math.round((overQuantity / totalQuantity) * 100)
        : 0;

    return (
      <div key={jobYield.id} style={{ paddingBottom: 16 }}>
        <Panel title={jobYield.item.name}>
          <KeyValueGrid
            singleColumn
            fields={[
              {
                label: i18n.t('Quantity to Produce'),
                value: i18n.t('{{quantity, number }}', {
                  quantity: jobYield.quantity,
                }),
              },
              {
                label: i18n.t('# Up'),
                value: jobYield.itemNumberUp,
              },
              {
                label: i18n.t('Over Quantity'),
                value: jobYield.overQuantity
                  ? i18n.t(
                      '{{quantity, number }} ({{ percentageChange, string }}%)',
                      {
                        quantity: jobYield.overQuantity,
                        percentageChange,
                      },
                    )
                  : '',
              },
              {
                label: i18n.t('Allow Overs/Unders?'),
                value: i18n.t(overUnderRule),
              },
              {
                label: i18n.t('Broker PO #'),
                value: jobYield.brokerCustomerOrderNumber || '',
              },
            ].concat(
              customJobYieldFields.map(customField => ({
                label: customField.name,
                value: renderCosmeticFieldValue({
                  formFieldType: customField.type,
                  value: jobYield.formFieldValues[customField.id] || '',
                }),
              })),
            )}
          />
          {/* FIXME: switch out for common divider component */}
          <Divider />
          <KeyValueGrid
            singleColumn
            fields={[
              {
                label: i18n.t('Inventory #'),
                value: jobYield.item.itemNumber || '',
              },
              {
                label: i18n.t('Part #'),
                value: jobYield.item.partNumber || '',
              },
              {
                label: i18n.t('Description'),
                value: jobYield.item.description || '',
              },
            ].concat(
              customItemFields.map(customField => ({
                label: customField.name,
                value: renderCosmeticFieldValue({
                  formFieldType: customField.type,
                  value: jobYield.item.formFieldValues[customField.id] || '',
                }),
              })),
            )}
          />
        </Panel>
      </div>
    );
  });
};

export default createFragmentContainer(RunYieldsInfo, {
  run: graphql`
    fragment RunYieldsInfo_run on Run {
      id
      yields(first: null) {
        edges {
          node {
            id
            quantity
            overUnderRule
            overQuantity
            brokerCustomerOrderNumber
            itemNumberUp
            formFieldValues
            form {
              id
              ...FormBuilder_form
              fields {
                id
                systemKey
                name
                type
              }
            }
            item {
              id
              itemNumber
              name
              partNumber
              description
              formFieldValues
              form {
                id
                ...FormBuilder_form
                fields {
                  id
                  systemKey
                  name
                  type
                }
              }
            }
          }
        }
      }
    }
  `,
});
