// @flow

import React from 'react';
import * as Yup from 'yup';
import moment from 'moment';
import { i18n } from 'shared/utils';
import MultilineText from 'shared/components/common/MultilineText';
import UserLabel from 'shared/components/common/QueryWidget/UserLabel';
import CustomerLabel from 'shared/components/common/QueryWidget/CustomerLabel';
import OrderLabel from 'shared/components/common/QueryWidget/OrderLabel';
import MachineLabel from 'shared/components/common/QueryWidget/MachineLabel';
import SelectUserField from '../SelectUserField';
import SelectCustomerField from '../SelectCustomerField';
import SelectOrderField from '../SelectOrderField';
import SelectMachineField from '../SelectMachineField';
import FieldGroup from '../FieldGroup';

export default {
  TEXT: {
    configLabel: i18n.t('Text'),
    component: FieldGroup,
    validation: (errorMsg: string) => Yup.string(errorMsg),
    cosmeticValue: (value: any) =>
      value && typeof value === 'string' ? value : '',
    props: ({ form, formField }: *) => ({
      type: 'text',
    }),
  },
  MULTILINE_TEXT: {
    configLabel: i18n.t('Multi-line Text'),
    component: FieldGroup,
    validation: (errorMsg: string) => Yup.string(errorMsg),
    cosmeticValue: (value: any) => (
      <MultilineText>
        {value && typeof value === 'string' ? value : ''}
      </MultilineText>
    ),
    props: ({ form, formField }: *) => ({
      type: 'textarea',
    }),
  },
  NUMBER: {
    configLabel: i18n.t('Number'),
    component: FieldGroup,
    validation: (errorMsg: string) => Yup.number(errorMsg),
    cosmeticValue: (value: any) =>
      value && (typeof value === 'string' || typeof value === 'number')
        ? i18n.t('{{numberValue, number}}', {
            numberValue: value,
          })
        : '',
    props: ({ form, formField }: *) => ({
      type: 'number',
    }),
  },
  SELECT: {
    configLabel: i18n.t('Selector'),
    component: FieldGroup,
    validation: (errorMsg: string) => Yup.string(errorMsg).nullable(),
    cosmeticValue: (value: any) =>
      value && typeof value === 'string' ? value : '',
    props: ({ form, formField }: *) => ({
      type: 'select',
      clearable: true,
      options: formField.options
        ? formField.options.map(a => ({
            label: a.name,
            value: a.value,
          }))
        : [],
    }),
  },
  MULTISELECT: {
    configLabel: i18n.t('Multi-Selector'),
    component: FieldGroup,
    validation: (errorMsg: string) => Yup.string(errorMsg).nullable(),
    cosmeticValue: (value: any) =>
      value && Array.isArray(value)
        ? value.map(val => val.value).join(', ')
        : '',
    props: ({ form, formField }: *) => ({
      type: 'select',
      multi: true,
      clearable: true,
      options: formField.options
        ? formField.options.map(a => ({
            label: a.name,
            value: a.value,
          }))
        : [],
    }),
  },
  DATE: {
    configLabel: i18n.t('Date'),
    component: FieldGroup,
    validation: (errorMsg: string) => Yup.date(errorMsg).nullable(),
    cosmeticValue: (value: any) =>
      value && (typeof value === 'string' || moment.isMoment(value))
        ? moment(value).format('MM/DD/YYYY')
        : '',
    props: ({ form, formField }: *) => ({
      type: 'date',
      allowPastDates: true,
    }),
  },
  SELECT_USER: {
    configLabel: i18n.t('User Selector'),
    component: SelectUserField,
    validation: (errorMsg: string) => Yup.string(errorMsg).nullable(),
    cosmeticValue: (value: any) =>
      value && typeof value === 'string' ? <UserLabel userId={value} /> : '',
    props: ({ form, formField }: *) => ({
      clearable: true,
      queryVariables: formField.properties?.queryVariables,
    }),
  },
  SELECT_CUSTOMER: {
    configLabel: i18n.t('Customer Selector'),
    component: SelectCustomerField,
    validation: (errorMsg: string) => Yup.string(errorMsg).nullable(),
    cosmeticValue: (value: any) =>
      value && typeof value === 'string' ? (
        <CustomerLabel customerId={value} />
      ) : (
        ''
      ),
    props: ({ form, formField }: *) => ({
      clearable: true,
      queryVariables: formField.properties?.queryVariables,
    }),
  },
  SELECT_ORDER: {
    configLabel: i18n.t('Order Selector'),
    component: SelectOrderField,
    validation: (errorMsg: string) => Yup.string(errorMsg).nullable(),
    cosmeticValue: (value: any) =>
      value && typeof value === 'string' ? <OrderLabel orderId={value} /> : '',
    props: ({ form, formField }: *) => ({
      clearable: true,
      queryVariables: formField.properties?.queryVariables,
    }),
  },
  SELECT_MACHINE: {
    configLabel: i18n.t('Work Center Selector'),
    component: SelectMachineField,
    validation: (errorMsg: string) => Yup.string(errorMsg).nullable(),
    cosmeticValue: (value: any) =>
      value && typeof value === 'string' ? (
        <MachineLabel machineId={value} />
      ) : (
        ''
      ),
    props: ({ form, formField, machineTypeId }: *) => ({
      clearable: true,
      queryVariables: {
        ...(formField.properties?.queryVariables || {}),
        productionTypeId: form.productionType?.id,
        machineTypeId,
      },
    }),
  },
};
