// @flow

import React, { PureComponent } from 'react';
import { createFragmentContainer, graphql } from 'react-relay';
import moment from 'moment';
import { i18n, WorkflowUtils } from 'shared/utils';
import styled from 'styled-components';
import { fonts, colors } from 'shared/styleguide';
import Panel from 'shared/components/common/Panel';
import KeyValueGrid from 'shared/components/common/KeyValueGrid';
import JobStateGrid from 'shared/components/common/JobStateGrid';
import Icon from 'shared/components/common/Icon';
import UpdateJobStateModal from 'shared/components/common/UpdateJobStateModal';
import type { RunDetails_run as RunFragment } from './__generated__/RunDetails_run';

type Props = {
  run: RunFragment,
};

type State = {
  updateJobStateRecord: ?Object,
};

const StatesDivider = styled.div`
  background-color: ${colors.paleGrey};
  color: ${colors.battleshipGrey};
  font-weight: ${fonts.bodyBold};
  padding-top: 8px;
  padding-bottom: 8px;
  text-align: center;
`;

class RunDetails extends PureComponent<Props, State> {
  state = {
    updateJobStateRecord: null,
  };

  handleOpenJobStateModal = (jobState: Object) => {
    this.setState({
      updateJobStateRecord: jobState,
    });
  };

  handleCloseAllModals = () => {
    this.setState({
      updateJobStateRecord: null,
    });
  };

  render() {
    const { updateJobStateRecord } = this.state;
    const { run } = this.props;

    const states = [...run.job.states];
    const jobStateIdx = states.findIndex(state => state.id === run.jobState.id);
    const currentState = states.splice(jobStateIdx, 1);
    const otherStates = states;
    const sortedJobStates = WorkflowUtils.getSortedJobStates(run.job);

    const displayStates = jobStates =>
      jobStates
        .filter(
          jobState =>
            jobState.isEnabled &&
            (jobState.workflowState.type === 'TASK' ||
              (jobState.workflowState.isAssignable ||
                jobState.workflowState.form)),
        )
        .sort((jobStateA, jobStateB) =>
          sortedJobStates
            ? (sortedJobStates[jobStateA.id]?.order || 0) -
              (sortedJobStates[jobStateB.id]?.order || 0)
            : 0,
        )
        .map(jobState => (
          <Panel
            key={jobState.id}
            style={{
              marginBottom: 16,
              marginTop: 16,
            }}
            title={jobState.workflowState.name}
            headerRight={
              <Icon
                type="circle-edit"
                onClick={() => this.handleOpenJobStateModal(jobState)}
              />
            }
          >
            <JobStateGrid jobState={jobState} />
          </Panel>
        ));

    const fields = [
      {
        label: i18n.t('Job #'),
        value: run.job.importJobNumber || run.job.jobNumber,
      },
      {
        label: i18n.t('Customer'),
        value: run.job.order?.customer.name || '',
      },
      {
        label: i18n.t('Project'),
        value: run.job.name,
      },
      {
        label: i18n.t('Scheduled Start'),
        value: run.scheduledStartAt
          ? moment(run.scheduledStartAt).format('MM/DD/YYYY, hh:mm:ss A')
          : null,
      },
      {
        label: i18n.t('Scheduled End'),
        value: run.scheduledEndAt
          ? moment(run.scheduledEndAt).format('MM/DD/YYYY, hh:mm:ss A')
          : null,
      },
      {
        label: i18n.t('Notes'),
        value: run.notes || '',
      },
    ];

    return (
      <div>
        <Panel title={i18n.t('Run Overview')}>
          <KeyValueGrid singleColumn fields={fields} />
        </Panel>
        {displayStates(currentState)}
        {otherStates.length ? (
          <>
            <StatesDivider>Other Run Info Below</StatesDivider>
            {displayStates(otherStates)}
          </>
        ) : null}
        {updateJobStateRecord && (
          <UpdateJobStateModal
            job={run.job}
            jobState={updateJobStateRecord}
            onClose={this.handleCloseAllModals}
          />
        )}
      </div>
    );
  }
}

export default createFragmentContainer(RunDetails, {
  run: graphql`
    fragment RunDetails_run on Run {
      id
      notes
      scheduledStartAt
      scheduledEndAt
      machine {
        id
        type {
          id
        }
      }
      jobState {
        id
      }
      job {
        id
        jobNumber
        importJobNumber
        name
        ...UpdateJobStateModal_job
        ...WorkflowUtils_job @relay(mask: false)
        order {
          id
          customer {
            id
            name
          }
        }
        states {
          id
          isEnabled
          workflowState {
            id
            name
            type
            isAssignable
            machineType {
              id
            }
            form {
              id
            }
          }
          ...UpdateJobStateModal_jobState
          ...JobStateGrid_jobState
        }
      }
    }
  `,
});
