/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ReaderFragment } from 'relay-runtime';
import type { FragmentReference } from "relay-runtime";
declare export opaque type SetupEndModal_run$ref: FragmentReference;
declare export opaque type SetupEndModal_run$fragmentType: SetupEndModal_run$ref;
export type SetupEndModal_run = {|
  +id: string,
  +jobState: {|
    +id: string,
    +workflowState: {|
      +id: string,
      +isTrackingSetupQuantity: ?boolean,
    |},
  |},
  +yields: {|
    +edges: ?$ReadOnlyArray<?{|
      +node: {|
        +id: string,
        +quantity: number,
        +item: {|
          +id: string,
          +name: string,
          +partNumber: ?string,
          +quantityUOM: {|
            +id: string,
            +symbol: string,
          |},
        |},
      |}
    |}>
  |},
  +machine: {|
    +id: string,
    +type: {|
      +id: string,
      +name: string,
    |},
  |},
  +$refType: SetupEndModal_run$ref,
|};
export type SetupEndModal_run$data = SetupEndModal_run;
export type SetupEndModal_run$key = {
  +$data?: SetupEndModal_run$data,
  +$fragmentRefs: SetupEndModal_run$ref,
};
*/


const node/*: ReaderFragment*/ = (function(){
var v0 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "id",
  "args": null,
  "storageKey": null
},
v1 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "name",
  "args": null,
  "storageKey": null
};
return {
  "kind": "Fragment",
  "name": "SetupEndModal_run",
  "type": "Run",
  "metadata": null,
  "argumentDefinitions": [],
  "selections": [
    (v0/*: any*/),
    {
      "kind": "LinkedField",
      "alias": null,
      "name": "jobState",
      "storageKey": null,
      "args": null,
      "concreteType": "JobState",
      "plural": false,
      "selections": [
        (v0/*: any*/),
        {
          "kind": "LinkedField",
          "alias": null,
          "name": "workflowState",
          "storageKey": null,
          "args": null,
          "concreteType": "WorkflowState",
          "plural": false,
          "selections": [
            (v0/*: any*/),
            {
              "kind": "ScalarField",
              "alias": null,
              "name": "isTrackingSetupQuantity",
              "args": null,
              "storageKey": null
            }
          ]
        }
      ]
    },
    {
      "kind": "LinkedField",
      "alias": null,
      "name": "yields",
      "storageKey": null,
      "args": null,
      "concreteType": "RunYieldConnection",
      "plural": false,
      "selections": [
        {
          "kind": "LinkedField",
          "alias": null,
          "name": "edges",
          "storageKey": null,
          "args": null,
          "concreteType": "RunYieldEdge",
          "plural": true,
          "selections": [
            {
              "kind": "LinkedField",
              "alias": null,
              "name": "node",
              "storageKey": null,
              "args": null,
              "concreteType": "JobYield",
              "plural": false,
              "selections": [
                (v0/*: any*/),
                {
                  "kind": "ScalarField",
                  "alias": null,
                  "name": "quantity",
                  "args": null,
                  "storageKey": null
                },
                {
                  "kind": "LinkedField",
                  "alias": null,
                  "name": "item",
                  "storageKey": null,
                  "args": null,
                  "concreteType": "Item",
                  "plural": false,
                  "selections": [
                    (v0/*: any*/),
                    (v1/*: any*/),
                    {
                      "kind": "ScalarField",
                      "alias": null,
                      "name": "partNumber",
                      "args": null,
                      "storageKey": null
                    },
                    {
                      "kind": "LinkedField",
                      "alias": null,
                      "name": "quantityUOM",
                      "storageKey": null,
                      "args": null,
                      "concreteType": "UOM",
                      "plural": false,
                      "selections": [
                        (v0/*: any*/),
                        {
                          "kind": "ScalarField",
                          "alias": null,
                          "name": "symbol",
                          "args": null,
                          "storageKey": null
                        }
                      ]
                    }
                  ]
                }
              ]
            }
          ]
        }
      ]
    },
    {
      "kind": "LinkedField",
      "alias": null,
      "name": "machine",
      "storageKey": null,
      "args": null,
      "concreteType": "Machine",
      "plural": false,
      "selections": [
        (v0/*: any*/),
        {
          "kind": "LinkedField",
          "alias": null,
          "name": "type",
          "storageKey": null,
          "args": null,
          "concreteType": "MachineType",
          "plural": false,
          "selections": [
            (v0/*: any*/),
            (v1/*: any*/)
          ]
        }
      ]
    }
  ]
};
})();
// prettier-ignore
(node/*: any*/).hash = '6a0f36bdf1add6acc2b5508d7d1c937d';
module.exports = node;
