// @flow

import React from 'react';
import { createFragmentContainer, graphql } from 'react-relay';
import * as Yup from 'yup';
import * as Actions from 'operator-portal/store/Actions';
import { i18n, Analytics } from 'shared/utils';
import UpdateMachineDowntimeEndMutation from 'shared/mutations/UpdateMachineDowntimeEnd';
import {
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
} from 'shared/components/modal';
import { Formik, Form, FieldGroup } from 'shared/components/form';
import FatButton from 'shared/components/common/FatButton';
import type { DowntimeEndModal_machine as RunFragment } from './__generated__/DowntimeEndModal_machine';

type Props = {
  onClose: () => void,
  machine: RunFragment,
};

const DowntimeEndModal = ({ onClose, machine }: Props) => (
  <Modal maxWidth={700}>
    <Formik
      initialValues={{
        userNumber: '',
        endNotes: '',
      }}
      validationSchema={Yup.object().shape({
        userNumber: Yup.string().required('Required'),
        endNotes: Yup.string(),
      })}
      onSubmit={async (values: *, { setSubmitting }: *) => {
        setSubmitting(true);

        try {
          await UpdateMachineDowntimeEndMutation.commit({
            variables: {
              input: {
                machineId: machine.id,
                userNumber: values.userNumber,
                endNotes: values.endNotes,
              },
            },
          });

          onClose();
          Analytics.trackEvent('Resume Uptime', {
            operatorID: values.userNumber,
            notes: values.endNotes,
            machineType: machine.type?.name,
          });
          Actions.alertNotification(i18n.t('Uptime Resumed'), 'success');
        } catch (e) {
          setSubmitting(false);
          Actions.alertNotification(e.message, 'error');
        }
      }}
      render={({ values, errors, isValid, isSubmitting, handleSubmit }) => (
        <Form>
          <ModalHeader header={i18n.t('Resume Uptime?')} onClose={onClose} />
          <ModalBody withPadding>
            <FieldGroup
              name="userNumber"
              type="number"
              label={i18n.t('Operator ID')}
              placeholder={i18n.t('Enter ID')}
              error={errors.userNumber}
            />
            <FieldGroup
              name="endNotes"
              type="textarea"
              label={i18n.t('Notes')}
              placeholder={i18n.t('Type here...')}
              error={errors.endNotes}
              height={80}
            />
          </ModalBody>
          <ModalFooter style={{ display: 'block', textAlign: 'center' }}>
            <FatButton
              type="submit"
              disabled={!isValid}
              loading={isSubmitting}
              onClick={handleSubmit}
            >
              {i18n.t('Resume Uptime For {{machineName}}', {
                machineName: machine.name,
              })}
            </FatButton>
          </ModalFooter>
        </Form>
      )}
    />
  </Modal>
);

export default createFragmentContainer(DowntimeEndModal, {
  machine: graphql`
    fragment DowntimeEndModal_machine on Machine {
      id
      name
      type {
        id
        name
      }
      currentDowntime {
        id
      }
    }
  `,
});
