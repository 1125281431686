/**
 * @flow
 * @relayHash a78e5ade1008f22f3a5cad94186ddd1f
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type SelectMachineFieldQueryVariables = {|
  workflowIds?: ?$ReadOnlyArray<string>,
  machineTypeId?: ?string,
|};
export type SelectMachineFieldQueryResponse = {|
  +machines: {|
    +edges: ?$ReadOnlyArray<?{|
      +node: {|
        +id: string,
        +name: string,
        +type: {|
          +id: string,
          +name: string,
        |},
      |}
    |}>
  |}
|};
export type SelectMachineFieldQuery = {|
  variables: SelectMachineFieldQueryVariables,
  response: SelectMachineFieldQueryResponse,
|};
*/


/*
query SelectMachineFieldQuery(
  $workflowIds: [ID!]
  $machineTypeId: ID
) {
  machines(workflowIds: $workflowIds, machineTypeId: $machineTypeId) {
    edges {
      node {
        id
        name
        type {
          id
          name
        }
      }
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "workflowIds",
    "type": "[ID!]",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "machineTypeId",
    "type": "ID",
    "defaultValue": null
  }
],
v1 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "id",
  "args": null,
  "storageKey": null
},
v2 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "name",
  "args": null,
  "storageKey": null
},
v3 = [
  {
    "kind": "LinkedField",
    "alias": null,
    "name": "machines",
    "storageKey": null,
    "args": [
      {
        "kind": "Variable",
        "name": "machineTypeId",
        "variableName": "machineTypeId"
      },
      {
        "kind": "Variable",
        "name": "workflowIds",
        "variableName": "workflowIds"
      }
    ],
    "concreteType": "MachineConnection",
    "plural": false,
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "edges",
        "storageKey": null,
        "args": null,
        "concreteType": "MachineEdge",
        "plural": true,
        "selections": [
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "node",
            "storageKey": null,
            "args": null,
            "concreteType": "Machine",
            "plural": false,
            "selections": [
              (v1/*: any*/),
              (v2/*: any*/),
              {
                "kind": "LinkedField",
                "alias": null,
                "name": "type",
                "storageKey": null,
                "args": null,
                "concreteType": "MachineType",
                "plural": false,
                "selections": [
                  (v1/*: any*/),
                  (v2/*: any*/)
                ]
              }
            ]
          }
        ]
      }
    ]
  }
];
return {
  "kind": "Request",
  "fragment": {
    "kind": "Fragment",
    "name": "SelectMachineFieldQuery",
    "type": "Query",
    "metadata": null,
    "argumentDefinitions": (v0/*: any*/),
    "selections": (v3/*: any*/)
  },
  "operation": {
    "kind": "Operation",
    "name": "SelectMachineFieldQuery",
    "argumentDefinitions": (v0/*: any*/),
    "selections": (v3/*: any*/)
  },
  "params": {
    "operationKind": "query",
    "name": "SelectMachineFieldQuery",
    "id": null,
    "text": "query SelectMachineFieldQuery(\n  $workflowIds: [ID!]\n  $machineTypeId: ID\n) {\n  machines(workflowIds: $workflowIds, machineTypeId: $machineTypeId) {\n    edges {\n      node {\n        id\n        name\n        type {\n          id\n          name\n        }\n      }\n    }\n  }\n}\n",
    "metadata": {}
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '59f1edf92b3bfa3658df5548b2d83c45';
module.exports = node;
