/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ReaderFragment } from 'relay-runtime';
export type ApplicationType = "MAIN_APP" | "OPERATOR_PORTAL";
export type FormFieldType = "CHECKBOX" | "DATE" | "MULTILINE_TEXT" | "MULTISELECT" | "NUMBER" | "SELECT" | "SELECT_CUSTOMER" | "SELECT_MACHINE" | "SELECT_ORDER" | "SELECT_USER" | "TEXT";
export type FormType = "ITEM" | "JOB_STEP" | "JOB_TASK" | "JOB_YIELD";
import type { FragmentReference } from "relay-runtime";
declare export opaque type RunTooling_job$ref: FragmentReference;
declare export opaque type RunTooling_job$fragmentType: RunTooling_job$ref;
export type RunTooling_job = {|
  +id: string,
  +importJobNumber: ?string,
  +jobNumber: string,
  +linkedItems: {|
    +edges: ?$ReadOnlyArray<?{|
      +usageQty: ?number,
      +node: {|
        +id: string,
        +description: ?string,
        +itemNumber: string,
        +formFieldValues: any,
        +name: string,
        +partNumber: ?string,
        +upc: ?string,
        +category: ?{|
          +id: string,
          +name: string,
        |},
        +quantityUOM: {|
          +id: string,
          +name: string,
          +symbol: string,
        |},
        +form: {|
          +id: string,
          +name: string,
          +type: FormType,
          +systemKey: ?string,
          +productionType: ?{|
            +id: string
          |},
          +fields: $ReadOnlyArray<{|
            +id: string,
            +systemKey: ?string,
            +type: FormFieldType,
            +name: string,
            +description: ?string,
            +placeholder: ?string,
            +isRequired: boolean,
            +isCreatable: boolean,
            +applicationType: ApplicationType,
            +displayOrder: number,
            +properties: ?any,
            +options: $ReadOnlyArray<{|
              +name: string,
              +value: string,
            |}>,
          |}>,
        |},
      |},
    |}>
  |},
  +$refType: RunTooling_job$ref,
|};
export type RunTooling_job$data = RunTooling_job;
export type RunTooling_job$key = {
  +$data?: RunTooling_job$data,
  +$fragmentRefs: RunTooling_job$ref,
};
*/


const node/*: ReaderFragment*/ = (function(){
var v0 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "id",
  "args": null,
  "storageKey": null
},
v1 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "description",
  "args": null,
  "storageKey": null
},
v2 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "name",
  "args": null,
  "storageKey": null
},
v3 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "type",
  "args": null,
  "storageKey": null
},
v4 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "systemKey",
  "args": null,
  "storageKey": null
};
return {
  "kind": "Fragment",
  "name": "RunTooling_job",
  "type": "Job",
  "metadata": null,
  "argumentDefinitions": [],
  "selections": [
    (v0/*: any*/),
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "importJobNumber",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "jobNumber",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "LinkedField",
      "alias": null,
      "name": "linkedItems",
      "storageKey": null,
      "args": null,
      "concreteType": "JobLinkedItemConnection",
      "plural": false,
      "selections": [
        {
          "kind": "LinkedField",
          "alias": null,
          "name": "edges",
          "storageKey": null,
          "args": null,
          "concreteType": "JobLinkedItemEdge",
          "plural": true,
          "selections": [
            {
              "kind": "ScalarField",
              "alias": null,
              "name": "usageQty",
              "args": null,
              "storageKey": null
            },
            {
              "kind": "LinkedField",
              "alias": null,
              "name": "node",
              "storageKey": null,
              "args": null,
              "concreteType": "Item",
              "plural": false,
              "selections": [
                (v0/*: any*/),
                (v1/*: any*/),
                {
                  "kind": "ScalarField",
                  "alias": null,
                  "name": "itemNumber",
                  "args": null,
                  "storageKey": null
                },
                {
                  "kind": "ScalarField",
                  "alias": null,
                  "name": "formFieldValues",
                  "args": null,
                  "storageKey": null
                },
                (v2/*: any*/),
                {
                  "kind": "ScalarField",
                  "alias": null,
                  "name": "partNumber",
                  "args": null,
                  "storageKey": null
                },
                {
                  "kind": "ScalarField",
                  "alias": null,
                  "name": "upc",
                  "args": null,
                  "storageKey": null
                },
                {
                  "kind": "LinkedField",
                  "alias": null,
                  "name": "category",
                  "storageKey": null,
                  "args": null,
                  "concreteType": "ItemCategory",
                  "plural": false,
                  "selections": [
                    (v0/*: any*/),
                    (v2/*: any*/)
                  ]
                },
                {
                  "kind": "LinkedField",
                  "alias": null,
                  "name": "quantityUOM",
                  "storageKey": null,
                  "args": null,
                  "concreteType": "UOM",
                  "plural": false,
                  "selections": [
                    (v0/*: any*/),
                    (v2/*: any*/),
                    {
                      "kind": "ScalarField",
                      "alias": null,
                      "name": "symbol",
                      "args": null,
                      "storageKey": null
                    }
                  ]
                },
                {
                  "kind": "LinkedField",
                  "alias": null,
                  "name": "form",
                  "storageKey": null,
                  "args": null,
                  "concreteType": "Form",
                  "plural": false,
                  "selections": [
                    (v0/*: any*/),
                    (v2/*: any*/),
                    (v3/*: any*/),
                    (v4/*: any*/),
                    {
                      "kind": "LinkedField",
                      "alias": null,
                      "name": "productionType",
                      "storageKey": null,
                      "args": null,
                      "concreteType": "ProductionType",
                      "plural": false,
                      "selections": [
                        (v0/*: any*/)
                      ]
                    },
                    {
                      "kind": "LinkedField",
                      "alias": null,
                      "name": "fields",
                      "storageKey": null,
                      "args": null,
                      "concreteType": "FormField",
                      "plural": true,
                      "selections": [
                        (v0/*: any*/),
                        (v4/*: any*/),
                        (v3/*: any*/),
                        (v2/*: any*/),
                        (v1/*: any*/),
                        {
                          "kind": "ScalarField",
                          "alias": null,
                          "name": "placeholder",
                          "args": null,
                          "storageKey": null
                        },
                        {
                          "kind": "ScalarField",
                          "alias": null,
                          "name": "isRequired",
                          "args": null,
                          "storageKey": null
                        },
                        {
                          "kind": "ScalarField",
                          "alias": null,
                          "name": "isCreatable",
                          "args": null,
                          "storageKey": null
                        },
                        {
                          "kind": "ScalarField",
                          "alias": null,
                          "name": "applicationType",
                          "args": null,
                          "storageKey": null
                        },
                        {
                          "kind": "ScalarField",
                          "alias": null,
                          "name": "displayOrder",
                          "args": null,
                          "storageKey": null
                        },
                        {
                          "kind": "ScalarField",
                          "alias": null,
                          "name": "properties",
                          "args": null,
                          "storageKey": null
                        },
                        {
                          "kind": "LinkedField",
                          "alias": null,
                          "name": "options",
                          "storageKey": null,
                          "args": null,
                          "concreteType": "FormFieldOption",
                          "plural": true,
                          "selections": [
                            (v2/*: any*/),
                            {
                              "kind": "ScalarField",
                              "alias": null,
                              "name": "value",
                              "args": null,
                              "storageKey": null
                            }
                          ]
                        }
                      ]
                    }
                  ]
                }
              ]
            }
          ]
        }
      ]
    }
  ]
};
})();
// prettier-ignore
(node/*: any*/).hash = '054f3053a5d3fdf0714615d99205518e';
module.exports = node;
