/**
 * @flow
 * @relayHash 71199ca8528cede3af7870d8f30dbf7c
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type FormType = "ITEM" | "JOB_STEP" | "JOB_TASK" | "JOB_YIELD";
export type SelectFormFieldQueryVariables = {|
  types?: ?$ReadOnlyArray<FormType>
|};
export type SelectFormFieldQueryResponse = {|
  +forms: {|
    +edges: ?$ReadOnlyArray<?{|
      +node: {|
        +id: string,
        +name: string,
        +type: FormType,
      |}
    |}>
  |}
|};
export type SelectFormFieldQuery = {|
  variables: SelectFormFieldQueryVariables,
  response: SelectFormFieldQueryResponse,
|};
*/


/*
query SelectFormFieldQuery(
  $types: [FormType!]
) {
  forms(types: $types) {
    edges {
      node {
        id
        name
        type
      }
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "types",
    "type": "[FormType!]",
    "defaultValue": null
  }
],
v1 = [
  {
    "kind": "LinkedField",
    "alias": null,
    "name": "forms",
    "storageKey": null,
    "args": [
      {
        "kind": "Variable",
        "name": "types",
        "variableName": "types"
      }
    ],
    "concreteType": "FormConnection",
    "plural": false,
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "edges",
        "storageKey": null,
        "args": null,
        "concreteType": "FormEdge",
        "plural": true,
        "selections": [
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "node",
            "storageKey": null,
            "args": null,
            "concreteType": "Form",
            "plural": false,
            "selections": [
              {
                "kind": "ScalarField",
                "alias": null,
                "name": "id",
                "args": null,
                "storageKey": null
              },
              {
                "kind": "ScalarField",
                "alias": null,
                "name": "name",
                "args": null,
                "storageKey": null
              },
              {
                "kind": "ScalarField",
                "alias": null,
                "name": "type",
                "args": null,
                "storageKey": null
              }
            ]
          }
        ]
      }
    ]
  }
];
return {
  "kind": "Request",
  "fragment": {
    "kind": "Fragment",
    "name": "SelectFormFieldQuery",
    "type": "Query",
    "metadata": null,
    "argumentDefinitions": (v0/*: any*/),
    "selections": (v1/*: any*/)
  },
  "operation": {
    "kind": "Operation",
    "name": "SelectFormFieldQuery",
    "argumentDefinitions": (v0/*: any*/),
    "selections": (v1/*: any*/)
  },
  "params": {
    "operationKind": "query",
    "name": "SelectFormFieldQuery",
    "id": null,
    "text": "query SelectFormFieldQuery(\n  $types: [FormType!]\n) {\n  forms(types: $types) {\n    edges {\n      node {\n        id\n        name\n        type\n      }\n    }\n  }\n}\n",
    "metadata": {}
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = 'd01dee31f1036ae07eb4d1686c3e7774';
module.exports = node;
