// @flow

import React from 'react';
import { QueryRenderer, graphql } from 'react-relay';
import relayEnvironment from 'shared/gql/relayEnvironment';

type Props = {
  customerId: string,
};

export default ({ customerId }: Props) => (
  <QueryRenderer
    environment={relayEnvironment}
    query={graphql`
      query CustomerLabelQuery($customerId: ID!) {
        customer(id: $customerId) {
          id
          name
        }
      }
    `}
    variables={{ customerId }}
    render={query => (
      <span>
        {query.props
          ? query.props.customer?.name || 'Unknown Customer'
          : '. . .'}
      </span>
    )}
  />
);
