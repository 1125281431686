/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ReaderFragment } from 'relay-runtime';
type JobStateGrid_jobState$ref = any;
type UpdateJobStateModal_job$ref = any;
type UpdateJobStateModal_jobState$ref = any;
export type JobStateStatus = "COMPLETE" | "INCOMPLETE" | "IN_PROGRESS" | "NEEDS_ATTENTION" | "PAUSED";
export type WorkflowStateType = "JOB_COMPLETED" | "JOB_CREATED" | "JOB_ITEM_COUNT" | "RUN" | "TASK";
import type { FragmentReference } from "relay-runtime";
declare export opaque type RunDetails_run$ref: FragmentReference;
declare export opaque type RunDetails_run$fragmentType: RunDetails_run$ref;
export type RunDetails_run = {|
  +id: string,
  +notes: ?string,
  +scheduledStartAt: any,
  +scheduledEndAt: any,
  +machine: {|
    +id: string,
    +type: {|
      +id: string
    |},
  |},
  +jobState: {|
    +id: string
  |},
  +job: {|
    +id: string,
    +jobNumber: string,
    +importJobNumber: ?string,
    +name: string,
    +workflow: {|
      +id: string,
      +states: $ReadOnlyArray<{|
        +id: string,
        +name: string,
        +isRequired: ?boolean,
        +type: ?WorkflowStateType,
      |}>,
      +transitions: $ReadOnlyArray<{|
        +id: string,
        +toWorkflowState: {|
          +id: string
        |},
        +fromWorkflowState: {|
          +id: string
        |},
      |}>,
    |},
    +states: $ReadOnlyArray<{|
      +id: string,
      +isEnabled: boolean,
      +status: JobStateStatus,
      +workflowState: {|
        +id: string,
        +name: string,
        +type: ?WorkflowStateType,
        +isAssignable: ?boolean,
        +machineType: ?{|
          +id: string
        |},
        +form: ?{|
          +id: string
        |},
      |},
      +$fragmentRefs: UpdateJobStateModal_jobState$ref & JobStateGrid_jobState$ref,
    |}>,
    +order: ?{|
      +id: string,
      +customer: {|
        +id: string,
        +name: string,
      |},
    |},
    +$fragmentRefs: UpdateJobStateModal_job$ref,
  |},
  +$refType: RunDetails_run$ref,
|};
export type RunDetails_run$data = RunDetails_run;
export type RunDetails_run$key = {
  +$data?: RunDetails_run$data,
  +$fragmentRefs: RunDetails_run$ref,
};
*/


const node/*: ReaderFragment*/ = (function(){
var v0 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "id",
  "args": null,
  "storageKey": null
},
v1 = [
  (v0/*: any*/)
],
v2 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "name",
  "args": null,
  "storageKey": null
},
v3 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "type",
  "args": null,
  "storageKey": null
};
return {
  "kind": "Fragment",
  "name": "RunDetails_run",
  "type": "Run",
  "metadata": null,
  "argumentDefinitions": [],
  "selections": [
    (v0/*: any*/),
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "notes",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "scheduledStartAt",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "scheduledEndAt",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "LinkedField",
      "alias": null,
      "name": "machine",
      "storageKey": null,
      "args": null,
      "concreteType": "Machine",
      "plural": false,
      "selections": [
        (v0/*: any*/),
        {
          "kind": "LinkedField",
          "alias": null,
          "name": "type",
          "storageKey": null,
          "args": null,
          "concreteType": "MachineType",
          "plural": false,
          "selections": (v1/*: any*/)
        }
      ]
    },
    {
      "kind": "LinkedField",
      "alias": null,
      "name": "jobState",
      "storageKey": null,
      "args": null,
      "concreteType": "JobState",
      "plural": false,
      "selections": (v1/*: any*/)
    },
    {
      "kind": "LinkedField",
      "alias": null,
      "name": "job",
      "storageKey": null,
      "args": null,
      "concreteType": "Job",
      "plural": false,
      "selections": [
        (v0/*: any*/),
        {
          "kind": "ScalarField",
          "alias": null,
          "name": "jobNumber",
          "args": null,
          "storageKey": null
        },
        {
          "kind": "ScalarField",
          "alias": null,
          "name": "importJobNumber",
          "args": null,
          "storageKey": null
        },
        (v2/*: any*/),
        {
          "kind": "LinkedField",
          "alias": null,
          "name": "workflow",
          "storageKey": null,
          "args": null,
          "concreteType": "Workflow",
          "plural": false,
          "selections": [
            (v0/*: any*/),
            {
              "kind": "LinkedField",
              "alias": null,
              "name": "states",
              "storageKey": null,
              "args": null,
              "concreteType": "WorkflowState",
              "plural": true,
              "selections": [
                (v0/*: any*/),
                (v2/*: any*/),
                {
                  "kind": "ScalarField",
                  "alias": null,
                  "name": "isRequired",
                  "args": null,
                  "storageKey": null
                },
                (v3/*: any*/)
              ]
            },
            {
              "kind": "LinkedField",
              "alias": null,
              "name": "transitions",
              "storageKey": null,
              "args": null,
              "concreteType": "WorkflowTransition",
              "plural": true,
              "selections": [
                (v0/*: any*/),
                {
                  "kind": "LinkedField",
                  "alias": null,
                  "name": "toWorkflowState",
                  "storageKey": null,
                  "args": null,
                  "concreteType": "WorkflowState",
                  "plural": false,
                  "selections": (v1/*: any*/)
                },
                {
                  "kind": "LinkedField",
                  "alias": null,
                  "name": "fromWorkflowState",
                  "storageKey": null,
                  "args": null,
                  "concreteType": "WorkflowState",
                  "plural": false,
                  "selections": (v1/*: any*/)
                }
              ]
            }
          ]
        },
        {
          "kind": "LinkedField",
          "alias": null,
          "name": "states",
          "storageKey": null,
          "args": null,
          "concreteType": "JobState",
          "plural": true,
          "selections": [
            (v0/*: any*/),
            {
              "kind": "ScalarField",
              "alias": null,
              "name": "isEnabled",
              "args": null,
              "storageKey": null
            },
            {
              "kind": "ScalarField",
              "alias": null,
              "name": "status",
              "args": null,
              "storageKey": null
            },
            {
              "kind": "LinkedField",
              "alias": null,
              "name": "workflowState",
              "storageKey": null,
              "args": null,
              "concreteType": "WorkflowState",
              "plural": false,
              "selections": [
                (v0/*: any*/),
                (v2/*: any*/),
                (v3/*: any*/),
                {
                  "kind": "ScalarField",
                  "alias": null,
                  "name": "isAssignable",
                  "args": null,
                  "storageKey": null
                },
                {
                  "kind": "LinkedField",
                  "alias": null,
                  "name": "machineType",
                  "storageKey": null,
                  "args": null,
                  "concreteType": "MachineType",
                  "plural": false,
                  "selections": (v1/*: any*/)
                },
                {
                  "kind": "LinkedField",
                  "alias": null,
                  "name": "form",
                  "storageKey": null,
                  "args": null,
                  "concreteType": "Form",
                  "plural": false,
                  "selections": (v1/*: any*/)
                }
              ]
            },
            {
              "kind": "FragmentSpread",
              "name": "UpdateJobStateModal_jobState",
              "args": null
            },
            {
              "kind": "FragmentSpread",
              "name": "JobStateGrid_jobState",
              "args": null
            }
          ]
        },
        {
          "kind": "LinkedField",
          "alias": null,
          "name": "order",
          "storageKey": null,
          "args": null,
          "concreteType": "Order",
          "plural": false,
          "selections": [
            (v0/*: any*/),
            {
              "kind": "LinkedField",
              "alias": null,
              "name": "customer",
              "storageKey": null,
              "args": null,
              "concreteType": "Customer",
              "plural": false,
              "selections": [
                (v0/*: any*/),
                (v2/*: any*/)
              ]
            }
          ]
        },
        {
          "kind": "FragmentSpread",
          "name": "UpdateJobStateModal_job",
          "args": null
        }
      ]
    }
  ]
};
})();
// prettier-ignore
(node/*: any*/).hash = '3d81953bd3c00f1f120e9297fcca5117';
module.exports = node;
