// @flow

import styled from 'styled-components';
import circleAngleDown from './img/circleAngleDown.svg';
import circleAngleLeft from './img/circleAngleLeft.svg';
import circleAngleRight from './img/circleAngleRight.svg';
import circleAngleUp from './img/circleAngleUp.svg';
import circleArrowUp from './img/circleArrowUp.svg';
import circleArrowDown from './img/circleArrowDown.svg';
import circleArrowLeft from './img/circleArrowLeft.svg';
import circleArrowRight from './img/circleArrowRight.svg';
import circleClose from './img/circleClose.svg';
import circleCustomers from './img/circleCustomers.svg';
import circleDashboard from './img/circleDashboard.svg';
import circleEdit from './img/circleEdit.svg';
import circleCheckGreen from './img/circleCheckGreen.svg';
import circleCheckBlue from './img/circleCheckBlue.svg';
import circleCheckOrange from './img/circleCheckOrange.svg';
import circleCheckGrey from './img/circleCheckGrey.svg';
import circleHelp from './img/circleHelp.svg';
import circleInfo from './img/circleInfo.svg';
import circleJobs from './img/circleJobs.svg';
import circleLogout from './img/circleLogout.svg';
import circleMenu from './img/circleMenu.svg';
import circleContextMenu from './img/circleContextMenu.svg';
import circleMinus from './img/circleMinus.svg';
import circlePlus from './img/circlePlus.svg';
import circleProductionSchedule from './img/circleProductionSchedule.svg';
import circleOrders from './img/circleOrders.svg';
import circleAlertRed from './img/circleAlertRed.svg';
import circleSettings from './img/circleSettings.svg';
import circleSearch from './img/circleSearch.svg';
import circleBluePlus from './img/circleBluePlus.svg';
import circleNewWindow from './img/circleNewWindow.svg';
import circleInventory from './img/circleInventory.svg';
import circleTrash from './img/circleTrash.svg';
import circleGearGreen from './img/circleGearGreen.svg';
import circleGearGrey from './img/circleGearGrey.svg';
import circleGearOrange from './img/circleGearOrange.svg';
import circleReports from './img/circleReports.svg';
import circleLot from './img/circleLot.svg';
import downArrow from './img/downArrow.svg';
import calendar from './img/calendar.svg';
import rightArrow from './img/rightArrow.svg';
import moreDownArrow from './img/moreDownArrow.svg';
import eyeActive from './img/eyeActive.svg';
import eyeInactive from './img/eyeInactive.svg';
import inventoryTransfer from './img/inventoryTransfer.svg';
import inventoryDeduct from './img/inventoryDeduct.svg';
import inventoryAdd from './img/inventoryAdd.svg';
import drag from './img/drag.svg';
import squareJobCreated from './img/squareJobCreated.svg';
import squareJobCreatedActive from './img/squareJobCreatedActive.svg';
import squareJobCompleted from './img/squareJobCompleted.svg';
import squareJobCompletedActive from './img/squareJobCompletedActive.svg';
import circleRun from './img/circleRun.svg';
import circleRunActive from './img/circleRunActive.svg';
import circleTask from './img/circleTask.svg';
import circleTaskActive from './img/circleTaskActive.svg';
import circleQuestion from './img/circleQuestion.svg';
import circleMinusBlack from './img/circleMinusBlack.svg';
import circlePlusBlack from './img/circlePlusBlack.svg';
import customer from './img/customer.svg';
import item from './img/item.svg';
import job from './img/job.svg';
import lot from './img/lot.svg';
import order from './img/order.svg';
import tag from './img/tag.svg';

const StyledIcon = styled.div`
  width: 32px;
  height: 32px;
  flex-shrink: 0;

  @media (min-width: 1000px) {
    &:hover {
      cursor: ${p => (p.onClick ? 'pointer' : 'default')};
    }
  }
`;

type IconMapType = {
  [key: string]: {
    component: *,
    width?: number,
    height?: number,
  },
};

const IconMap: IconMapType = {
  'circle-angle-down': {
    component: StyledIcon.withComponent(circleAngleDown),
  },
  'circle-angle-left': {
    component: StyledIcon.withComponent(circleAngleLeft),
  },
  'circle-angle-right': {
    component: StyledIcon.withComponent(circleAngleRight),
  },
  'circle-angle-up': {
    component: StyledIcon.withComponent(circleAngleUp),
  },
  'circle-arrow-up': {
    component: StyledIcon.withComponent(circleArrowUp),
  },
  'circle-arrow-down': {
    component: StyledIcon.withComponent(circleArrowDown),
  },
  'circle-arrow-left': {
    component: StyledIcon.withComponent(circleArrowLeft),
  },
  'circle-arrow-right': {
    component: StyledIcon.withComponent(circleArrowRight),
  },
  'circle-close': {
    component: StyledIcon.withComponent(circleClose),
  },
  'circle-customers': {
    component: StyledIcon.withComponent(circleCustomers),
  },
  'circle-dashboard': {
    component: StyledIcon.withComponent(circleDashboard),
  },
  'circle-edit': {
    component: StyledIcon.withComponent(circleEdit),
  },
  'circle-check-grey': {
    component: StyledIcon.withComponent(circleCheckGrey),
  },
  'circle-check-green': {
    component: StyledIcon.withComponent(circleCheckGreen),
  },
  'circle-check-orange': {
    component: StyledIcon.withComponent(circleCheckOrange),
  },
  'circle-check-blue': {
    component: StyledIcon.withComponent(circleCheckBlue),
  },
  'circle-help': {
    component: StyledIcon.withComponent(circleHelp),
  },
  'circle-info': {
    component: StyledIcon.withComponent(circleInfo),
  },
  'circle-jobs': {
    component: StyledIcon.withComponent(circleJobs),
  },
  'circle-logout': {
    component: StyledIcon.withComponent(circleLogout),
  },
  'circle-menu': {
    component: StyledIcon.withComponent(circleMenu),
  },
  'circle-context-menu': {
    component: StyledIcon.withComponent(circleContextMenu),
  },
  'circle-minus': {
    component: StyledIcon.withComponent(circleMinus),
  },
  'circle-plus': {
    component: StyledIcon.withComponent(circlePlus),
  },
  'circle-production-schedule': {
    component: StyledIcon.withComponent(circleProductionSchedule),
  },
  'circle-orders': {
    component: StyledIcon.withComponent(circleOrders),
  },
  'circle-alert-red': {
    component: StyledIcon.withComponent(circleAlertRed),
  },
  'circle-settings': {
    component: StyledIcon.withComponent(circleSettings),
  },
  'circle-search': {
    component: StyledIcon.withComponent(circleSearch),
  },
  'circle-blue-plus': {
    component: StyledIcon.withComponent(circleBluePlus),
  },
  'circle-new-window': {
    component: StyledIcon.withComponent(circleNewWindow),
  },
  'circle-inventory': {
    component: StyledIcon.withComponent(circleInventory),
  },
  'circle-trash': {
    component: StyledIcon.withComponent(circleTrash),
  },
  'circle-gear-green': {
    component: StyledIcon.withComponent(circleGearGreen),
  },
  'circle-gear-grey': {
    component: StyledIcon.withComponent(circleGearGrey),
  },
  'circle-gear-orange': {
    component: StyledIcon.withComponent(circleGearOrange),
  },
  'circle-reports': {
    component: StyledIcon.withComponent(circleReports),
  },
  'down-arrow': {
    component: StyledIcon.withComponent(downArrow),
  },
  'right-arrow': {
    component: StyledIcon.withComponent(rightArrow),
    width: 20,
    height: 20,
  },
  calendar: {
    component: StyledIcon.withComponent(calendar),
    width: 20,
    height: 20,
  },
  'more-down-arrow': {
    component: StyledIcon.withComponent(moreDownArrow),
  },
  'eye-inactive': {
    component: StyledIcon.withComponent(eyeInactive),
  },
  'eye-active': {
    component: StyledIcon.withComponent(eyeActive),
    width: 32,
    height: 19,
  },
  'inventory-transfer': {
    component: StyledIcon.withComponent(inventoryTransfer),
  },
  'inventory-deduct': {
    component: StyledIcon.withComponent(inventoryDeduct),
  },
  'inventory-add': {
    component: StyledIcon.withComponent(inventoryAdd),
  },
  'circle-lot': {
    component: StyledIcon.withComponent(circleLot),
  },
  drag: {
    component: StyledIcon.withComponent(drag),
    width: 56,
    height: 56,
  },
  'square-job-created': {
    component: StyledIcon.withComponent(squareJobCreated),
    width: 56,
    height: 56,
  },
  'square-job-created-active': {
    component: StyledIcon.withComponent(squareJobCreatedActive),
    width: 56,
    height: 56,
  },
  'square-job-completed': {
    component: StyledIcon.withComponent(squareJobCompleted),
    width: 56,
    height: 56,
  },
  'square-job-completed-active': {
    component: StyledIcon.withComponent(squareJobCompletedActive),
    width: 56,
    height: 56,
  },
  'circle-run': {
    component: StyledIcon.withComponent(circleRun),
    width: 56,
    height: 56,
  },
  'circle-run-active': {
    component: StyledIcon.withComponent(circleRunActive),
    width: 56,
    height: 56,
  },
  'circle-task': {
    component: StyledIcon.withComponent(circleTask),
    width: 56,
    height: 56,
  },
  'circle-task-active': {
    component: StyledIcon.withComponent(circleTaskActive),
    width: 56,
    height: 56,
  },
  tag: {
    component: StyledIcon.withComponent(tag),
    width: 10,
    height: 10,
  },
  'circle-minus-black': {
    component: StyledIcon.withComponent(circleMinusBlack),
    width: 20,
    height: 20,
  },
  'circle-plus-black': {
    component: StyledIcon.withComponent(circlePlusBlack),
    width: 20,
    height: 20,
  },
  'circle-question': {
    component: StyledIcon.withComponent(circleQuestion),
    width: 16,
    height: 16,
  },
  customer: {
    component: StyledIcon.withComponent(customer),
  },
  item: {
    component: StyledIcon.withComponent(item),
  },
  job: {
    component: StyledIcon.withComponent(job),
  },
  lot: {
    component: StyledIcon.withComponent(lot),
  },
  order: {
    component: StyledIcon.withComponent(order),
  },
};

export default IconMap;
