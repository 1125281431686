// @flow

import React from 'react';
import { i18n } from 'shared/utils';
import FieldTypeConfigs from './FieldTypeConfigs';

/**
 * Render a form field component based on the passed in FormField
 * @param {Object} formField
 * @param {Object} props
 * @return {React$Node}
 */
export default ({ form, formField, creatingNewRecord, ...props }: *) => {
  // Retrieve the component config
  const componentConfig =
    FieldTypeConfigs[formField.type] || FieldTypeConfigs.TEXT;

  // Retrieve the component config's props
  const componentConfigProps = componentConfig.props
    ? componentConfig.props({ form, formField, ...props })
    : {};

  // Build the form field component's props
  const componentProps = {
    name: formField.id,
    label:
      formField.name +
      (formField.applicationType === 'OPERATOR_PORTAL'
        ? ' ' + i18n.t('(logged by operator)')
        : ''),
    placeholder: formField.placeholder,
    description: formField.description,
    creatable: formField.isCreatable,
    message: creatingNewRecord
      ? i18n.t(`A new {{fieldName}} will be created`, {
          fieldName: formField.name,
        })
      : null,
    ...componentConfigProps,
    ...(props || {}),
  };

  return <componentConfig.component {...componentProps} />;
};
