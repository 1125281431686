// @flow

import React, { PureComponent } from 'react';
import styled from 'styled-components';
import { formStyles } from 'shared/styleguide';
import type { FormikProps, FieldProps } from 'formik';
import type { FieldSize } from './types';
import searchIcon from './img/searchIcon.svg';

type Props = {
  form?: FormikProps<string>,
  field?: $PropertyType<FieldProps, 'field'>,
  type?: 'text' | 'email' | 'number',
  error?: string,
  placeholder?: string,
  value: string,
  thin?: boolean,
  size?: FieldSize,
  disabled?: boolean,
  onChange?: string => void,
  onKeyDown?: KeyboardEvent => void,
  onBlur?: Event => void,
  autoFocus?: boolean,
};

const Wrapper = styled.div`
  position: relative;
  ${formStyles.fieldWidth};
`;

const StyledInput = styled.input`
  position: relative;
  padding: 0 10px 0 50px;
  ${formStyles.field};
`;

const SearchIcon = styled(searchIcon)`
  width: 22px;
  height: 22px;
  position: absolute;
  left: 16px;
  top: 50%;
  transform: translateY(-50%);
  opacity: ${p => (p.disabled ? 0.4 : 1)};
`;

class SearchBar extends PureComponent<Props> {
  static defaultProps = {
    form: undefined,
    field: undefined,
    type: 'text',
    error: undefined,
    placeholder: undefined,
    thin: false,
    size: undefined,
    disabled: false,
    onChange: () => {},
    onKeyDown: () => {},
    onBlur: () => {},
    autoFocus: false,
  };

  handleBlur = (e: Event) => {
    const { form, field, onBlur } = this.props;

    if (form && field) {
      form.setFieldTouched(field.name, true);
    }

    if (onBlur) {
      onBlur(e);
    }
  };

  handleChange = (e: SyntheticEvent<HTMLInputElement>) => {
    const { form, field, onChange } = this.props;

    if (form && field) {
      form.setFieldValue(field.name, e.currentTarget.value);
    }

    if (onChange) {
      onChange(e.currentTarget.value);
    }
  };

  handleKeyDown = (e: KeyboardEvent, ...args: *) => {
    const { form, field, onKeyDown } = this.props;

    if (e.key === 'Enter' && form && field && form.handleSubmit) {
      form.handleSubmit(e, ...args);
    }

    if (onKeyDown) {
      onKeyDown(e, ...args);
    }
  };

  render() {
    const {
      field,
      type,
      error,
      placeholder,
      value,
      thin,
      size,
      disabled,
      autoFocus,
    } = this.props;

    return (
      <Wrapper size={size}>
        <StyledInput
          onChange={this.handleChange}
          onKeyDown={this.handleKeyDown}
          onBlur={this.handleBlur}
          type={type}
          placeholder={placeholder}
          value={value !== undefined ? value : field?.value}
          error={error}
          thin={thin || false}
          size={size}
          disabled={disabled}
          autoFocus={autoFocus}
        />
        <SearchIcon disabled={disabled} />
      </Wrapper>
    );
  }
}

export default SearchBar;
