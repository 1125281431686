/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ReaderFragment } from 'relay-runtime';
export type RunStatus = "COMPLETE" | "IN_PROGRESS" | "PAUSED" | "SCHEDULED";
import type { FragmentReference } from "relay-runtime";
declare export opaque type SetupStartModal_run$ref: FragmentReference;
declare export opaque type SetupStartModal_run$fragmentType: SetupStartModal_run$ref;
export type SetupStartModal_run = {|
  +id: string,
  +status: RunStatus,
  +machine: {|
    +id: string,
    +type: {|
      +id: string,
      +name: string,
    |},
  |},
  +$refType: SetupStartModal_run$ref,
|};
export type SetupStartModal_run$data = SetupStartModal_run;
export type SetupStartModal_run$key = {
  +$data?: SetupStartModal_run$data,
  +$fragmentRefs: SetupStartModal_run$ref,
};
*/


const node/*: ReaderFragment*/ = (function(){
var v0 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "id",
  "args": null,
  "storageKey": null
};
return {
  "kind": "Fragment",
  "name": "SetupStartModal_run",
  "type": "Run",
  "metadata": null,
  "argumentDefinitions": [],
  "selections": [
    (v0/*: any*/),
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "status",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "LinkedField",
      "alias": null,
      "name": "machine",
      "storageKey": null,
      "args": null,
      "concreteType": "Machine",
      "plural": false,
      "selections": [
        (v0/*: any*/),
        {
          "kind": "LinkedField",
          "alias": null,
          "name": "type",
          "storageKey": null,
          "args": null,
          "concreteType": "MachineType",
          "plural": false,
          "selections": [
            (v0/*: any*/),
            {
              "kind": "ScalarField",
              "alias": null,
              "name": "name",
              "args": null,
              "storageKey": null
            }
          ]
        }
      ]
    }
  ]
};
})();
// prettier-ignore
(node/*: any*/).hash = '88c934230afd9af360575fa13088b1f0';
module.exports = node;
