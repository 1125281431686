// @flow

import { graphql } from 'react-relay';
import createRelayMutation from 'shared/gql/createRelayMutation';

const mutation = graphql`
  mutation UpdateRunSetupPauseMutation($input: UpdateRunSetupPauseInput!) {
    updateRunSetupPause(input: $input) {
      runEdge {
        cursor
        node {
          id
          status
          scheduledStartAt
          scheduledEndAt
          ...RunControls_run
          ...SetupEndModal_run
          ...RunEndModal_run
          ...RunDetails_run
          ...RunYields_run
          ...RunLog_run
        }
      }
    }
  }
`;

export default {
  commit: createRelayMutation.bind(null, mutation),
};
