// @flow

import React from 'react';
import moment from 'moment';
import { createFragmentContainer, graphql } from 'react-relay';
import styled from 'styled-components';
import { colors, fonts } from 'shared/styleguide';
import { i18n } from 'shared/utils';
import FatButton from 'shared/components/common/FatButton';
import TimeElapsedTicker from 'shared/components/common/TimeElapsedTicker';
import ProgressPanel from './ProgressPanel';
import pauseIcon from './img/pauseIcon.svg';
import type { RunControls_run as RunFragment } from './__generated__/RunControls_run';

type Props = {
  run: RunFragment,
  onSetupStart: () => void,
  onSetupPause: () => void,
  onSetupEnd: () => void,
  onRunStart: () => void,
  onRunPause: () => void,
  onRunEnd: () => void,
};

const StartButtonsWrapper = styled.div`
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  align-items: center;
`;

const StartButton = styled.div`
  text-align: center;
  padding: 10px;
  width: 135px;
  height: 135px;
  border: 1px solid ${colors.paleGrey};
  border-radius: 10px;
  box-shadow: ${p => (p.complete ? 'none' : '0 0 6px 0 rgba(66, 68, 76, 0.3)')};
  display: flex;
  flex-flow: column;
  justify-content: center;
  align-items: center;
  color: ${colors.charcoalGrey};
  ${fonts.headerRegular};

  strong {
    ${fonts.headerBold};
  }

  @media (min-width: 800px) {
    width: 160px;
    height: 160px;

    &:hover {
      background-color: ${p =>
        p.complete ? 'transparent' : colors.veryLightBlue};
      cursor: ${p => (p.complete ? 'default' : 'pointer')};
    }
  }
`;

const BottomButtonRow = styled.div`
  display: flex;
  flex-flow: row nowrap;
  align-items: flex-start;
  padding-top: 24px;

  button {
    margin-right: 16px;

    &:last-child {
      margin-right: 0;
    }
  }
`;

const PauseIcon = styled(pauseIcon)`
  width: 32px;
  height: 32px;
  fill: ${colors.charcoalGrey};
`;

const RunControls = ({
  run,
  onSetupStart,
  onSetupPause,
  onSetupEnd,
  onRunStart,
  onRunPause,
  onRunEnd,
}: Props) => {
  const lastLogEntry = run.logs[run.logs.length - 1];
  const runComplete = Boolean(run.logs.find(log => log.type === 'RUN_END'));
  // Setup is automatically considered complete if a run has been started
  const setupComplete = Boolean(
    run.logs.find(log => log.type === 'RUN_START' || log.type === 'SETUP_END'),
  );
  const startTime = lastLogEntry ? moment(lastLogEntry.createdAt) : moment();
  const isPaused = run.status === 'PAUSED';

  if (
    lastLogEntry &&
    (lastLogEntry.type === 'SETUP_START' || lastLogEntry.type === 'SETUP_PAUSE')
  ) {
    return (
      <>
        <div style={{ paddingBottom: 16 }}>
          <ProgressPanel
            label={
              isPaused
                ? i18n.t('TAP TO RESUME SETUP')
                : i18n.t('SETUP IN PROGRESS')
            }
            startTime={startTime.subtract(run.setupTotalSeconds, 'seconds')}
            endTime={isPaused ? moment(lastLogEntry.createdAt) : undefined}
            user={lastLogEntry.user}
            onClick={isPaused ? onSetupStart : undefined}
          />
        </div>
        <FatButton onClick={onSetupEnd} disabled={isPaused}>
          {i18n.t('FINISH SETUP')}
        </FatButton>
        <BottomButtonRow>
          {!isPaused && (
            <FatButton theme="white" thin onClick={onSetupPause}>
              <PauseIcon />
            </FatButton>
          )}
        </BottomButtonRow>
      </>
    );
  }

  if (
    lastLogEntry &&
    (lastLogEntry.type === 'RUN_START' || lastLogEntry.type === 'RUN_PAUSE')
  ) {
    return (
      <>
        <div style={{ paddingBottom: 16 }}>
          <ProgressPanel
            label={
              isPaused ? i18n.t('TAP TO RESUME RUN') : i18n.t('RUN IN PROGRESS')
            }
            startTime={startTime.subtract(run.runTotalSeconds, 'seconds')}
            endTime={isPaused ? moment(lastLogEntry.createdAt) : undefined}
            user={lastLogEntry.user}
            onClick={isPaused ? onRunStart : undefined}
          />
        </div>
        <FatButton onClick={onRunEnd} disabled={isPaused}>
          {i18n.t('FINISH RUN')}
        </FatButton>
        <BottomButtonRow>
          {!isPaused && (
            <FatButton theme="white" thin onClick={onRunPause}>
              <PauseIcon />
            </FatButton>
          )}
        </BottomButtonRow>
      </>
    );
  }

  return (
    <StartButtonsWrapper>
      <StartButton
        complete={setupComplete}
        onClick={setupComplete ? undefined : onSetupStart}
      >
        {setupComplete ? (
          <>
            <div style={{ paddingBottom: 8 }}>{i18n.t('Setup Complete')}</div>
            <strong>
              <TimeElapsedTicker
                startTime={moment().subtract(run.setupTotalSeconds, 'seconds')}
                endTime={moment()}
              />
            </strong>
          </>
        ) : (
          <strong>{i18n.t('START SETUP')}</strong>
        )}
      </StartButton>
      <StartButton
        complete={runComplete}
        onClick={runComplete ? undefined : onRunStart}
      >
        {runComplete ? (
          <>
            <div style={{ paddingBottom: 8 }}>{i18n.t('Run Complete')}</div>
            <strong>
              <TimeElapsedTicker
                startTime={moment().subtract(run.runTotalSeconds, 'seconds')}
                endTime={moment()}
              />
            </strong>
          </>
        ) : (
          <strong>{i18n.t('START RUN')}</strong>
        )}
      </StartButton>
    </StartButtonsWrapper>
  );
};

export default createFragmentContainer(RunControls, {
  run: graphql`
    fragment RunControls_run on Run {
      id
      setupTotalSeconds
      runTotalSeconds
      status
      logs {
        id
        type
        notes
        createdAt
        user {
          id
          ...ProgressPanel_user
        }
      }
    }
  `,
});
