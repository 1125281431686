/**
 * @flow
 * @relayHash fa2b44aada7cc25cf26272f8b2af8d42
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type AddLotInput = {|
  lotNumber: string,
  binNumber?: ?string,
  expiresAt?: ?any,
|};
export type AddLotMutationVariables = {|
  input: AddLotInput
|};
export type AddLotMutationResponse = {|
  +addLot: {|
    +lotEdge: {|
      +node: {|
        +id: string,
        +lotNumber: string,
        +binNumber: ?string,
        +expiresAt: ?any,
      |}
    |}
  |}
|};
export type AddLotMutation = {|
  variables: AddLotMutationVariables,
  response: AddLotMutationResponse,
|};
*/


/*
mutation AddLotMutation(
  $input: AddLotInput!
) {
  addLot(input: $input) {
    lotEdge {
      node {
        id
        lotNumber
        binNumber
        expiresAt
      }
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "input",
    "type": "AddLotInput!",
    "defaultValue": null
  }
],
v1 = [
  {
    "kind": "LinkedField",
    "alias": null,
    "name": "addLot",
    "storageKey": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "AddLotPayload",
    "plural": false,
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "lotEdge",
        "storageKey": null,
        "args": null,
        "concreteType": "LotEdge",
        "plural": false,
        "selections": [
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "node",
            "storageKey": null,
            "args": null,
            "concreteType": "Lot",
            "plural": false,
            "selections": [
              {
                "kind": "ScalarField",
                "alias": null,
                "name": "id",
                "args": null,
                "storageKey": null
              },
              {
                "kind": "ScalarField",
                "alias": null,
                "name": "lotNumber",
                "args": null,
                "storageKey": null
              },
              {
                "kind": "ScalarField",
                "alias": null,
                "name": "binNumber",
                "args": null,
                "storageKey": null
              },
              {
                "kind": "ScalarField",
                "alias": null,
                "name": "expiresAt",
                "args": null,
                "storageKey": null
              }
            ]
          }
        ]
      }
    ]
  }
];
return {
  "kind": "Request",
  "fragment": {
    "kind": "Fragment",
    "name": "AddLotMutation",
    "type": "Mutation",
    "metadata": null,
    "argumentDefinitions": (v0/*: any*/),
    "selections": (v1/*: any*/)
  },
  "operation": {
    "kind": "Operation",
    "name": "AddLotMutation",
    "argumentDefinitions": (v0/*: any*/),
    "selections": (v1/*: any*/)
  },
  "params": {
    "operationKind": "mutation",
    "name": "AddLotMutation",
    "id": null,
    "text": "mutation AddLotMutation(\n  $input: AddLotInput!\n) {\n  addLot(input: $input) {\n    lotEdge {\n      node {\n        id\n        lotNumber\n        binNumber\n        expiresAt\n      }\n    }\n  }\n}\n",
    "metadata": {}
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = 'caeba1c53314778231e92458bb1674a0';
module.exports = node;
