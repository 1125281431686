// @flow

import { applyMiddleware, createStore } from 'redux';
import reduxThunk from 'redux-thunk';
import RootReducer from './Reducer';

// $FlowFixMe
const createStoreWithMiddleware = applyMiddleware(reduxThunk)(createStore);

const store = createStoreWithMiddleware(RootReducer);

export default store;
