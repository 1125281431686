// @flow

import { css } from 'styled-components';
import colors from './colors';
import fonts from './fonts';

export const INPUT_HEIGHT = 34;
export const INPUT_HEIGHT_THIN = 34;
export const INPUT_BORDER_RADIUS = 5;

export const fieldWidth = css`
  width: 100%;

  @media (min-width: 1000px) {
    width: ${p => {
      if (p.size === 'extra-small') {
        return '120px';
      }

      if (p.size === 'small') {
        return '256px';
      }

      if (p.size === 'medium') {
        return '480px';
      }

      return '100%';
    }};
  }
`;

export const fieldFont = css`
  ${fonts.bodyRegular};
  color: ${colors.charcoalGrey};
`;

export const fieldBorder = css`
  border: 1px solid ${colors.lightBlueGrey};
`;

export const fieldDisabled = css`
  opacity: 0.5;
`;

export const fieldErrorBorder = css`
  border: 1px solid ${colors.cherry};
`;

export const fieldFocus = css`
  border: 1px solid ${colors.azul};
  background-color: ${colors.paleGreyTwo};
  box-shadow: none;
`;

export const fieldPlaceholderFont = css`
  color: ${colors.coolGrey};
`;

export const field = css`
  ${fieldFont};
  ${fieldBorder};
  ${fieldWidth};
  border-radius: ${INPUT_BORDER_RADIUS}px;
  background-color: ${colors.white};
  height: ${p => (p.thin ? INPUT_HEIGHT_THIN : INPUT_HEIGHT)}px;
  line-height: ${INPUT_HEIGHT}px;
  outline: none;

  :focus {
    ${fieldFocus};
  }

  ::placeholder {
    ${fieldPlaceholderFont};
  }

  :disabled {
    ${fieldDisabled};
  }

  ${p => (p.error ? fieldErrorBorder : '')};
`;
