// @flow

import React, { PureComponent } from 'react';
import { i18n } from 'shared/utils';
import { graphql, fetchQuery } from 'react-relay';
import relayEnvironment from 'shared/gql/relayEnvironment';
import { FieldGroup } from 'shared/components/form';

type Props = {
  name?: string,
  rawField?: boolean,
  label?: ?string,
  error?: ?string,
  queryVariables?: ?Object,
  excludeIds?: Array<string>,
  isSearchable?: boolean,
};

type State = {
  fetchedOptions: Array<Object>,
};

const QUERY = graphql`
  query SelectFormFieldQuery($types: [FormType!]) {
    forms(types: $types) {
      edges {
        node {
          id
          name
          type
        }
      }
    }
  }
`;

class SelectFormField extends PureComponent<Props, State> {
  static defaultProps = {
    name: undefined,
    rawField: false,
    label: undefined,
    error: undefined,
    excludeIds: [],
    queryVariables: {},
    isSearchable: undefined,
  };

  mounted = false;

  state = {
    fetchedOptions: [],
  };

  componentDidMount() {
    this.mounted = true;

    this.getData();
  }

  componentWillUnmount() {
    this.mounted = false;
  }

  /**
   * Massage records to be ReactSelect-friendly
   * @param {Array<Object>} records
   * @param {Array<string | number>} excludeIds
   * @return {Array<Object>}
   */
  massageData = (
    records: Array<Object>,
    excludeIds?: Array<string> = [],
  ): Array<Object> => {
    return (
      records
        // filter out specified ids
        .filter(a => !a.node || excludeIds.indexOf(a.node.id.toString()) < 0)
        .map(({ node }) => ({
          data: node,
          value: node.id,
          label: node.name,
        }))
    );
  };

  getData = async (inputValue?: string) => {
    const { queryVariables, excludeIds } = this.props;

    try {
      const results = await fetchQuery(relayEnvironment, QUERY, {
        search: inputValue,
        ...queryVariables,
      });

      const options = this.massageData(results.forms.edges || [], excludeIds);

      if (this.mounted) {
        this.setState({
          fetchedOptions: options,
        });
      }

      return options;
    } catch (e) {
      return [];
    }
  };

  render() {
    const { name, rawField, label, error, ...fieldProps } = this.props;
    const { fetchedOptions } = this.state;

    return (
      <FieldGroup
        name={name}
        rawField={rawField || false}
        label={label !== undefined ? label : i18n.t('Form')}
        error={error}
        clearable
        placeholder={i18n.t('Form Name')}
        {...fieldProps}
        type="select"
        options={fetchedOptions}
        // isLoading={!query.props}
        // loadOptions={this.getData}
        defaultOptions
      />
    );
  }
}

export default SelectFormField;
