// @flow

import React from 'react';
import IconMap from './IconMap';

export type Props = {
  type: $Keys<typeof IconMap>,
  style?: Object,
  size?: number,
  onClick?: Event => void,
};

const Icon = ({ type, style, size, onClick }: Props) => {
  const ChosenIcon = IconMap[type];

  const computedWidth = size || ChosenIcon.width || 32;
  const computedHeight = size || ChosenIcon.height || 32;

  return (
    <ChosenIcon.component
      onClick={onClick}
      style={{ width: computedWidth, height: computedHeight, ...style }}
    />
  );
};

Icon.defaultProps = {
  style: {},
  size: undefined,
  onClick: undefined,
};

export default Icon;
