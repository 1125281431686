// @flow

// Determine which configuration file to use, based on the web url
let config = __CONFIG__.filter(c =>
  RegExp(`^http(s|)://(${c.url})`).test(window.location.href),
)[0];

const localConfig = __CONFIG__.find(c => c.env === 'local');

// If gitignore'd local.json config file is found, allow it to take precedence
if (localConfig) {
  delete localConfig.env;
  config = { ...config, ...localConfig };
}

if (!config) {
  config = __CONFIG__.filter(c => c.env === 'development')[0];
}

export default config;
