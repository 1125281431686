// @flow

import React from 'react';
import { QueryRenderer, graphql } from 'react-relay';
import type { RouterHistory } from 'react-router';
import relayEnvironment from 'shared/gql/relayEnvironment';
import * as Yup from 'yup';
import styled from 'styled-components';
import { i18n } from 'shared/utils';
import * as Actions from 'operator-portal/store/Actions';
import { Formik, Form, SelectMachineField } from 'shared/components/form';
import Page from 'operator-portal/components/Page';
import PageHeaderLogo from 'operator-portal/components/PageHeaderLogo';
import Button from 'shared/components/common/Button';
import Loader from 'shared/components/common/Loader';
import { colors, fonts } from 'shared/styleguide';

type Props = {
  history: RouterHistory,
};

const Wrapper = styled.div`
  padding: 24px 16px 16px 16px;

  @media (min-width: 800px) {
    padding-top: 48px;
    margin: 0 auto;
    width: 480px;
  }
`;

const Header = styled.div`
  ${fonts.largeTitle};
  color: ${colors.azul};
  padding-bottom: 24px;
`;

const SubHeader = styled.div`
  ${fonts.bodyRegular};
  color: ${colors.charcoalGrey};
  padding-bottom: 42px;
`;

const Divider = styled.div`
  border-bottom: 1px solid ${colors.lightBlueGrey};
  margin: 16px 0 40px 0;
`;

export default ({ history }: Props) => (
  <QueryRenderer
    environment={relayEnvironment}
    query={graphql`
      query ChooseMachineQuery {
        viewer {
          id
          company {
            id
            name
          }
        }
      }
    `}
    render={query => {
      if (!query.props) {
        return <Loader />;
      }

      if (!query.props.viewer) {
        location.href = '/';
      }

      return (
        <Page hideMenu noPadding>
          <PageHeaderLogo />
          <Wrapper>
            <Header>
              {i18n.t('{{companyName}} Operator Portal', {
                companyName: query.props.viewer.company.name,
              })}
            </Header>
            <SubHeader>
              {i18n.t(
                'Please select the work center that you will be operating for {{companyName}}.',
                {
                  companyName: query.props.viewer.company.name,
                },
              )}
            </SubHeader>
            <Formik
              initialValues={{
                machine: null,
              }}
              validationSchema={Yup.object().shape({
                machine: Yup.object()
                  .nullable()
                  .required('Required'),
              })}
              onSubmit={async (values, { props, setSubmitting }) => {
                setSubmitting(true);
                Actions.setOperatorMachineId(values.machine.value);
                history.push('/');
              }}
              render={({
                errors,
                values,
                isValid,
                isSubmitting,
                handleSubmit,
              }) => (
                <Form>
                  <SelectMachineField
                    name="machine"
                    error={errors.machine}
                    isSearchable={false}
                  />
                  {/* FIXME: switch out for common divider component */}
                  <Divider />
                  <Button
                    type="submit"
                    disabled={!isValid}
                    loading={isSubmitting}
                    onClick={handleSubmit}
                  >
                    {i18n.t('Continue')}
                  </Button>
                </Form>
              )}
            />
          </Wrapper>
        </Page>
      );
    }}
  />
);
