// @flow

import React, { PureComponent } from 'react';
import { createRefetchContainer, graphql } from 'react-relay';
import type { RouterHistory } from 'react-router-dom';
import { Trans } from 'react-i18next';
import moment from 'moment';
import { i18n } from 'shared/utils';
import Table from 'shared/components/common/Table';
import EmptyListResults from 'shared/components/common/EmptyListResults';
import Pill from 'shared/components/common/Pill';
import RunStatusPill from 'shared/components/common/Pill/RunStatusPill';
import type { RefetchableRunsContainer_runs as RunsFragment } from './__generated__/RefetchableRunsContainer_runs';

type Props = {
  queryVariables: Object,
  runs: RunsFragment,
  history: RouterHistory,
  relay: {
    refetch: Function,
  },
};

class RefetchableRunsContainer extends PureComponent<Props> {
  refetchInterval = null;

  componentDidMount() {
    this.refetchInterval = setInterval(this.handleRefetch, 60000 * 5); // FIXME: change back to 1 minute after relay.refetch is fixed
  }

  componentWillUnmount() {
    if (this.refetchInterval) {
      clearInterval(this.refetchInterval);
    }
  }

  handleRefetch = () => {
    const { relay, queryVariables } = this.props;

    // FIXME: refetch isn't working for some reason. Doing a hard reload for now...
    window.location.reload();

    relay.refetch(queryVariables, null, null, {
      force: true,
    });
  };

  getRunDuration = (node: Object) => {
    if (
      !node ||
      !node.original ||
      !node.original.scheduledStartAt ||
      !node.original.scheduledEndAt
    )
      return null;

    const { scheduledStartAt, scheduledEndAt } = node.original;
    const duration = moment.duration(
      moment(scheduledEndAt).diff(moment(scheduledStartAt)),
    );
    const days = duration.days() > 0 ? `${duration.days()}D ` : '';

    return (
      <span>
        {i18n.t(`${days}${duration.hours()}H ${duration.minutes()}M`)}
      </span>
    );
  };

  render() {
    const { runs, history } = this.props;

    if (!runs.edges?.length) {
      return (
        <EmptyListResults
          graphic="runs"
          message={
            <Trans>
              There are no runs scheduled for this day.
              <br />
              Contact your org administrator to ensure runs are scheduled.
            </Trans>
          }
        />
      );
    }

    const edges = runs.edges ? runs.edges.filter(Boolean).map(a => a.node) : [];

    return (
      <Table
        data={edges}
        onRowClick={rowData => history.push(`/run/${rowData.id}`)}
        columns={[
          {
            Header: i18n.t('Job #'),
            accessor: 'job.jobNumber',
            width: 160,
            headerStyle: {
              textAlign: 'center',
            },
            Cell: ({ value, original }: *) => (
              <Pill>
                {original.job.importJobNumber || original.job.jobNumber}
              </Pill>
            ),
          },
          {
            Header: i18n.t('Customer Name'),
            accessor: 'job.order.customer.name',
            width: 220,
          },
          {
            Header: i18n.t('Project Name'),
            accessor: 'job.name',
          },
          {
            Header: i18n.t('Production Step'),
            accessor: 'jobState.workflowState.name',
            width: 150,
          },
          {
            Header: i18n.t('Items'),
            accessor: 'job.yields.totalCount',
            width: 100,
            Cell: ({ value }: *) => <Pill>{value}</Pill>,
          },
          {
            Header: i18n.t('Scheduled Duration'),
            accessor: 'scheduledStartAt',
            width: 150,
            Cell: (node: *) => this.getRunDuration(node),
          },
          {
            Header: '',
            accessor: 'status',
            width: 120,
            Cell: ({ value }: *) => <RunStatusPill status={value} />,
          },
        ]}
      />
    );
  }
}

export default createRefetchContainer(
  RefetchableRunsContainer,
  {
    runs: graphql`
      fragment RefetchableRunsContainer_runs on RunConnection
        @argumentDefinitions(
          startAt: { type: "DateTime!" }
          endAt: { type: "DateTime!" }
          machineIds: { type: "[ID!]!" }
        ) {
        edges {
          node {
            id
            status
            scheduledStartAt
            scheduledEndAt
            jobState {
              id
              workflowState {
                id
                name
              }
            }
            job {
              id
              importJobNumber
              jobNumber
              name
              yields {
                totalCount
              }
              order {
                id
                customer {
                  id
                  name
                }
              }
            }
          }
        }
      }
    `,
  },
  graphql`
    query RefetchableRunsContainerQuery(
      $startAt: DateTime!
      $endAt: DateTime!
      $machineIds: [ID!]!
    ) {
      runs(
        startAt: $startAt
        endAt: $endAt
        machineIds: $machineIds
        sortBy: { field: SCHEDULED_START_AT, direction: ASC }
      ) {
        ...RefetchableRunsContainer_runs
          @arguments(startAt: $startAt, endAt: $endAt, machineIds: $machineIds)
      }
    }
  `,
);
