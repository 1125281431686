// @flow

import React, { PureComponent } from 'react';
import { createFragmentContainer, graphql } from 'react-relay';
import * as Yup from 'yup';
import styled from 'styled-components';
import { Trans } from 'react-i18next';
import { colors, fonts } from 'shared/styleguide';
import { i18n, Analytics } from 'shared/utils';
import UpdateRunSetupEndMutation from 'shared/mutations/UpdateRunSetupEnd';
import {
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
} from 'shared/components/modal';
import {
  Formik,
  Form,
  FieldGroup,
  FieldLabel,
  FieldDescription,
} from 'shared/components/form';
import Divider from 'shared/components/common/Divider';
import FatButton from 'shared/components/common/FatButton';
import type { SetupEndModal_run as RunFragment } from './__generated__/SetupEndModal_run';

type Props = {
  onClose: () => void,
  run: RunFragment,
};

type State = {
  updateAllYieldsAtOnce: boolean,
};

const Intro = styled.div`
  ${fonts.bodyRegular};
  color: ${colors.charcoalGrey};
  padding-bottom: 24px;

  strong {
    ${fonts.bodyBold};
  }
`;

class SetupEndModal extends PureComponent<Props, State> {
  state = {
    updateAllYieldsAtOnce: true,
  };

  render() {
    const { onClose, run } = this.props;
    const { updateAllYieldsAtOnce } = this.state;
    const {
      jobState: {
        workflowState: { isTrackingSetupQuantity },
      },
    } = run;

    const initialValues: Object = {
      userNumber: '',
      notes: '',
    };

    const validationObj: Object = {
      userNumber: Yup.string().required('Required'),
      notes: Yup.string(),
    };

    const yieldEdges = (run.yields.edges || []).filter(Boolean);

    if (isTrackingSetupQuantity) {
      if (updateAllYieldsAtOnce) {
        initialValues.setupQuantity = '';
        validationObj.setupQuantity = Yup.number().required('Required');
      } else {
        for (const yieldEdge of yieldEdges) {
          initialValues[yieldEdge.node.id + 'setupQuantity'] = '';

          validationObj[
            yieldEdge.node.id + 'setupQuantity'
          ] = Yup.number().required('Required');
        }
      }
    }

    return (
      <Modal maxWidth={700}>
        <Formik
          initialValues={initialValues}
          validationSchema={Yup.object().shape(validationObj)}
          onSubmit={async (values: *, { setSubmitting }: *) => {
            setSubmitting(true);

            const input: Object = {
              runId: run.id,
              userNumber: values.userNumber,
              notes: values.notes,
            };

            if (updateAllYieldsAtOnce) {
              input.setupQuantity = parseFloat(values.setupQuantity || 0);
            } else {
              input.jobYields = yieldEdges.map(yieldEdge => ({
                jobYieldId: yieldEdge.node.id,
                setupQuantity: parseFloat(
                  values[yieldEdge.node.id + 'setupQuantity'] || 0,
                ),
              }));
            }

            try {
              await UpdateRunSetupEndMutation.commit({
                variables: {
                  input,
                },
              });

              onClose();
              Analytics.trackEvent('Setup End', {
                operatorID: values.userNumber,
                notes: values.notes,
                machineType: run.machine?.type?.name,
                totalSetupUnits: parseFloat(values.setupQuantity || 0),
              });
            } catch (e) {
              setSubmitting(false);
              window.alert(e.message);
            }
          }}
          render={({ values, errors, isValid, isSubmitting, handleSubmit }) => (
            <Form>
              <ModalHeader
                header={i18n.t('Complete Setup?')}
                onClose={onClose}
              />
              <ModalBody withPadding>
                <Intro>
                  {isTrackingSetupQuantity ? (
                    <Trans>
                      Enter your <strong>Operator ID</strong> and{' '}
                      <strong>Total Setup Quantity</strong> to complete setup.
                    </Trans>
                  ) : (
                    <Trans>
                      Enter your <strong>Operator ID</strong> to complete setup.
                    </Trans>
                  )}
                </Intro>
                <Divider style={{ marginBottom: 24 }} />
                <FieldGroup
                  name="userNumber"
                  type="number"
                  label={i18n.t('Operator ID')}
                  placeholder={i18n.t('Enter ID')}
                  error={errors.userNumber}
                />
                {isTrackingSetupQuantity && (
                  <>
                    <FieldGroup
                      value={updateAllYieldsAtOnce}
                      type="toggle"
                      label={i18n.t('Update Quantites For All Items')}
                      onChange={() => {
                        this.setState({
                          updateAllYieldsAtOnce: !updateAllYieldsAtOnce,
                        });
                      }}
                      rawField
                    />
                    {updateAllYieldsAtOnce ? (
                      <FieldGroup
                        name="setupQuantity"
                        label={i18n.t('Total Setup Quantity')}
                        placeholder={i18n.t('Enter a number')}
                        type="number"
                        error={errors.setupQuantity}
                      />
                    ) : (
                      yieldEdges.map(yieldEdge => (
                        <React.Fragment key={yieldEdge.node.id}>
                          <FieldLabel>
                            {yieldEdge.node.item.name}&nbsp;
                          </FieldLabel>
                          <FieldDescription>
                            {i18n.t(
                              'Quantity to Produce: {{quantity, number}} {{quantitySymbol}}',
                              {
                                quantity: yieldEdge.node.quantity,
                                quantitySymbol:
                                  yieldEdge.node.item.quantityUOM.symbol,
                              },
                            )}
                            &nbsp;&nbsp;&nbsp;&nbsp;
                            {i18n.t('Part #: {{partNumber}}', {
                              partNumber: yieldEdge.node.item.partNumber,
                            })}
                          </FieldDescription>
                          <FieldGroup
                            name={yieldEdge.node.id + 'setupQuantity'}
                            label={i18n.t('Total Setup Quantity')}
                            extendedLabel={
                              yieldEdge.node.item.quantityUOM.symbol
                            }
                            type="number"
                            placeholder={i18n.t('#')}
                            error={errors[yieldEdge.node.id + 'setupQuantity']}
                          />
                        </React.Fragment>
                      ))
                    )}
                  </>
                )}
                <Divider style={{ marginBottom: 24 }} />
                <FieldGroup
                  name="notes"
                  label={i18n.t('Notes')}
                  placeholder={i18n.t('Type here...')}
                  type="textarea"
                  error={errors.notes}
                  height={80}
                />
              </ModalBody>
              <ModalFooter style={{ display: 'block', textAlign: 'center' }}>
                <FatButton
                  type="submit"
                  theme="blue"
                  disabled={!isValid}
                  loading={isSubmitting}
                  onClick={handleSubmit}
                >
                  {i18n.t('Complete Setup')}
                </FatButton>
              </ModalFooter>
            </Form>
          )}
        />
      </Modal>
    );
  }
}

export default createFragmentContainer(SetupEndModal, {
  run: graphql`
    fragment SetupEndModal_run on Run {
      id
      jobState {
        id
        workflowState {
          id
          isTrackingSetupQuantity
        }
      }
      yields(first: null) {
        edges {
          node {
            id
            quantity
            item {
              id
              name
              partNumber
              quantityUOM {
                id
                symbol
              }
            }
          }
        }
      }
      machine {
        id
        type {
          id
          name
        }
      }
    }
  `,
});
