// @flow

import React from 'react';
import { createFragmentContainer, graphql } from 'react-relay';
import moment from 'moment';
import styled from 'styled-components';
import { i18n } from 'shared/utils';
import { colors, fonts } from 'shared/styleguide';
import TimeElapsedTicker from 'shared/components/common/TimeElapsedTicker';
import UserWidget from 'shared/components/common/QueryWidget/UserWidget';
import FatButton from 'shared/components/common/FatButton';
import type { DowntimeOverlay_downtime as DowntimeFragment } from './__generated__/DowntimeOverlay_downtime';

type Props = {
  downtime: DowntimeFragment,
  onDowntimeEnd: () => void,
};

const Wrapper = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 24px;
`;

const MidSection = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

const Reason = styled.div`
  ${fonts.headerBold};
  color: ${colors.paleRed};
  padding-bottom: 16px;
`;

const TickerWrapper = styled.div`
  ${fonts.tickerBold};
  color: ${colors.paleRed};
  padding-bottom: 16px;
`;

const Notes = styled.div`
  ${fonts.headerRegular};
  color: ${colors.charcoalGrey};
`;

const DowntimeOverlay = ({ downtime, onDowntimeEnd }: Props) => (
  <Wrapper>
    <UserWidget user={downtime.startUser} />
    <MidSection>
      <Reason>{downtime.category.name}</Reason>
      <TickerWrapper>
        <TimeElapsedTicker startTime={moment(downtime.startAt)} />
      </TickerWrapper>
      <Notes>{downtime.startNotes}</Notes>
    </MidSection>
    <FatButton width="100%" onClick={onDowntimeEnd}>
      {i18n.t('RESUME UPTIME')}
    </FatButton>
  </Wrapper>
);

export default createFragmentContainer(DowntimeOverlay, {
  downtime: graphql`
    fragment DowntimeOverlay_downtime on MachineDowntime {
      id
      startNotes
      startAt
      category {
        id
        name
      }
      startUser {
        ...UserWidget_user
      }
    }
  `,
});
