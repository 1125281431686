/**
 * @flow
 * @relayHash 3324208f692e54cdb8e46666657f2d25
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
type DowntimeEndModal_machine$ref = any;
type DowntimeOverlay_downtime$ref = any;
type DowntimeStartModal_machine$ref = any;
export type PageOperatorQueryVariables = {|
  machineId: string,
  skipMachineLookup: boolean,
|};
export type PageOperatorQueryResponse = {|
  +machine?: {|
    +id: string,
    +currentDowntime: ?{|
      +id: string,
      +$fragmentRefs: DowntimeOverlay_downtime$ref,
    |},
    +$fragmentRefs: DowntimeStartModal_machine$ref & DowntimeEndModal_machine$ref,
  |}
|};
export type PageOperatorQuery = {|
  variables: PageOperatorQueryVariables,
  response: PageOperatorQueryResponse,
|};
*/


/*
query PageOperatorQuery(
  $machineId: ID!
  $skipMachineLookup: Boolean!
) {
  machine(id: $machineId) @skip(if: $skipMachineLookup) {
    id
    currentDowntime {
      id
      ...DowntimeOverlay_downtime
    }
    ...DowntimeStartModal_machine
    ...DowntimeEndModal_machine
  }
}

fragment DowntimeOverlay_downtime on MachineDowntime {
  id
  startNotes
  startAt
  category {
    id
    name
  }
  startUser {
    ...UserWidget_user
    id
  }
}

fragment DowntimeStartModal_machine on Machine {
  id
  name
  type {
    id
    name
  }
  currentDowntime {
    id
    category {
      id
      name
    }
  }
}

fragment DowntimeEndModal_machine on Machine {
  id
  name
  type {
    id
    name
  }
  currentDowntime {
    id
  }
}

fragment UserWidget_user on User {
  id
  firstName
  lastName
  ...UserAvatar_user
}

fragment UserAvatar_user on User {
  id
  avatar
  firstName
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "machineId",
    "type": "ID!",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "skipMachineLookup",
    "type": "Boolean!",
    "defaultValue": null
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "id",
    "variableName": "machineId"
  }
],
v2 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "id",
  "args": null,
  "storageKey": null
},
v3 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "name",
  "args": null,
  "storageKey": null
},
v4 = [
  (v2/*: any*/),
  (v3/*: any*/)
];
return {
  "kind": "Request",
  "fragment": {
    "kind": "Fragment",
    "name": "PageOperatorQuery",
    "type": "Query",
    "metadata": null,
    "argumentDefinitions": (v0/*: any*/),
    "selections": [
      {
        "kind": "Condition",
        "passingValue": false,
        "condition": "skipMachineLookup",
        "selections": [
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "machine",
            "storageKey": null,
            "args": (v1/*: any*/),
            "concreteType": "Machine",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              {
                "kind": "LinkedField",
                "alias": null,
                "name": "currentDowntime",
                "storageKey": null,
                "args": null,
                "concreteType": "MachineDowntime",
                "plural": false,
                "selections": [
                  (v2/*: any*/),
                  {
                    "kind": "FragmentSpread",
                    "name": "DowntimeOverlay_downtime",
                    "args": null
                  }
                ]
              },
              {
                "kind": "FragmentSpread",
                "name": "DowntimeStartModal_machine",
                "args": null
              },
              {
                "kind": "FragmentSpread",
                "name": "DowntimeEndModal_machine",
                "args": null
              }
            ]
          }
        ]
      }
    ]
  },
  "operation": {
    "kind": "Operation",
    "name": "PageOperatorQuery",
    "argumentDefinitions": (v0/*: any*/),
    "selections": [
      {
        "kind": "Condition",
        "passingValue": false,
        "condition": "skipMachineLookup",
        "selections": [
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "machine",
            "storageKey": null,
            "args": (v1/*: any*/),
            "concreteType": "Machine",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              {
                "kind": "LinkedField",
                "alias": null,
                "name": "currentDowntime",
                "storageKey": null,
                "args": null,
                "concreteType": "MachineDowntime",
                "plural": false,
                "selections": [
                  (v2/*: any*/),
                  {
                    "kind": "ScalarField",
                    "alias": null,
                    "name": "startNotes",
                    "args": null,
                    "storageKey": null
                  },
                  {
                    "kind": "ScalarField",
                    "alias": null,
                    "name": "startAt",
                    "args": null,
                    "storageKey": null
                  },
                  {
                    "kind": "LinkedField",
                    "alias": null,
                    "name": "category",
                    "storageKey": null,
                    "args": null,
                    "concreteType": "DowntimeCategory",
                    "plural": false,
                    "selections": (v4/*: any*/)
                  },
                  {
                    "kind": "LinkedField",
                    "alias": null,
                    "name": "startUser",
                    "storageKey": null,
                    "args": null,
                    "concreteType": "User",
                    "plural": false,
                    "selections": [
                      (v2/*: any*/),
                      {
                        "kind": "ScalarField",
                        "alias": null,
                        "name": "firstName",
                        "args": null,
                        "storageKey": null
                      },
                      {
                        "kind": "ScalarField",
                        "alias": null,
                        "name": "lastName",
                        "args": null,
                        "storageKey": null
                      },
                      {
                        "kind": "ScalarField",
                        "alias": null,
                        "name": "avatar",
                        "args": null,
                        "storageKey": null
                      }
                    ]
                  }
                ]
              },
              (v3/*: any*/),
              {
                "kind": "LinkedField",
                "alias": null,
                "name": "type",
                "storageKey": null,
                "args": null,
                "concreteType": "MachineType",
                "plural": false,
                "selections": (v4/*: any*/)
              }
            ]
          }
        ]
      }
    ]
  },
  "params": {
    "operationKind": "query",
    "name": "PageOperatorQuery",
    "id": null,
    "text": "query PageOperatorQuery(\n  $machineId: ID!\n  $skipMachineLookup: Boolean!\n) {\n  machine(id: $machineId) @skip(if: $skipMachineLookup) {\n    id\n    currentDowntime {\n      id\n      ...DowntimeOverlay_downtime\n    }\n    ...DowntimeStartModal_machine\n    ...DowntimeEndModal_machine\n  }\n}\n\nfragment DowntimeOverlay_downtime on MachineDowntime {\n  id\n  startNotes\n  startAt\n  category {\n    id\n    name\n  }\n  startUser {\n    ...UserWidget_user\n    id\n  }\n}\n\nfragment DowntimeStartModal_machine on Machine {\n  id\n  name\n  type {\n    id\n    name\n  }\n  currentDowntime {\n    id\n    category {\n      id\n      name\n    }\n  }\n}\n\nfragment DowntimeEndModal_machine on Machine {\n  id\n  name\n  type {\n    id\n    name\n  }\n  currentDowntime {\n    id\n  }\n}\n\nfragment UserWidget_user on User {\n  id\n  firstName\n  lastName\n  ...UserAvatar_user\n}\n\nfragment UserAvatar_user on User {\n  id\n  avatar\n  firstName\n}\n",
    "metadata": {}
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = 'f2d4326a191ad9035190f7a8a47787e3';
module.exports = node;
