// @flow

import React from 'react';
import { createFragmentContainer, graphql } from 'react-relay';
import * as Yup from 'yup';
import { i18n, Analytics } from 'shared/utils';
import UpdateRunStartMutation from 'shared/mutations/UpdateRunStart';
import {
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
} from 'shared/components/modal';
import { Formik, Form, FieldGroup } from 'shared/components/form';
import FatButton from 'shared/components/common/FatButton';
import type { RunStartModal_run as RunFragment } from './__generated__/RunStartModal_run';

type Props = {
  onClose: () => void,
  run: RunFragment,
};

const LANGUAGE = {
  resume: {
    title: i18n.t('Resume Run?'),
    button: i18n.t('Resume Run'),
    success: i18n.t('Run Resumed'),
  },
  start: {
    title: i18n.t('Start Run?'),
    button: i18n.t('Start Run'),
    success: i18n.t('Run Started'),
  },
};

const RunStartModal = ({ onClose, run }: Props) => {
  const language = LANGUAGE[run.status === 'PAUSED' ? 'resume' : 'start'];

  return (
    <Modal maxWidth={700}>
      <Formik
        initialValues={{
          userNumber: '',
          notes: '',
        }}
        validationSchema={Yup.object().shape({
          userNumber: Yup.string().required('Required'),
          notes: Yup.string(),
        })}
        onSubmit={async (values: *, { setSubmitting }: *) => {
          setSubmitting(true);

          try {
            await UpdateRunStartMutation.commit({
              variables: {
                input: {
                  runId: run.id,
                  userNumber: values.userNumber,
                  notes: values.notes,
                },
              },
            });

            onClose();
            Analytics.trackEvent('Run Start', {
              operatorID: values.userNumber,
              notes: values.notes,
              machineType: run.machine?.type?.name,
            });
          } catch (e) {
            setSubmitting(false);
            window.alert(e.message);
          }
        }}
        render={({ values, errors, isValid, isSubmitting, handleSubmit }) => (
          <Form>
            <ModalHeader header={language.title} onClose={onClose} />
            <ModalBody withPadding>
              <FieldGroup
                name="userNumber"
                type="number"
                label={i18n.t('Operator ID')}
                placeholder={i18n.t('Enter ID')}
                error={errors.userNumber}
              />
              <FieldGroup
                name="notes"
                type="textarea"
                label={i18n.t('Notes')}
                placeholder={i18n.t('Type here...')}
                error={errors.notes}
                height={80}
              />
            </ModalBody>
            <ModalFooter style={{ display: 'block', textAlign: 'center' }}>
              <FatButton
                type="submit"
                theme="blue"
                disabled={!isValid}
                loading={isSubmitting}
                onClick={handleSubmit}
              >
                {language.button}
              </FatButton>
            </ModalFooter>
          </Form>
        )}
      />
    </Modal>
  );
};
export default createFragmentContainer(RunStartModal, {
  run: graphql`
    fragment RunStartModal_run on Run {
      id
      status
      machine {
        id
        type {
          id
          name
        }
      }
    }
  `,
});
