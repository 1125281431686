/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ReaderFragment } from 'relay-runtime';
export type RunStatus = "COMPLETE" | "IN_PROGRESS" | "PAUSED" | "SCHEDULED";
import type { FragmentReference } from "relay-runtime";
declare export opaque type RefetchableRunsContainer_runs$ref: FragmentReference;
declare export opaque type RefetchableRunsContainer_runs$fragmentType: RefetchableRunsContainer_runs$ref;
export type RefetchableRunsContainer_runs = {|
  +edges: ?$ReadOnlyArray<?{|
    +node: {|
      +id: string,
      +status: RunStatus,
      +scheduledStartAt: any,
      +scheduledEndAt: any,
      +jobState: {|
        +id: string,
        +workflowState: {|
          +id: string,
          +name: string,
        |},
      |},
      +job: {|
        +id: string,
        +importJobNumber: ?string,
        +jobNumber: string,
        +name: string,
        +yields: {|
          +totalCount: number
        |},
        +order: ?{|
          +id: string,
          +customer: {|
            +id: string,
            +name: string,
          |},
        |},
      |},
    |}
  |}>,
  +$refType: RefetchableRunsContainer_runs$ref,
|};
export type RefetchableRunsContainer_runs$data = RefetchableRunsContainer_runs;
export type RefetchableRunsContainer_runs$key = {
  +$data?: RefetchableRunsContainer_runs$data,
  +$fragmentRefs: RefetchableRunsContainer_runs$ref,
};
*/


const node/*: ReaderFragment*/ = (function(){
var v0 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "id",
  "args": null,
  "storageKey": null
},
v1 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "name",
  "args": null,
  "storageKey": null
},
v2 = [
  (v0/*: any*/),
  (v1/*: any*/)
];
return {
  "kind": "Fragment",
  "name": "RefetchableRunsContainer_runs",
  "type": "RunConnection",
  "metadata": null,
  "argumentDefinitions": [
    {
      "kind": "LocalArgument",
      "name": "startAt",
      "type": "DateTime!",
      "defaultValue": null
    },
    {
      "kind": "LocalArgument",
      "name": "endAt",
      "type": "DateTime!",
      "defaultValue": null
    },
    {
      "kind": "LocalArgument",
      "name": "machineIds",
      "type": "[ID!]!",
      "defaultValue": null
    }
  ],
  "selections": [
    {
      "kind": "LinkedField",
      "alias": null,
      "name": "edges",
      "storageKey": null,
      "args": null,
      "concreteType": "RunEdge",
      "plural": true,
      "selections": [
        {
          "kind": "LinkedField",
          "alias": null,
          "name": "node",
          "storageKey": null,
          "args": null,
          "concreteType": "Run",
          "plural": false,
          "selections": [
            (v0/*: any*/),
            {
              "kind": "ScalarField",
              "alias": null,
              "name": "status",
              "args": null,
              "storageKey": null
            },
            {
              "kind": "ScalarField",
              "alias": null,
              "name": "scheduledStartAt",
              "args": null,
              "storageKey": null
            },
            {
              "kind": "ScalarField",
              "alias": null,
              "name": "scheduledEndAt",
              "args": null,
              "storageKey": null
            },
            {
              "kind": "LinkedField",
              "alias": null,
              "name": "jobState",
              "storageKey": null,
              "args": null,
              "concreteType": "JobState",
              "plural": false,
              "selections": [
                (v0/*: any*/),
                {
                  "kind": "LinkedField",
                  "alias": null,
                  "name": "workflowState",
                  "storageKey": null,
                  "args": null,
                  "concreteType": "WorkflowState",
                  "plural": false,
                  "selections": (v2/*: any*/)
                }
              ]
            },
            {
              "kind": "LinkedField",
              "alias": null,
              "name": "job",
              "storageKey": null,
              "args": null,
              "concreteType": "Job",
              "plural": false,
              "selections": [
                (v0/*: any*/),
                {
                  "kind": "ScalarField",
                  "alias": null,
                  "name": "importJobNumber",
                  "args": null,
                  "storageKey": null
                },
                {
                  "kind": "ScalarField",
                  "alias": null,
                  "name": "jobNumber",
                  "args": null,
                  "storageKey": null
                },
                (v1/*: any*/),
                {
                  "kind": "LinkedField",
                  "alias": null,
                  "name": "yields",
                  "storageKey": null,
                  "args": null,
                  "concreteType": "JobYieldConnection",
                  "plural": false,
                  "selections": [
                    {
                      "kind": "ScalarField",
                      "alias": null,
                      "name": "totalCount",
                      "args": null,
                      "storageKey": null
                    }
                  ]
                },
                {
                  "kind": "LinkedField",
                  "alias": null,
                  "name": "order",
                  "storageKey": null,
                  "args": null,
                  "concreteType": "Order",
                  "plural": false,
                  "selections": [
                    (v0/*: any*/),
                    {
                      "kind": "LinkedField",
                      "alias": null,
                      "name": "customer",
                      "storageKey": null,
                      "args": null,
                      "concreteType": "Customer",
                      "plural": false,
                      "selections": (v2/*: any*/)
                    }
                  ]
                }
              ]
            }
          ]
        }
      ]
    }
  ]
};
})();
// prettier-ignore
(node/*: any*/).hash = '22dbacf8bbde083878f9435a7c56242a';
module.exports = node;
