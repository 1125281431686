/* eslint-disable react/no-array-index-key */

// @flow

import React from 'react';
import styled, { css } from 'styled-components';
import { colors, fonts } from 'shared/styleguide';

type Props = {
  children: React$Node,
  title?: React$Node | string,
  headerLeft?: React$Node | Array<React$Node>,
  headerRight?: React$Node,
  headerBottom?: React$Node,
  style?: Object,
  headerStyle?: Object,
  showHeaderBorder?: boolean,
};

const Wrapper = styled.div`
  border-radius: 10px;
  border: 1px solid ${colors.paleGrey};
  box-shadow: 0 2px 4px 0 rgba(106, 109, 123, 0.2);
  background-color: ${colors.white};
  padding: 16px;
`;

const Header = styled.div`
  ${p =>
    p.showHeaderBorder &&
    css`
      padding-bottom: 16px;
      margin-bottom: 16px;
      border-bottom: 2px solid ${colors.paleGrey};
    `}
`;

const HeaderBottomWrapper = styled.div`
  padding-top: 16px;
`;

const HeaderTop = styled.div`
  display: flex;
  flex-flow: row nowrap;
  justify-content: flex-start;
  align-items: center;
`;

const Title = styled.div`
  ${fonts.largeBold};
  color: ${colors.charcoalGrey};
  flex: 1;
`;

const LeftCell = styled.div`
  padding-right: 16px;
`;

const RightCell = styled.div`
  padding-left: 16px;
`;

const Panel = ({
  children,
  headerLeft,
  headerRight,
  headerBottom,
  title,
  style,
  headerStyle,
  showHeaderBorder,
}: Props) => {
  const hasHeader = headerLeft || title || headerRight || headerBottom;

  return (
    <Wrapper style={style}>
      {hasHeader && (
        <Header style={headerStyle} showHeaderBorder={showHeaderBorder}>
          <HeaderTop>
            {headerLeft
              ? Array.isArray(headerLeft)
                ? headerLeft.map((a, i) => <LeftCell key={i}>{a}</LeftCell>)
                : headerLeft
              : null}
            <Title>{title}</Title>
            {headerRight
              ? Array.isArray(headerRight)
                ? headerRight.map((a, i) => <RightCell key={i}>{a}</RightCell>)
                : headerRight
              : null}
          </HeaderTop>
          {headerBottom && (
            <HeaderBottomWrapper>{headerBottom}</HeaderBottomWrapper>
          )}
        </Header>
      )}
      {children}
    </Wrapper>
  );
};

Panel.defaultProps = {
  title: undefined,
  headerLeft: undefined,
  headerRight: undefined,
  headerBottom: undefined,
  style: {},
  headerStyle: {},
  showHeaderBorder: true,
};

export default Panel;
