// @flow

import React from 'react';
import { createFragmentContainer, graphql } from 'react-relay';
import * as Yup from 'yup';
import { i18n, WorkflowUtils } from 'shared/utils';
import UpdateJobStateMutation from 'shared/mutations/UpdateJobState';
import Button from 'shared/components/common/Button';
import {
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
} from 'shared/components/modal';
import {
  FieldGroup,
  Formik,
  Form,
  SelectUserField,
} from 'shared/components/form';
import {
  computeValidationSchema,
  computeFieldValues,
  createNewFieldOptions,
  DynamicFormField,
  renderAdditionalFieldInfo,
} from 'shared/components/form/FormBuilder';
import Divider from 'shared/components/common/Divider';
import { Trans } from 'react-i18next';
import type { UpdateJobStateModal_jobState as JobStateFragment } from './__generated__/UpdateJobStateModal_jobState';
import type { UpdateJobStateModal_job as JobFragment } from './__generated__/UpdateJobStateModal_job';

type Props = {
  onClose: () => void,
  jobState: JobStateFragment,
  job: JobFragment,
};

const createToggleDescription = (jobState, job, completed) => {
  if (
    job.status === 'CLOSED' &&
    ((jobState.status === 'COMPLETE' && !completed) ||
      (jobState.status === 'INCOMPLETE' && completed))
  ) {
    return i18n.t(
      'This Job is already closed. Are you sure you want to modify this Task?',
    );
  }
  const runWorkflowUtils = job.states.length === job.workflow.states.length;
  let toggleDescription = null;

  if (runWorkflowUtils) {
    const skippedStates = WorkflowUtils.getSkippedStatesOfJobState(
      jobState,
      job,
    );
    const startedDescendantStates = WorkflowUtils.getStartedDescendantStates(
      jobState,
      job,
    );

    if (completed && skippedStates.length && jobState.status !== 'COMPLETE') {
      const skippedStateLabels = [];

      for (let i = 0; i < skippedStates.length; i++) {
        const skippedState = skippedStates[i];
        let text = `${skippedState.name}`;

        if (skippedStates.length >= 2) {
          if (i === skippedStates.length - 2) {
            text += ' and ';
          } else if (i < skippedStates.length - 1) {
            text += ', ';
          }
        }

        skippedStateLabels.push(text);
      }

      toggleDescription = (
        <Trans>
          Are you sure you want to complete this Task?{' '}
          <strong>{skippedStateLabels}</strong> have not been completed yet.
        </Trans>
      );
    } else if (
      !completed &&
      startedDescendantStates.length &&
      jobState.status === 'COMPLETE'
    ) {
      const startedDescendantStateLabels = [];

      for (let i = 0; i < startedDescendantStates.length; i++) {
        const descendantState = startedDescendantStates[i];
        let text = `${descendantState.name}`;

        if (startedDescendantStates.length >= 2) {
          if (i === startedDescendantStates.length - 2) {
            text += ' and ';
          } else if (i < startedDescendantStates.length - 1) {
            text += ', ';
          }
        }

        startedDescendantStateLabels.push(text);
      }

      toggleDescription = (
        <Trans>
          Are you sure you want to mark this as Incomplete?{' '}
          <strong>{startedDescendantStateLabels}</strong> have been started or
          completed.
        </Trans>
      );
    }
  }

  return toggleDescription;
};

const UpdateJobStateModal = ({ onClose, jobState, job }: Props) => {
  const initialCustomFieldValues = jobState.workflowState.form
    ? computeFieldValues(
        jobState.workflowState.form.fields,
        jobState.formFieldValues,
        true,
      )
    : {};

  const validationSchema = computeValidationSchema(
    jobState.workflowState.form && jobState.workflowState.form.fields,
    {
      completed: Yup.boolean().nullable(),
      user: Yup.object().nullable(),
    },
  );

  return (
    <Modal maxWidth={700}>
      <Formik
        initialValues={{
          ...initialCustomFieldValues,
          completed: jobState.status === 'COMPLETE' ? true : false,
          user:
            (jobState.user && {
              value: jobState.user.id,
            }) ||
            (jobState.workflowState.defaultUser && {
              value: jobState.workflowState.defaultUser.id,
            }),
        }}
        validationSchema={validationSchema}
        onSubmit={async (values: *, { setSubmitting }: *) => {
          setSubmitting(true);

          const { user, completed, ...fieldValues } = values;

          try {
            if (jobState.workflowState.form) {
              await createNewFieldOptions(
                jobState.workflowState.form.fields,
                values,
              );
            }

            const formFieldValues = jobState.workflowState.form
              ? computeFieldValues(
                  jobState.workflowState.form.fields,
                  fieldValues,
                )
              : {};
            const taskStatus =
              jobState.workflowState.type === 'TASK'
                ? {
                    status: completed ? 'COMPLETE' : 'INCOMPLETE',
                  }
                : {};
            const input = {
              id: jobState.id,
              userId: user?.value || null,
              formFieldValues,
              ...taskStatus,
            };

            await UpdateJobStateMutation.commit({
              variables: {
                input,
              },
            });

            onClose();
          } catch (e) {
            setSubmitting(false);
            window.alert(e.message);
          }
        }}
        render={({
          values,
          errors,
          isValid,
          isSubmitting,
          handleSubmit,
          setFieldValue,
        }) => {
          const formFieldValues = jobState.workflowState.form
            ? computeFieldValues(jobState.workflowState.form.fields, values)
            : {};
          const toggleDescription = createToggleDescription(
            jobState,
            job,
            values.completed,
          );

          return (
            <Form>
              <ModalHeader
                header={jobState.workflowState.name}
                onClose={onClose}
              />
              <ModalBody withPadding>
                {jobState.workflowState.type === 'TASK' && (
                  <>
                    <FieldGroup
                      type="toggle"
                      name="completed"
                      label={i18n.t(
                        `${values.completed ? 'Complete' : 'Incomplete'}`,
                      )}
                      description={toggleDescription}
                    />
                    {(jobState.workflowState.isAssignable ||
                      jobState.workflowState.form) && (
                      <Divider style={{ marginBottom: 16 }} />
                    )}
                  </>
                )}
                {jobState.workflowState.isAssignable && (
                  <SelectUserField
                    name="user"
                    label={i18n.t('Assigned To')}
                    error={errors.user}
                    excludeOperators={false}
                  />
                )}
                {jobState.workflowState.form &&
                  jobState.workflowState.form.fields.map(formField => {
                    return (
                      <React.Fragment key={formField.id}>
                        <DynamicFormField
                          form={jobState.workflowState}
                          formField={formField}
                          error={errors ? errors[formField.id] : null}
                          creatingNewRecord={
                            values[formField.id] &&
                            values[formField.id]?.__isNew__
                          }
                          machineTypeId={jobState.workflowState.machineType?.id}
                        />
                        {renderAdditionalFieldInfo({
                          formFieldType: formField.type,
                          value: formFieldValues[formField.id],
                          props: {
                            style: {
                              marginBottom: 24,
                              marginTop: -8,
                            },
                          },
                        })}
                      </React.Fragment>
                    );
                  })}
              </ModalBody>
              <ModalFooter>
                <Button
                  type="submit"
                  theme="blue"
                  disabled={!isValid}
                  loading={isSubmitting}
                  onClick={handleSubmit}
                >
                  {i18n.t('Update')}
                </Button>
              </ModalFooter>
            </Form>
          );
        }}
      />
    </Modal>
  );
};

export default createFragmentContainer(UpdateJobStateModal, {
  jobState: graphql`
    fragment UpdateJobStateModal_jobState on JobState {
      id
      formFieldValues
      status
      workflowState {
        id
        name
        isAssignable
        type
        machineType {
          id
        }
        defaultUser {
          id
        }
        form {
          id
          ...FormBuilder_form @relay(mask: false)
          fields {
            id
            options {
              id
              name
              value
            }
            ...AdditionalFieldInfo_formField
          }
        }
      }
      user {
        id
      }
    }
  `,
  job: graphql`
    fragment UpdateJobStateModal_job on Job {
      id
      status
      ...WorkflowUtils_job @relay(mask: false)
      states {
        id
        status
        workflowState {
          id
          type
        }
      }
      workflow {
        id
        states {
          id
          name
        }
      }
    }
  `,
});
