// @flow

import React, { PureComponent } from 'react';
import type { Node } from 'react';
import ReactSelect, { components } from 'react-select';
import ReactSelectCreatable from 'react-select/creatable';
import ReactSelectAsync from 'react-select/async';
import ReactSelectAsyncCreatable from 'react-select/async-creatable';
import styled from 'styled-components';
import { formStyles, colors } from 'shared/styleguide';
import type { FormikProps, FieldProps } from 'formik';
import Icon from 'shared/components/common/Icon';
import { i18n } from 'shared/utils';
import type { FieldSize } from './types';

type Props = {
  form?: FormikProps<string>,
  field?: $PropertyType<FieldProps, 'field'>,
  error?: ?string,
  size?: FieldSize,
  placeholder?: string,
  disabled?: boolean,
  loading?: boolean,
  creatable?: boolean,
  clearable?: boolean,
  multi?: boolean,
  options: Array<{
    label: string,
    value: any,
    ...*,
  }>,
  value: any,
  onChange?: any => void,
  onBlur?: () => void,
  loadOptions?: Function,
  defaultOptions?: boolean,
  isSearchable?: boolean,
  label: string,
  selectOrderField: boolean,
};

const CreateLabel = styled.div`
  display: flex;
  flex-direction: column;

  span {
    margin-left: 8px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    text-align: left;
  }
`;

const CreateLabelTop = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 8px;
`;

// Easier to control styling of ReactSelect component through custom styles prop rather than Styled Components
const CUSTOM_STYLES = {
  control: base => ({
    ...base,
    minHeight: formStyles.INPUT_HEIGHT,
  }),
  option: (base, { isSelected, isFocused }) => ({
    ...base,
    backgroundColor: !isSelected && isFocused ? colors.paleGreyTwo : 'default',
    '&:hover': {
      backgroundColor:
        !isSelected && isFocused ? colors.paleGreyTwo : 'default',
    },
  }),
  indicatorSeparator: () => null,
  indicatorsContainer: base => ({
    ...base,
    height: formStyles.INPUT_HEIGHT - 2,
  }),
  clearIndicator: base => ({
    ...base,
    padding: '5px',
  }),
  dropdownIndicator: base => ({
    ...base,
    padding: '5px',
  }),
  singleValue: base => base,
};

const Wrapper = styled.div`
  position: relative;

  ${formStyles.fieldWidth};
  .react-select__control {
    ${formStyles.field};
    line-height: normal;
    height: auto;

    &--is-focused {
      ${formStyles.fieldFocus};
    }
  }

  .react-select-input {
    input {
      line-height: auto;
      padding: 0;
    }
  }

  .react-select-placeholder,
  .react-select--single > .react-select-control .react-select-value {
    line-height: auto;
  }

  .react-select--isDisabled {
    ${formStyles.fieldDisabled};
  }

  .react-select__input input {
    border: none !important;
  }

  .react-select input {
    border: none;
    box-shadow: none;
    height: auto;
  }

  .react-select__option {
    ${formStyles.fieldFont};
    margin-top: 0;
    margin-bottom: 0;
  }

  .react-select__placeholder {
    ${formStyles.fieldFont};
    ${formStyles.fieldPlaceholderFont};
  }

  .react-select__menu-list {
    padding-top: 0;
    padding-bottom: 0;
  }

  .react-select__option:first-child {
    border-top-left-radius: 4px;
    border-top-right-radius: 4px;
  }

  .react-select__option:last-child {
    border-bottom-left-radius: 4px;
    border-bottom-right-radius: 4px;
  }

  .react-select__option--is-selected {
    background-color: ${colors.cobalt};
    color: ${colors.white};
  }
`;

const DropdownIndicator = props => (
  <components.DropdownIndicator {...props}>
    <Icon type="down-arrow" size={15} style={{ marginRight: 4 }} />
  </components.DropdownIndicator>
);

type NoOptionsMessageProps = {
  selectProps: {
    creatable: boolean,
    formatCreateLabel: (value: string) => Node,
    ...*,
  },
  ...*,
};

const NoOptionsMessage = (props: NoOptionsMessageProps) => {
  const {
    selectProps: { creatable, formatCreateLabel },
  } = props;
  if (!creatable) return <components.NoOptionsMessage {...props} />;
  return (
    <components.NoOptionsMessage {...props}>
      {formatCreateLabel('')}
    </components.NoOptionsMessage>
  );
};

class Select extends PureComponent<Props> {
  static defaultProps = {
    form: undefined,
    field: undefined,
    creatable: false,
    disabled: false,
    loading: false,
    clearable: false,
    multi: false,
    error: undefined,
    size: 'large',
    placeholder: undefined,
    onChange: () => {},
    onBlur: () => {},
    loadOptions: undefined,
    defaultOptions: undefined,
    isSearchable: undefined,
  };

  getValue = () => {
    const { multi, value, field, options } = this.props;
    const val = value !== undefined ? value : field?.value;

    if (multi) {
      return !val || !Array.isArray(val)
        ? []
        : val.map(v => {
            if (!v) {
              return null;
            }

            if (v.__isNew__) {
              return null;
            }

            if (options) {
              return options.find(a => a.value === v.value) || null;
            }

            return v;
          });
    } else {
      if (!val) {
        return null;
      }

      if (val.__isNew__) {
        return val;
      }

      if (options) {
        return options.find(a => a.value === val.value) || null;
      }

      return val;
    }
  };

  handleBlur = () => {
    const { form, field, onBlur } = this.props;

    if (form && field) {
      form.setFieldTouched(field.name, true);
    }

    if (onBlur) {
      onBlur();
    }
  };

  handleChange = (option: Object) => {
    const { form, field, onChange } = this.props;

    if (form && field) {
      form.setFieldValue(field.name, option);
    }

    if (onChange) {
      onChange(option);
    }
  };

  formatCreateLabel = (inputValue: string) => {
    const { label } = this.props;

    if (label) {
      return (
        <CreateLabel>
          <CreateLabelTop>
            <Icon type="circle-plus" size={24} />
            <span>
              {i18n.t('Create new {{ label }}:', {
                label,
              })}
            </span>
          </CreateLabelTop>
          <span>{`"${inputValue}"`}</span>
        </CreateLabel>
      );
    }
  };

  render() {
    const {
      error,
      size,
      placeholder,
      disabled,
      loading,
      options,
      creatable,
      clearable,
      multi,
      loadOptions,
      defaultOptions,
      isSearchable,
      selectOrderField,
    } = this.props;
    let SelectComponent = ReactSelect;
    const customStyles = { ...CUSTOM_STYLES };

    if (creatable && loadOptions) {
      SelectComponent = ReactSelectAsyncCreatable;
    } else if (creatable && !loadOptions) {
      SelectComponent = ReactSelectCreatable;
    } else if (!creatable && loadOptions) {
      SelectComponent = ReactSelectAsync;
    }

    if (selectOrderField) {
      const deleteKeys = new Set([
        'transform',
        'top',
        'textOverflow',
        'whiteSpace',
      ]);

      customStyles.singleValue = base => {
        const filteredBase = Object.keys(base)
          .filter(key => !deleteKeys.has(key))
          .reduce((obj, key) => ({ ...obj, [key]: base[key] }), {});

        return {
          ...filteredBase,
          minHeight: 'fit-content',
          position: 'static',
        };
      };
    }

    return (
      <Wrapper error={error} size={size} selectOrderField={selectOrderField}>
        <SelectComponent
          components={{ DropdownIndicator, NoOptionsMessage }}
          placeholder={placeholder}
          isClearable={clearable}
          isDisabled={disabled}
          isLoading={loading}
          isMulti={multi}
          isSearchable={isSearchable}
          options={options}
          theme={theme => ({
            ...theme,
            colors: {
              ...theme.colors,
              primary: '#B2D4FF',
            },
          })}
          className="react-select" // eslint-disable-line react/forbid-component-props
          classNamePrefix="react-select"
          onBlur={this.handleBlur}
          onChange={this.handleChange}
          value={this.getValue()}
          getOptionValue={val => val?.value || null}
          loadOptions={loadOptions}
          defaultOptions={defaultOptions}
          styles={customStyles}
          formatCreateLabel={this.formatCreateLabel}
          creatable={creatable}
        />
      </Wrapper>
    );
  }
}

export default Select;
