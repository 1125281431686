// @flow

export default {
  /**
   * Commonly-used "width" prop handler for styled-components.
   * @param {Object} p
   * @param {string|number} defaultWidth
   * @return {string|number}
   */
  widthProp: (p: Object, defaultWidth?: string | number) => {
    return p.width
      ? typeof p.width === 'string'
        ? p.width
        : `${p.width}px`
      : defaultWidth || '100%';
  },
};
