/**
 * @flow
 * @relayHash 9791fcbdee1a317a215e2c26936c9279
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
type AdditionalFieldInfo_formField$ref = any;
type CosmeticFieldValue_formField$ref = any;
type UserWidget_user$ref = any;
export type FormFieldType = "CHECKBOX" | "DATE" | "MULTILINE_TEXT" | "MULTISELECT" | "NUMBER" | "SELECT" | "SELECT_CUSTOMER" | "SELECT_MACHINE" | "SELECT_ORDER" | "SELECT_USER" | "TEXT";
export type JobStateStatus = "COMPLETE" | "INCOMPLETE" | "IN_PROGRESS" | "NEEDS_ATTENTION" | "PAUSED";
export type JobStatus = "CLOSED" | "OPEN";
export type UpdateJobStateInput = {|
  id: string,
  userId?: ?string,
  formFieldValues?: ?any,
  isEnabled?: ?boolean,
  status?: ?JobStateStatus,
|};
export type UpdateJobStateMutationVariables = {|
  input?: ?UpdateJobStateInput
|};
export type UpdateJobStateMutationResponse = {|
  +updateJobState: {|
    +jobState: {|
      +id: string,
      +isEnabled: boolean,
      +formFieldValues: any,
      +status: JobStateStatus,
      +user: ?{|
        +id: string,
        +$fragmentRefs: UserWidget_user$ref,
      |},
      +workflowState: {|
        +id: string,
        +isAssignable: ?boolean,
        +form: ?{|
          +id: string,
          +fields: $ReadOnlyArray<{|
            +id: string,
            +name: string,
            +type: FormFieldType,
            +options: $ReadOnlyArray<{|
              +id: string,
              +name: string,
              +value: string,
            |}>,
            +$fragmentRefs: CosmeticFieldValue_formField$ref & AdditionalFieldInfo_formField$ref,
          |}>,
        |},
      |},
    |},
    +job: {|
      +id: string,
      +status: JobStatus,
      +states: $ReadOnlyArray<{|
        +id: string,
        +status: JobStateStatus,
      |}>,
    |},
  |}
|};
export type UpdateJobStateMutation = {|
  variables: UpdateJobStateMutationVariables,
  response: UpdateJobStateMutationResponse,
|};
*/


/*
mutation UpdateJobStateMutation(
  $input: UpdateJobStateInput
) {
  updateJobState(input: $input) {
    jobState {
      id
      isEnabled
      formFieldValues
      status
      user {
        id
        ...UserWidget_user
      }
      workflowState {
        id
        isAssignable
        form {
          id
          fields {
            id
            name
            type
            options {
              id
              name
              value
            }
            ...CosmeticFieldValue_formField
            ...AdditionalFieldInfo_formField
          }
        }
      }
    }
    job {
      id
      status
      states {
        id
        status
      }
    }
  }
}

fragment UserWidget_user on User {
  id
  firstName
  lastName
  ...UserAvatar_user
}

fragment CosmeticFieldValue_formField on FormField {
  id
  name
  type
}

fragment AdditionalFieldInfo_formField on FormField {
  id
  name
  type
}

fragment UserAvatar_user on User {
  id
  avatar
  firstName
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "input",
    "type": "UpdateJobStateInput",
    "defaultValue": null
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
],
v2 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "id",
  "args": null,
  "storageKey": null
},
v3 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "isEnabled",
  "args": null,
  "storageKey": null
},
v4 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "formFieldValues",
  "args": null,
  "storageKey": null
},
v5 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "status",
  "args": null,
  "storageKey": null
},
v6 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "isAssignable",
  "args": null,
  "storageKey": null
},
v7 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "name",
  "args": null,
  "storageKey": null
},
v8 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "type",
  "args": null,
  "storageKey": null
},
v9 = {
  "kind": "LinkedField",
  "alias": null,
  "name": "options",
  "storageKey": null,
  "args": null,
  "concreteType": "FormFieldOption",
  "plural": true,
  "selections": [
    (v2/*: any*/),
    (v7/*: any*/),
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "value",
      "args": null,
      "storageKey": null
    }
  ]
},
v10 = {
  "kind": "LinkedField",
  "alias": null,
  "name": "job",
  "storageKey": null,
  "args": null,
  "concreteType": "Job",
  "plural": false,
  "selections": [
    (v2/*: any*/),
    (v5/*: any*/),
    {
      "kind": "LinkedField",
      "alias": null,
      "name": "states",
      "storageKey": null,
      "args": null,
      "concreteType": "JobState",
      "plural": true,
      "selections": [
        (v2/*: any*/),
        (v5/*: any*/)
      ]
    }
  ]
};
return {
  "kind": "Request",
  "fragment": {
    "kind": "Fragment",
    "name": "UpdateJobStateMutation",
    "type": "Mutation",
    "metadata": null,
    "argumentDefinitions": (v0/*: any*/),
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "updateJobState",
        "storageKey": null,
        "args": (v1/*: any*/),
        "concreteType": "UpdateJobStatePayload",
        "plural": false,
        "selections": [
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "jobState",
            "storageKey": null,
            "args": null,
            "concreteType": "JobState",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              (v3/*: any*/),
              (v4/*: any*/),
              (v5/*: any*/),
              {
                "kind": "LinkedField",
                "alias": null,
                "name": "user",
                "storageKey": null,
                "args": null,
                "concreteType": "User",
                "plural": false,
                "selections": [
                  (v2/*: any*/),
                  {
                    "kind": "FragmentSpread",
                    "name": "UserWidget_user",
                    "args": null
                  }
                ]
              },
              {
                "kind": "LinkedField",
                "alias": null,
                "name": "workflowState",
                "storageKey": null,
                "args": null,
                "concreteType": "WorkflowState",
                "plural": false,
                "selections": [
                  (v2/*: any*/),
                  (v6/*: any*/),
                  {
                    "kind": "LinkedField",
                    "alias": null,
                    "name": "form",
                    "storageKey": null,
                    "args": null,
                    "concreteType": "Form",
                    "plural": false,
                    "selections": [
                      (v2/*: any*/),
                      {
                        "kind": "LinkedField",
                        "alias": null,
                        "name": "fields",
                        "storageKey": null,
                        "args": null,
                        "concreteType": "FormField",
                        "plural": true,
                        "selections": [
                          (v2/*: any*/),
                          (v7/*: any*/),
                          (v8/*: any*/),
                          (v9/*: any*/),
                          {
                            "kind": "FragmentSpread",
                            "name": "CosmeticFieldValue_formField",
                            "args": null
                          },
                          {
                            "kind": "FragmentSpread",
                            "name": "AdditionalFieldInfo_formField",
                            "args": null
                          }
                        ]
                      }
                    ]
                  }
                ]
              }
            ]
          },
          (v10/*: any*/)
        ]
      }
    ]
  },
  "operation": {
    "kind": "Operation",
    "name": "UpdateJobStateMutation",
    "argumentDefinitions": (v0/*: any*/),
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "updateJobState",
        "storageKey": null,
        "args": (v1/*: any*/),
        "concreteType": "UpdateJobStatePayload",
        "plural": false,
        "selections": [
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "jobState",
            "storageKey": null,
            "args": null,
            "concreteType": "JobState",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              (v3/*: any*/),
              (v4/*: any*/),
              (v5/*: any*/),
              {
                "kind": "LinkedField",
                "alias": null,
                "name": "user",
                "storageKey": null,
                "args": null,
                "concreteType": "User",
                "plural": false,
                "selections": [
                  (v2/*: any*/),
                  {
                    "kind": "ScalarField",
                    "alias": null,
                    "name": "firstName",
                    "args": null,
                    "storageKey": null
                  },
                  {
                    "kind": "ScalarField",
                    "alias": null,
                    "name": "lastName",
                    "args": null,
                    "storageKey": null
                  },
                  {
                    "kind": "ScalarField",
                    "alias": null,
                    "name": "avatar",
                    "args": null,
                    "storageKey": null
                  }
                ]
              },
              {
                "kind": "LinkedField",
                "alias": null,
                "name": "workflowState",
                "storageKey": null,
                "args": null,
                "concreteType": "WorkflowState",
                "plural": false,
                "selections": [
                  (v2/*: any*/),
                  (v6/*: any*/),
                  {
                    "kind": "LinkedField",
                    "alias": null,
                    "name": "form",
                    "storageKey": null,
                    "args": null,
                    "concreteType": "Form",
                    "plural": false,
                    "selections": [
                      (v2/*: any*/),
                      {
                        "kind": "LinkedField",
                        "alias": null,
                        "name": "fields",
                        "storageKey": null,
                        "args": null,
                        "concreteType": "FormField",
                        "plural": true,
                        "selections": [
                          (v2/*: any*/),
                          (v7/*: any*/),
                          (v8/*: any*/),
                          (v9/*: any*/)
                        ]
                      }
                    ]
                  }
                ]
              }
            ]
          },
          (v10/*: any*/)
        ]
      }
    ]
  },
  "params": {
    "operationKind": "mutation",
    "name": "UpdateJobStateMutation",
    "id": null,
    "text": "mutation UpdateJobStateMutation(\n  $input: UpdateJobStateInput\n) {\n  updateJobState(input: $input) {\n    jobState {\n      id\n      isEnabled\n      formFieldValues\n      status\n      user {\n        id\n        ...UserWidget_user\n      }\n      workflowState {\n        id\n        isAssignable\n        form {\n          id\n          fields {\n            id\n            name\n            type\n            options {\n              id\n              name\n              value\n            }\n            ...CosmeticFieldValue_formField\n            ...AdditionalFieldInfo_formField\n          }\n        }\n      }\n    }\n    job {\n      id\n      status\n      states {\n        id\n        status\n      }\n    }\n  }\n}\n\nfragment UserWidget_user on User {\n  id\n  firstName\n  lastName\n  ...UserAvatar_user\n}\n\nfragment CosmeticFieldValue_formField on FormField {\n  id\n  name\n  type\n}\n\nfragment AdditionalFieldInfo_formField on FormField {\n  id\n  name\n  type\n}\n\nfragment UserAvatar_user on User {\n  id\n  avatar\n  firstName\n}\n",
    "metadata": {}
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = 'b020e422e4947ca711d097e6db47a400';
module.exports = node;
