// @flow

import React from 'react';
import { createFragmentContainer, graphql } from 'react-relay';
import type { UserAvatar_user as UserFragment } from './__generated__/UserAvatar_user';
import UnassignedAvatar from './img/unassignedAvatar.svg';
import DefaultAvatar from './img/defaultAvatar.svg';

type Props = {
  style?: Object,
  width?: number,
  user: ?UserFragment,
};

const UserAvatar = ({ user, width, style }: Props) => {
  let img;
  const imgProps = {
    alt: user && user.firstName ? user.firstName : 'User',
    width: width,
    height: width,
    style: {
      borderRadius: (width || 30) / 2,
      ...(style || {}),
    },
  };

  // If user has an avatar
  if (user && user.avatar) {
    return <img src={user.avatar} {...imgProps} />;
  }

  // If user hasn't selected an avatar
  if (user && !user.avatar) {
    return <DefaultAvatar {...imgProps} />;
  }

  return <UnassignedAvatar {...imgProps} />;
};

UserAvatar.defaultProps = {
  style: undefined,
  width: 30,
};

export default createFragmentContainer(UserAvatar, {
  user: graphql`
    fragment UserAvatar_user on User {
      id
      avatar
      firstName
    }
  `,
});
