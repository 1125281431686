// @flow

import React from 'react';
import { QueryRenderer, graphql } from 'react-relay';
import relayEnvironment from 'shared/gql/relayEnvironment';

type Props = {
  orderId: string,
};

export default ({ orderId }: Props) => (
  <QueryRenderer
    environment={relayEnvironment}
    query={graphql`
      query OrderLabelQuery($orderId: ID!) {
        order(id: $orderId) {
          id
          orderNumber
          customer {
            id
            name
          }
        }
      }
    `}
    variables={{ orderId }}
    render={query => (
      <span>
        {query.props
          ? query.props.order?.orderNumber
            ? `${query.props.order.customer.name} : ${query.props.order?.orderNumber}`
            : 'Unknown Order'
          : '. . .'}
      </span>
    )}
  />
);
