// @flow

import React from 'react';
import styled from 'styled-components';
import { fonts, colors } from 'shared/styleguide';
import { i18n } from 'shared/utils';
import Button from 'shared/components/common/Button';
import emptyCustomers from './img/emptyCustomers.svg';
import emptyJobs from './img/emptyJobs.svg';
import emptyItems from './img/emptyItems.svg';
import emptyRuns from './img/emptyRuns.svg';
import emptyOrders from './img/emptyOrders.svg';
import emptyAttachments from './img/emptyAttachments.svg';
import emptyLedger from './img/emptyLedger.svg';
import emptyItemLots from './img/emptyItemLots.svg';
import attachmentsDragging from './img/attachmentsDragging.svg';
import emptyComponents from './img/emptyComponents.svg';
import emptyTooling from './img/emptyTooling.svg';
import emptyReport from './img/emptyReport.svg';
import emptyWorkflows from './img/emptyWorkflows.svg';

const EmptyGraphicMap = {
  customers: emptyCustomers,
  jobs: emptyJobs,
  items: emptyItems,
  runs: emptyRuns,
  orders: emptyOrders,
  attachments: emptyAttachments,
  attachmentsDragging: attachmentsDragging,
  ledger: emptyLedger,
  itemLots: emptyItemLots,
  components: emptyComponents,
  tooling: emptyTooling,
  report: emptyReport,
  workflows: emptyWorkflows,
};

type Props = {
  graphic: $Keys<typeof EmptyGraphicMap>,
  message: string | React$Node,
  onGraphicClick?: (e: Event) => void,
  onCreateNew?: () => void,
  createNewLabel?: string,
};

const Wrapper = styled.div`
  width: 100%;
  text-align: center;

  a:hover {
    cursor: pointer;
  }

  svg {
    width: 200px;
    height: 200px;
  }
`;

const Label = styled.div`
  padding-bottom: ${p => (p.createNewButton ? 24 : 0)}px;
  padding: 0 16px 24px 16px;
  ${fonts.bodyBold};
  color: ${colors.battleshipGrey};
`;

const EmptyListResults = ({
  graphic,
  message,
  onCreateNew,
  onGraphicClick,
  createNewLabel,
}: Props) => {
  const Graphic = EmptyGraphicMap[graphic];

  return (
    <Wrapper>
      <Graphic onClick={onGraphicClick} />
      <Label createNewButton={Boolean(onCreateNew)}>{message}</Label>
      {onCreateNew && (
        <Button theme="blue" width="auto" onClick={onCreateNew}>
          {createNewLabel ? createNewLabel : i18n.t('Create New')}
        </Button>
      )}
    </Wrapper>
  );
};

EmptyListResults.defaultProps = {
  onGraphicClick: undefined,
  onCreateNew: undefined,
  createNewLabel: undefined,
};

export default EmptyListResults;
