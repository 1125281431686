/**
 * @flow
 * @relayHash e87532b57efbab88344505aa86fe578f
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
type UserWidget_user$ref = any;
export type RunLogType = "PROGRESS_ENTRY" | "RUN_END" | "RUN_PAUSE" | "RUN_START" | "SETUP_END" | "SETUP_PAUSE" | "SETUP_START";
export type UpdateRunLogInput = {|
  id: string,
  createdAt?: ?any,
|};
export type UpdateRunLogMutationVariables = {|
  input: UpdateRunLogInput
|};
export type UpdateRunLogMutationResponse = {|
  +updateRunLog: {|
    +runLog: {|
      +id: string,
      +type: RunLogType,
      +notes: ?string,
      +createdAt: any,
      +user: {|
        +$fragmentRefs: UserWidget_user$ref
      |},
    |}
  |}
|};
export type UpdateRunLogMutation = {|
  variables: UpdateRunLogMutationVariables,
  response: UpdateRunLogMutationResponse,
|};
*/


/*
mutation UpdateRunLogMutation(
  $input: UpdateRunLogInput!
) {
  updateRunLog(input: $input) {
    runLog {
      id
      type
      notes
      createdAt
      user {
        ...UserWidget_user
        id
      }
    }
  }
}

fragment UserWidget_user on User {
  id
  firstName
  lastName
  ...UserAvatar_user
}

fragment UserAvatar_user on User {
  id
  avatar
  firstName
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "input",
    "type": "UpdateRunLogInput!",
    "defaultValue": null
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
],
v2 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "id",
  "args": null,
  "storageKey": null
},
v3 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "type",
  "args": null,
  "storageKey": null
},
v4 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "notes",
  "args": null,
  "storageKey": null
},
v5 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "createdAt",
  "args": null,
  "storageKey": null
};
return {
  "kind": "Request",
  "fragment": {
    "kind": "Fragment",
    "name": "UpdateRunLogMutation",
    "type": "Mutation",
    "metadata": null,
    "argumentDefinitions": (v0/*: any*/),
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "updateRunLog",
        "storageKey": null,
        "args": (v1/*: any*/),
        "concreteType": "UpdateRunLogPayload",
        "plural": false,
        "selections": [
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "runLog",
            "storageKey": null,
            "args": null,
            "concreteType": "RunLog",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              (v3/*: any*/),
              (v4/*: any*/),
              (v5/*: any*/),
              {
                "kind": "LinkedField",
                "alias": null,
                "name": "user",
                "storageKey": null,
                "args": null,
                "concreteType": "User",
                "plural": false,
                "selections": [
                  {
                    "kind": "FragmentSpread",
                    "name": "UserWidget_user",
                    "args": null
                  }
                ]
              }
            ]
          }
        ]
      }
    ]
  },
  "operation": {
    "kind": "Operation",
    "name": "UpdateRunLogMutation",
    "argumentDefinitions": (v0/*: any*/),
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "updateRunLog",
        "storageKey": null,
        "args": (v1/*: any*/),
        "concreteType": "UpdateRunLogPayload",
        "plural": false,
        "selections": [
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "runLog",
            "storageKey": null,
            "args": null,
            "concreteType": "RunLog",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              (v3/*: any*/),
              (v4/*: any*/),
              (v5/*: any*/),
              {
                "kind": "LinkedField",
                "alias": null,
                "name": "user",
                "storageKey": null,
                "args": null,
                "concreteType": "User",
                "plural": false,
                "selections": [
                  (v2/*: any*/),
                  {
                    "kind": "ScalarField",
                    "alias": null,
                    "name": "firstName",
                    "args": null,
                    "storageKey": null
                  },
                  {
                    "kind": "ScalarField",
                    "alias": null,
                    "name": "lastName",
                    "args": null,
                    "storageKey": null
                  },
                  {
                    "kind": "ScalarField",
                    "alias": null,
                    "name": "avatar",
                    "args": null,
                    "storageKey": null
                  }
                ]
              }
            ]
          }
        ]
      }
    ]
  },
  "params": {
    "operationKind": "mutation",
    "name": "UpdateRunLogMutation",
    "id": null,
    "text": "mutation UpdateRunLogMutation(\n  $input: UpdateRunLogInput!\n) {\n  updateRunLog(input: $input) {\n    runLog {\n      id\n      type\n      notes\n      createdAt\n      user {\n        ...UserWidget_user\n        id\n      }\n    }\n  }\n}\n\nfragment UserWidget_user on User {\n  id\n  firstName\n  lastName\n  ...UserAvatar_user\n}\n\nfragment UserAvatar_user on User {\n  id\n  avatar\n  firstName\n}\n",
    "metadata": {}
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '86342bebe6acd30561aaa9234353097a';
module.exports = node;
